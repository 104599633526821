
import Swiper, { Autoplay, FreeMode, Mousewheel, Navigation, Pagination, Parallax } from 'swiper';
import Component from '~/components/component';

export default class DatesSlider extends Component {

    constructor(...args) {
        super(...args);

        this.slideIndex = 0;
        this.dates = [];
        this.widthSlides = [];
        this.posSlides = [];

        this.swiper = undefined;
        this.isSwiperMobile = false;
        this.isSwiperDesktop = false;
        this.retrieveDOM();
        this.getDates();
        this.initSwiper();
    }

    retrieveDOM() {
        this.dom.swiperContainerDesktop = this.dom.component.querySelector('.js-swiper-container.only-desktop');
        this.dom.swiperContainerMobile = this.dom.component.querySelector('.js-swiper-container.only-mobile');
        this.dom.pagination = this.dom.component.querySelector('.js-pagination');
        this.dom.paginationDesktop = this.dom.component.querySelector('.js-pagination-desktop');
        this.dom.slides = this.dom.component.querySelectorAll('.swiper-slide .js-slide');
        this.dom.containerScroll = this.dom.component.querySelector('.swiper-slide');
        this.dom.selector = this.dom.component.querySelector('.js-selector');
        this.dom.datesDots = this.dom.component.querySelectorAll('.js-dates-dots');
        this.dom.blueBird = this.dom.component.querySelector('.js-blue-bird');
        this.dom.allDateDesktop = this.dom.component.querySelector('.js-all-date-desktop');
        this.dom.allDateMobile = this.dom.component.querySelector('.js-all-date-mobile');

        this.computeAnimationSizes();
    }

    computeAnimationSizes() {
        this.slideNumber = this.dom.slides.length;
        this.animationWidth = (window.innerWidth * 0.8) - this.dom.blueBird.offsetWidth;
        this.animationHeight = this.dom.slides[0].querySelector('.left').offsetHeight - this.dom.blueBird.offsetWidth;
    }

    bind() {
        this.getPosSlides();
        this.swiper.updateSize();

        //Events click on dots
        for (let i = 0; i < this.dom.datesDots.length; i++) {
            this.dom.datesDots[i].addEventListener('click', () => {
                const index = i == 0 ? 0 : - this.posSlides[i] + (window.innerWidth / 2);
                this.setTransition("0.4s");
                this.swiper.setTranslate(index);
            })
        }

        //Trigger mouse enter slider
        this.dom.component.addEventListener('mouseenter', () => {
            document.documentElement.style.overscrollBehaviorX = 'none';
            document.body.style.overscrollBehaviorX = 'none';
        });
        this.dom.component.addEventListener('mouseleave', () => {
            document.body.style.overscrollBehaviorX = 'initial';
            document.documentElement.style.overscrollBehaviorX = 'initial';
        });
        this.dom.pagination.addEventListener('click', () => {
            // Send google event
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ 'event': 'clicSwiper', 'swiperClic': 'clic -' + this.dates[this.slideIndex] });
            }
        })
        this.dom.paginationDesktop.addEventListener('click', () => {
            // Send google event
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ 'event': 'clicSwiper', 'swiperClic': 'clic -' + this.dates[this.slideIndex] });
            }
        })

        this.dom.allDateDesktop.addEventListener('click', () => {
            // Send google event
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ 'event': 'clicSwiper', 'swiperClic': 'clic - all date - desktop' });
            }
        })
        this.dom.allDateMobile.addEventListener('click', () => {
            // Send google event
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ 'event': 'clicSwiper', 'swiperClic': 'clic - all date - mobile' });
            }
        })
    }

    getPosSlides() {
        //Get width of slides
        for (let i = 0; i < this.dom.slides.length; i++) {
            const valueWidth = i == 0 ? this.dom.slides[i].offsetWidth - window.innerWidth : this.dom.slides[i].offsetWidth;
            this.widthSlides.push(valueWidth);
        }
        let pos = 0;
        //Increment values on width slides
        for (let j = 0; j < this.widthSlides.length; j++) {
            pos += this.widthSlides[j];
            this.posSlides.push(pos);
        }
    }

    getDates() {
        for (let i = 0; i < this.dom.datesDots.length; i++) {
            const value = this.dom.datesDots[i].querySelector('.js-date').innerHTML;
            this.dates.push(value);
        }
    }

    processDots(translate) {
        //Check if translate is over container
        if (translate > 0) return;
        const value = Math.abs(translate);
        let index = 1;

        //Check the pos for get index slide
        for (let m = 0; m < this.posSlides.length - 1; m++) {
            value >= this.posSlides[m] ? index = m + 2 : '';
        }
        if (this.slideIndex !== index - 1) {
            this.slideIndex = index - 1;
        }
        //Translate selector date
        this.translateSelector(index - 1);
    }

    setTransition = (duration) => {
        const sWrapper = this.dom.swiperContainerDesktop.querySelector('.swiper-wrapper');
        sWrapper.style.transitionDuration = duration;
        setTimeout(() => {
            sWrapper.style.transitionDuration = "0s";
        }, duration + 50);
    }

    translateSelector(index) {
        if (this.dom.datesDots[index]) {
            this.dom.selector.style.transform = "translateX(" + this.dom.datesDots[index].offsetLeft + "px)";
        }
    }

    mapLinear(nbr, in_min, in_max, out_min, out_max) {
        return ((nbr - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    }

    resize() {
        this.posSlides = [];
        this.widthSlides = [];
        this.initSwiper();
        this.computeAnimationSizes();
        this.getPosSlides();
    }

    yVariation(x) {
        const c1 = 1.70158;
        const c2 = c1 * 1.525;

        return x < 0.5
            ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
            : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
    }


    onSliderMove(e) {
        this.processDots(e.translate);

        const initValue = this.posSlides[this.slideIndex] - this.widthSlides[this.slideIndex];
        const x = ((-e.translate - initValue) / this.widthSlides[this.slideIndex]);
        const y = (this.yVariation(x) * (this.animationHeight / this.slideNumber)) + this.slideIndex * (this.animationHeight / this.slideNumber);
        this.dom.blueBird.style.transform = `translate(${(x * (this.animationWidth / this.slideNumber)) + this.slideIndex * (this.animationWidth / this.slideNumber)}px, ${-y}px)`;

    }

    initSwiper() {
        if (window.innerWidth > 768) {
            if (!this.isSwiperDesktop) {
                // Handles resize
                if (this.swiper) {
                    this.swiper.destroy(true, true);
                }
                this.isSwiperMobile = false;
                this.isSwiperDesktop = true;

                this.swiper = new Swiper(this.dom.swiperContainerDesktop, {
                    modules: [Autoplay, Pagination, Navigation, Mousewheel, FreeMode, Parallax],
                    direction: 'horizontal',
                    grabCursor: 'true',
                    slidesPerView: 'auto',
                    parallax: true,
                    freeMode: true,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    on: {
                        touchEnd: () => {
                            // Send google event
                            if (typeof dataLayer !== 'undefined') {
                                dataLayer.push({ 'event': 'clicSwiper', 'swiperClic': 'drag -' + this.dates[this.slideIndex] });
                            }
                        }
                    }
                });
                this.swiper.on('setTranslate', this.onSliderMove.bind(this));
            }
        } else if (!this.isSwiperMobile) {
            // Handles resize
            if (this.swiper) {
                this.swiper.destroy(true, true);
                this.swiper.rei
            }
            this.isSwiperMobile = true;
            this.isSwiperDesktop = false;

            this.swiper = new Swiper(this.dom.swiperContainerMobile, {
                modules: [Pagination, Navigation, Mousewheel],
                wrapAround: false,
                cellAlign: 'center',
                prevNextButtons: false,
                pagination: {
                    el: this.dom.pagination,
                    clickable: true,
                    renderBullet: (index, className) => {
                        return '<div class="date-dot ' + className + '"><span class="label-date">' + this.dates[index] + '</span></div>';
                    },
                },
                adaptiveHeight: true,
                groupCells: false,
                watchCSS: true,
                on: {
                    touchEnd: () => {
                        // Send google event
                        if (typeof dataLayer !== 'undefined') {
                            dataLayer.push({ 'event': 'clicSwiper', 'swiperClic': 'drag -' + this.dates[this.slideIndex] });
                        }
                    }
                }
            });
        }
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
