export default class ScrollListener {

    _updateScrollTarget = false;

    constructor( callback = null ) {
        if( callback && typeof callback == 'function')
            this._callback = callback;
        else
            console.warn('You must specifiy a callback function to receive scroll\'s data');

        this._init();

    }

    _init() {
        // Get animation frame
        window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame ||
            // IE callback
            function(callback){ window.setTimeout(callback, 1000/60) };

        // Vars
        this._lastPosition = -1;
        this._top = 0;
        this._wHeight = window.innerHeight;
        this._checkPermanently = false;

        this._loopListener = this._loop.bind(this);

        this._loop();
    }

    _loop() {
        this._top = this.getScroll();

        // Avoid calculations if not needed
        if (this._lastPosition == this._top && this._checkPermanently == false) {
            // this._scroll( this._loopListener );
            window.requestAnimationFrame( this._loopListener );
            return false;
        } else
            this._lastPosition = this._top

        this._callback( this._top );

        // this._scroll( this._loopListener );
        window.requestAnimationFrame( this._loopListener );
    }

    getScroll() {
        if( this._updateScrollTarget == false ) {
            return (window.scrollY || window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
        }
        
    
        if( this._updateScrollTarget ) {
            return this._targetScroll.scrollTop;
        }
    }

    setCheckPermanently( value ) {
        this._checkPermanently = value;
    }

    updateScrollElement( target ) {

        if( target ) {
            this._updateScrollTarget = true;
            this._targetScroll = target;
        }
    }
}