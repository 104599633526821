import Component from '~/components/component';

export default class PopinPush extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();

    this.date = new Date(this.dom.component.dataset.date);
    this.dayRest = 0;
    this.time = 0;
    this.process = true;
  }

  _retrieveDOM() {
    this.dom.closeButton = this.dom.component.querySelector('.js-close');
    this.dom.stringDays = this.dom.component.querySelector('.js-string-days');
    this.dom.stringHours = this.dom.component.querySelector('.js-string-hours');
    this.dom.countDays = this.dom.component.querySelector('.js-count-days');
    this.dom.countHours = this.dom.component.querySelector('.js-count-hours');
  }

  bind() {
    const isPopin = localStorage.getItem('isPopin');

    if (
      this.dom.component.dataset.typePopin != 'none' &&
      !isPopin &&
      this.process
    ) {
      if (this.dom.component.dataset.typePopin === 'count') {
        //Calcul time left for the first time
        this.calcTime();

        this.checkDate();

        //Calcul time left to every hours
        setInterval(() => {
          this.calcTime();
        }, 60000);
      }

      //Close popin
      this.dom.closeButton.addEventListener('click', () => {
        this.closePopin();
      });

      //Click outside popin
      this.dom.component.addEventListener('click', (e) => {
        if (e.target.id === 'popin-push') {
          this.closePopin();
        }
      });

      localStorage.setItem('isPopin', 'true');
    } else {
      this.dom.component.style.display = 'none';
    }
  }
  closePopin() {
    this.dom.component.classList.remove('open');
    this.noScroll.enableScroll();
  }
  calcTime() {
    this.dateNow = new Date();
    this.seconds = Math.floor((this.date - this.dateNow) / 1000);
    this.minutes = Math.floor(this.seconds / 60);
    this.hours = Math.floor(this.minutes / 60);
    this.days = Math.floor(this.hours / 24);

    this.hours = this.hours - this.days * 24;
    this.minutes = this.minutes - this.days * 24 * 60 - this.hours * 60;
    this.seconds =
      this.seconds -
      this.days * 24 * 60 * 60 -
      this.hours * 60 * 60 -
      this.minutes * 60;

    this.hours =
      this.hours.toString().length > 1 ? this.hours : '0' + this.hours;
    this.minutes =
      this.minutes.toString().length > 1 ? this.minutes : '0' + this.minutes;
    this.days =
      this.days.toString().length > 1 ? this.days.toString() : '0' + this.days;

    //Split number
    this.dayRest = this.days.toString().split('');
    const formatTime = '' + this.hours + this.minutes;
    this.time = formatTime.toString().split('');

    this.days == 0 ? this.dateHours() : this.dateDays();
  }
  dateDays() {
    this.dom.stringDays.classList.add('show');
    this.dom.stringHours.classList.remove('show');
    this.dom.countDays.classList.add('show');
    this.dom.countHours.classList.remove('show');

    this.formatInsertHtml(false);
  }
  dateHours() {
    this.dom.stringDays.classList.remove('show');
    this.dom.stringHours.classList.add('show');
    this.dom.countDays.classList.remove('show');
    this.dom.countHours.classList.add('show');

    this.formatInsertHtml(true);
  }
  formatInsertHtml(isHours) {
    const string = isHours ? this.time : this.dayRest;
    const classString = isHours ? '.count-hours' : '.count-days';
    this.dom.spanCount =
      this?.dom?.component?.querySelectorAll(classString + ' .js-span-count') ||
      [];

    for (let index = 0; index < string.length; index++) {
      // Vérifiez que l'index est valide
      if (
        index < this.dom.spanCount.length &&
        this.dom.spanCount[index] != null
      ) {
        // Mettez à jour la valeur de l'élément correspondant dans this.dom.spanCount
        this.dom.spanCount[index].innerHTML = string[index];
      }
    }
  }
  checkDate() {
    if (this.date.getTime() < this.dateNow.getTime()) {
      this.process = false;
      this.dom.component.style.display = 'none';
    }
  }

  /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
