import Flickity from "flickity";
import Page from '~/pages/page';

export default class MdmIndex extends Page {

    _classes = {
        sliderAction : 'js-mdm-slider-action-container',
        sliderActionSlide: 'js-mdm-slider-action-slide',
        sliderFightFirst : 'js-mdm-slider-fight-first-container',
        sliderFightFirstSlide: 'js-mdm-slider-fight-first-slide'
    };

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.sliderAction = {
            container : this.dom.component.querySelector( '.' + this._classes.sliderAction ),
            slides : null
        };
        this.dom.views.sliderAction.slides =  [].slice.call( this.dom.views.sliderAction.container.querySelectorAll( '.' + this._classes.sliderActionSlide ) );

        this.dom.views.fight = {};
        this.dom.views.fight.sliderFirst = {
            container : this.dom.component.querySelector( '.' + this._classes.sliderFightFirst ),
            slides : null
        }
        this.dom.views.fight.sliderFirst.slides =  [].slice.call( this.dom.views.fight.sliderFirst.container.querySelectorAll( '.' + this._classes.sliderFightFirstSlide ) );
    }

    init() {
        super.init();

        // this._initSlider();
    }


    /**
     * Init Flickity Slider
     */
     _initSlider() {

        if( this.dom.views.sliderAction.slides.length > 1 ) {

            this._sliderActionFlickity = new Flickity( this.dom.views.sliderAction.container, {
                wrapAround: false,
                cellAlign: 'center',
                prevNextButtons: false,
                pageDots: true,
                adaptiveHeight: true,
                groupCells: false,
                watchCSS: true
            });
        }

        if( this.dom.views.fight.sliderFirst.slides.length > 1 ) {

            this._sliderFightFlickity1 = new Flickity( this.dom.views.fight.sliderFirst.container, {
                wrapAround: false,
                cellAlign: 'center',
                prevNextButtons: true,
                pageDots: true,
                adaptiveHeight: true,
                groupCells: false,
                watchCSS: true,
                arrowShape: 'M39.7,49.7H62 M39,49.7l5.2,5.3 M39,49.7l5.2-5.2'
            });
        }
    }


    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
