import Swiper, { Mousewheel } from 'swiper';
import Component from '~/components/component';

export default class Slider extends Component {

    constructor(...args) {
        super(...args);
        this.retrieveDOM();
        this.initSwiper();
    }

    retrieveDOM() {
        this.dom.slides = this.dom.component.querySelectorAll('.swiper-slide');
    }

    initSwiper() {

        var initialSlideId = Array.from(this.dom.slides).findIndex(getInitialSlide);

        function getInitialSlide(slide) {
          return slide.classList.contains('initial-slide');
        }
        
        const swiper = new Swiper(this.dom.component, {
            modules: [Mousewheel],
            direction: 'horizontal',
            grabCursor: 'true',
            loop: true,
            slidesPerView: "auto",
            centeredSlides: true,
            initialSlide: initialSlideId,
            mousewheel: {
                forceToAxis: true,
            },
        });
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}