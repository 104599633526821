import Swiper, { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';
import Component from '~/components/component';

export default class SliderSmall extends Component {

    constructor(...args) {
        super(...args);
        this.retrieveDOM();
        this.initSwiper();
    }

    retrieveDOM() {
        this.dom.prevButton = this.dom.component.parentNode.querySelector('.button.prev');
        this.dom.nextButton = this.dom.component.parentNode.querySelector('.button.next');
    }

    initSwiper() {

        let slidesPerView = ( this.dom.component.dataset.slidesPerView ) ? parseInt( this.dom.component.dataset.slidesPerView ) : false;

        const swiper = new Swiper(this.dom.component, {
            modules: [Autoplay, Pagination, Navigation, Mousewheel],
            direction: 'horizontal',
            grabCursor: 'true',
            slidesPerView: ( slidesPerView ) ? slidesPerView : 'auto',

            mousewheel: {
                forceToAxis: true,
            },

            navigation: {
                prevEl: this.dom.prevButton,
                nextEl: this.dom.nextButton,
            },

            pagination: {
                el: this.dom.component.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
        });
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}