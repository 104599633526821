import Swiper, { Autoplay, Mousewheel, Navigation } from 'swiper';
import Component from '~/components/component';
import NoScroll from '../../helpers/no-scroll';

export default class PopinVideo extends Component {
	constructor(...args) {
		super(...args);
		this.players = [];
		this._retrieveDOM();
		this.swiper = undefined;
		this.noScroll = new NoScroll();

	}
	_retrieveDOM() {
		this.dom.cardVideo = document.body.querySelectorAll('.js-popin-video');
		this.dom.wrapperSlider = this.dom.component.querySelector('.js-wrapper-slider');
		this.dom.prevButton = this.dom.component.querySelector('.js-prev');
		this.dom.nextButton = this.dom.component.querySelector('.js-next');
		this.dom.closeButton = this.dom.component.querySelector('.js-close');
	}
	bind() {
		this.initSwiper();

		this.getValuesCards();

		//Open popin
		for (let i = 0; i < this.dom.cardVideo.length; i++) {
			this.dom.cardVideo[i].addEventListener('click', () => {
				this.openPopin(i);
			});
		}
		//Close popin
		this.dom.closeButton.addEventListener('click', () => {
			this.closePopin();
		});

		//Click outside popin
		this.dom.component.addEventListener('click', (e) => {
			if (e.target.id === "popin-video") {
				this.closePopin();
			}
		});

		//Pause video on slide change
		this.swiper.on('slideChange', () => {
			//reset iframe player src for stoping video
			this.players[this.swiper.previousIndex].src = this.players[this.swiper.previousIndex].src;
		})
	}
	getValuesCards() {
		for (let i = 0; i < this.dom.cardVideo.length; i++) {
			this.insertSliderPopin(this.dom.cardVideo[i], i + 1);
		}

		this.players = this.dom.component.querySelectorAll('.video');

		this.swiper.update();
	}
	insertSliderPopin(el, i) {
		const src = el.dataset.srcVideo;
		const type = el.dataset.type;
		const title = el.dataset.title;
		const tag = el.dataset.tag;
		const iframe = '<iframe id="iframe' + i + '" class="video" src="' + src + '" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>';

		//Insert slide in wrapper
		const slide = '<div class="swiper-slide">' + iframe + '<span class="tag">' + tag + '</span><span class="title">' + title + '</span></div>'
		this.dom.wrapperSlider.insertAdjacentHTML('beforeend', slide);
	}
	initSwiper() {
		this.swiper = new Swiper(this.dom.component.querySelector('.js-swiper-container'), {
			modules: [Autoplay, Navigation, Mousewheel],
			direction: 'horizontal',

			mousewheel: {
				forceToAxis: true,
			},

			speed: 500,

			navigation: {
				prevEl: this.dom.prevButton,
				nextEl: this.dom.nextButton,
			},
		});
	}
	openPopin(i) {
		this.swiper.slideTo(i, 0);
		this.dom.component.classList.add('open');
		this.noScroll.disableScroll();
	}
	closePopin() {
		//reset iframe player src for stoping video
		this.players[this.swiper.activeIndex].src = this.players[this.swiper.activeIndex].src;
		this.dom.component.classList.remove('open');
		this.noScroll.enableScroll();
	}
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
