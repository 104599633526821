import Component from '~/components/component';

export default class Timeline extends Component {
    constructor(...args) {
        super(...args);
        this.init();
    }
    
  
    init() {
        this._retrieveDOM();
        this.bind();
    }

    showMore(e) {
        const target = e.currentTarget;
        const container = target.parentNode;
        const hiddenWrapper = container.querySelector(".hidden");
        hiddenWrapper.classList.remove("hidden");
        target.classList.add("hidden");
    }

    _retrieveDOM() {
        this.dom = {};
        this.dom.buttons = document.body.querySelectorAll(".timeline-load-more");
    }

    bind() {
        this.dom.buttons.forEach(button => {
            if(!button.hasAttribute("listener")) {
                button.removeEventListener("click", this.showMore);
                button.addEventListener("click", this.showMore);
                button.setAttribute("listener", true);
            }
        });
    }
}
