// all keycodes to block
export const keyCodes = [
	'ArrowDown',
	'ArrowUp',
	'ArrowLeft',
	'ArrowRight',
	'Space',
	'PageDown',
	'PageUp',
	'End',
	'Home',
];
export const options = { passive: false };

/**
 * Lock all scroll in page
 */
 export default class NoScroll {
	constructor(){

	}

	@autobind
	_preventDefault(e) {
		e.preventDefault();
	}

	@autobind
	_preventDefaultForScrollKeys(e) {

		if (keyCodes.includes(e.code)) {
			preventDefault(e);
			return false;
		}
	}
	disableScroll() {
		console.log('disable');
		window.addEventListener('wheel', this._preventDefault, options);
		window.addEventListener('touchmove', this._preventDefault,options);
		window.addEventListener('keydown',this._preventDefaultForScrollKeys);
	}
	
	enableScroll() {
		window.removeEventListener('wheel', this._preventDefault);
		window.removeEventListener('touchmove', this._preventDefault);
		window.removeEventListener('keydown', this._preventDefaultForScrollKeys);
	}
	
	
}
