export const map = {
    components: {
        dateSliderHorizontal: require('components/date_slider_horizontal/date_slider_horizontal'),
        map: require('components/map/map'),
        jobSlider: require('components/job-slider/job-slider'),
        expandItem: require('components/expand-item/expand-item'),
        keyFiguresSmall: require('components/key_figures_small/key_figures_small'),
        logosSlider: require('components/logos_slider/logos_slider'),
        timeline: require('components/timeline/timeline'),
        expandContent: require('components/expand_content/expand_content'),
        customCursor: require('components/custom-cursor/custom-cursor'),
        intervention: require('components/intervention/intervention'),
        intervention: require('components/intervention/intervention'),
        menu: require('components/menu/menu'),
        select: require('components/select/select'),
        brief: require('components/brief/brief'),
        filters: require('components/filters/filters'),
        "newsletter-popin": require('components/newsletter-popin/newsletter-popin'),
        // 'picto-key-figures': require('components/picto-key-figures/picto-key-figures'),
        "slider": require('components/slider/slider'),
        "slider-small": require('components/slider/slider-small'),
        "slider-filters": require('components/slider/slider-filters'),
        "related-news": require('components/related-news/related-news'),
        "introduction": require('components/introduction/introduction'),
        "social-network-share": require('components/social-network-share/social-network-share'),
        "reading-banner": require('components/reading-banner/reading-banner'),
        "popin-video": require('components/popin-video/popin-video'),
        "socials": require('components/socials/socials'),
        // "list-push-link": require('components/list-push-link/list-push-link'),
        "globe": require('components/globe/globe'),
        "dates-slider": require('components/dates-slider/dates-slider'),
        "banner-hover": require('components/banner-hover/banner-hover'),
        "list-testimonial": require('components/list-testimonial/list-testimonial'),
        "block-history": require('components/block-history/block-history'),
        "slider-2": require('components/slider-2/slider-2'),
        "push-next-date": require('components/push-next-date/push-next-date'),
        "header-5": require('components/header-5/header-5'),
        "page-transition": require('components/page-transition/page-transition'),
        "block-text-2": require('components/block-text-2/block-text-2'),
        "footer": require('components/footer/footer'),
        "popin-push": require('components/popin-push/popin-push'),
    },
    pages: {
        homepage: {
            index: require('pages/homepage/homepage-index')
        },
        master: {
            index: require('pages/master/master-index')
        },
        contact: {
            index: require('pages/contact/contact-index')
        },
        news: {
            entry: require('pages/news/news-entry'),
            list: require('pages/news/news-list')
        },
        report: {
            list: require('pages/report/report-list'),
            entry: require('pages/report/report-entry')
        },
        event: {
            entry: require('pages/event/event-entry')
        },
        resource: {
            list: require('pages/resource/resource-list')
        },
        publication: {
            list: require('pages/publication/publication-list'),
            entry: require('pages/publication/publication-entry')
        },
        statement: {
            list: require('pages/statement/statement-list'),
            entry: require('pages/statement/statement-entry'),
        },
        newspaper: {
            list: require('pages/newspaper/newspaper-list'),
            entry: require('pages/newspaper/newspaper-entry'),
        },
        podcast: {
            list: require('pages/podcast/podcast-list'),
            entry: require('pages/podcast/podcast-entry'),
        },
        campaign: {
            list: require('pages/campaign/campaign-list'),
            entry: require('pages/campaign/campaign-entry'),
        },
        tribune: {
            list: require('pages/tribune/tribune-list'),
            entry: require('pages/tribune/tribune-entry'),
        },
        testimony: {
            list: require('pages/testimony/testimony-list'),
            entry: require('pages/testimony/testimony-entry'),
        },
        'media-video': {
            list: require('pages/media-video/media-video-list')
        },
        action: {
            entry: require('pages/action/action-entry')
        },
        date: {
            list: require('pages/date/date-list'),
            entry: require('pages/date/date-entry')
        },
        search: {
            index: require('pages/search/search-index')
        },
        mdm: {
            index: require('pages/mdm/mdm-index')
        },
        country: {
            entry: require('pages/country/country-entry')
        },
        city: {
            entry: require('pages/city/city-entry')
        },
        territory: {
            entry: require('pages/territory/territory-entry')
        },
        'in-the-field': {
            index: require('pages/in-the-field/in-the-field-index')
        },
        'join-us': {
            index: require('pages/join-us/join-us-index')
        },
        'work-at-mdm': {
            index: require('pages/work-at-mdm/work-at-mdm-index')
        },
        'work-in-world': {
            index: require('pages/work-in-world/work-in-world-index')
        },
        'spontaneous-application': {
            index: require('pages/spontaneous-application/spontaneous-application-index')
        },
        job: {
            entry: require('pages/job/job-entry'),
            list: require('pages/job/job-list')
        },
        'support-us': {
            index: require('pages/support-us/support-us-index')
        },
        'meet-us': {
            index: require('pages/meet-us/meet-us-index')
        },
        'international-network': {
            index: require('pages/international-network/international-network-index')
        },
        partner: {
            list: require('pages/partner/partner-list')
        },
        'press-release': {
            entry: require('pages/press-release/press-release-entry'),
            list: require('pages/press-release/press-release-list')
        },
        'press-media': {
            list: require('pages/press-media/press-media-list')
        },
        'press-kit': {
            list: require('pages/press-kit/press-kit-list')
        },
        'press-area': {
            index: require('pages/press-area/press-area-index')
        },
        volunteering: {
            index: require('pages/volunteering/volunteering-index')
        },
        generic: {
            index: require('pages/generic/generic-index')
        },
    }
};
