import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText';
import Page from '~/pages/page';
gsap.registerPlugin(ScrollTrigger);

export default class HomepageIndex extends Page {
    constructor(...args) {
        super(...args);

        this._retrieveDOM();
        this._initTls();
        this.wordSplit = null;

        if (this.Components.hasOwnProperty('Globe')) {
            this.Components.Globe.on('open-popin', () => {
                this.Components.Menu.handleOpenPopin();
            })
            this.Components.Globe.on('close-popin', () => {
                this.Components.Menu.handleClosePopin();
            })
        }
    }

    _retrieveDOM() {
        this.dom.hero = this.dom.page.querySelector('.ct--introduction');
        this.dom.newsTitle = this.dom.page.querySelector('.ct--home-news-subtitle');
    }

    init() {
        const highlights = gsap.utils.toArray('.highlighting');
        highlights.forEach((btn) => {
            gsap.from(btn, {
                scrollTrigger: {
                    start: '10% 90%',
                    trigger: btn,
                    toggleClass: 'enable',
                    once: true
                }
            });
        });
        const banners = gsap.utils.toArray('.animated-banner');
        banners.forEach((btn) => {
            gsap.from(btn, {
                scrollTrigger: {
                    start: '10% 90%',
                    trigger: btn,
                    toggleClass: 'enable',
                    once: true
                }
            });
        });
        const cards = gsap.utils.toArray('.animated-card');
        cards.forEach((card) => {
            gsap.from(card, {
                scrollTrigger: {
                    start: '10% 90%',
                    trigger: card,
                    toggleClass: 'enable',
                    once: true
                }
            });
        });

        this.wordSplit = new SplitText(this.dom.newsTitle, { type: "words,lines", wordsClass: "word-animation", linesClass: "line-animation" });

        gsap.from(this.dom.newsTitle, {
            scrollTrigger: {
                start: '10% 85%',
                trigger: this.dom.newsTitle,
                toggleClass: 'enable',
                once: true
            }
        });

        window.addEventListener("load", () => {
            setTimeout(() => {
                this.dom.hero.classList.add('animate');
            }, 900);
            setTimeout(() => {
                this.dom.hero.classList.add('remove-overflow');
            }, 1900);
        });
    }

    resize() {
        this.wordSplit.revert();
        this.wordSplit = new SplitText(this.dom.newsTitle, { type: "words,lines", wordsClass: "word-animation", linesClass: "line-animation" });
        const highlights = gsap.utils.toArray('.highlighting');
        highlights.forEach((btn) => {
            gsap.from(btn, {
                scrollTrigger: {
                    start: '10% 90%',
                    trigger: btn,
                    toggleClass: 'enable',
                    once: true
                }
            });
        });
    }


    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
