import Page from '~/pages/page';
import FormValidator from '../../helpers/form-validator';

export default class ContactIndex extends Page {

    _classes = {
        formContainer: 'js-contact-form-container',
        formInput: 'js-contact-form-input',
        formSelect: 'js-contact-form-select',
        formContainerButton: 'js-contact-form-container-button',
        formButton: 'js-contact-form-button',
        formResult: 'js-contact-form-result',
        formError: 'js-contact-form-error',

        error: 'error',
        success: 'success',
        show: 'show',
        hide: 'hide',
        emailInvalid: 'email-invalid',
        loading: 'loading',
        notRequired: 'not-required',

        object_select: 'js-object-select',
        grids: 'js-grid'
    };

    _isProcess = false;

    _actionSendForm = 'mdm_contact_send_form';

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
        this._initTls();
        this.selectedIndex = 0;
    }

    _retrieveDOM() {
        this.dom.views = {};

        this.dom.views.form = {
            container: this.dom.page.querySelector('.' + this._classes.formContainer),
            inputs: [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formInput)),
            selects: [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formSelect)),
            containerButton: this.dom.page.querySelector('.' + this._classes.formContainerButton),
            button: this.dom.page.querySelector('.' + this._classes.formButton),
            result: this.dom.page.querySelector('.' + this._classes.formResult),
            error: this.dom.page.querySelector('.' + this._classes.formError),
            // objectSelect: this.dom.page.querySelector('.' + this._classes.object_select),
            grids: this.dom.page.querySelectorAll('.' + this._classes.grids),
            moreSection: [].slice.call( this.dom.page.querySelectorAll('.js-more-section') ),
            moreButton: this.dom.page.querySelector('.js-more'),
        };
    }

    init() {
        super.init();

        this._initForm();
    }

    /**
     * Init form with FormValidator class
    */
    _initForm() {

        /** Init Form Validator **/
        this._Validator = new FormValidator({
            'inputs': this.dom.views.form.inputs,
            'parent': false
        });
    }

    _handleSubjectChange( e ) {

        let selectIndexActive = e.target.selectedIndex;

        this.dom.views.form.grids[ this.selectedIndex ].classList.add( this._classes.hide );

        if( selectIndexActive == 0 ) {
            this._hideMoreContact( true );
        } else {
           
            this.selectedIndex = selectIndexActive - 1;

            this.dom.views.form.grids[this.selectedIndex].classList.remove( this._classes.hide );
            // if (this.dom.views.form.moreSection.classList.contains('hide')) {
                this._hideMoreContact( false );
            // }
        }    
    }

    _hideMoreContact( to_hide ) {
        // this.dom.views.form.moreSection.classList.toggle('hide');
        this.dom.views.form.moreSection.map( item  => {
           if( to_hide ) {
                item.classList.add( this._classes.hide ); 
           } else {
                item.classList.remove( this._classes.hide ); 
           }
        });

        this.dom.views.form.container.classList.add( this._classes.hide );
    }

    handleMoreClick( event ) {

        this.dom.views.form.moreSection.map( item  => {
            if( item.classList.contains( 'more' ) ) {
                 item.classList.add( this._classes.hide ); 
            }
         });
         
        this.dom.views.form.container.classList.remove( this._classes.hide );
    }

    bind() {
        super.bind();

        ['_changeSelectValue', '_validate']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        // Select input
        if (this.dom.views.form.selects) {
            for (let i = 0, j = this.dom.views.form.selects.length; i < j; i++) {
                this.dom.views.form.selects[i].addEventListener('change', this._changeSelectValue);
            }
        }

        // Validate Form
        this.dom.views.form.button.addEventListener('click', this._validate);

        // if (this.dom.views.form.objectSelect) {
        //     this.dom.views.form.objectSelect.addEventListener('change', this._handleSubjectChange.bind(this));
        // }

        this.dom.views.form.moreButton.addEventListener('click', this.handleMoreClick.bind(this));
    }

    _changeSelectValue(event) {

        this._checkSelectValue(event);

        let target = event.currentTarget;
     
        if( target.getAttribute( 'name') == "subject" ){

            this._handleSubjectChange( event );
        }

    }
    /**
    * The user changes the value of a select
    */
    _checkSelectValue(event) {

        let target = event.currentTarget;

        if (target.value != '') {
            target.parentNode.classList.remove(this._classes.error);
            target.parentNode.classList.add(this._classes.success);
        } else {
            target.parentNode.classList.add(this._classes.error);
            target.parentNode.classList.remove(this._classes.success);
        }
    }

    /**
     * Check if all the fields of type "select" are filled
     * @returns boolean
    */
    _selectIsValid() {

        let isValid = true;

        for (let i = 0, j = this.dom.views.form.selects.length; i < j; i++) {

            let select = this.dom.views.form.selects[i];

            if (select.value == '') {
                select.parentNode.classList.add(this._classes.error);
                isValid = false;
            } else {
                select.parentNode.classList.remove(this._classes.error);
            }
        }

        return isValid;
    }

    /**
     * Retrieving the values ​​of all "select" type fields
     * @returns data : array of select input values
    */
    _selectGetData() {

        let data = {};

        for (let i = 0, j = this.dom.views.form.selects.length; i < j; i++) {
            let select = this.dom.views.form.selects[i];
            data[select.name] = select.value;
        }

        return data;
    }

    /**
    * The user clicks on the "send" button of the form
    * @param event
    */
    _validate(event) {

        let errors = [];
        let emailValid = true;

        // Hide message error
        if (this.dom.views.form.error) {
            this.dom.views.form.error.classList.remove(this._classes.show);
            this.dom.views.form.error.classList.remove(this._classes.emailInvalid);
        }

        // Check process
        if (this._isProcess == false) {

            this._isProcess = true;

            document.body.classList.add(this._classes.loading);
            this.dom.views.form.button.classList.add(this._classes.loading);

            let data = new FormData();

            // Check Form Selects
            if (this.dom.views.form.selects) {

                if (this._selectIsValid()) {

                    // Get Select Fields data
                    let selects = this._selectGetData();
                    for (let item in selects) {
                        console.log(item, ' ::: ', selects[item]);
                        data.append(item, selects[item]);
                    }

                } else {
                    errors.push('Select not valid');
                }
            }

            // Check Form inputs
            if (this._Validator.isValid()) {

                // Get Form Fields data
                let inputs = this._Validator.getData();

                for (let item in inputs) {
                    console.log(item, ' ::: ', inputs[item]);
                    data.append(item, inputs[item]);
                }

            } else {
                errors.push('Form not valid');
            }

            // Check Form inputs type "email"
            if (this._Validator.isValidEmail() == false) {
                emailValid = false;
                errors.push('Form email not valid');
            }

            // Check errors
            if (errors.length > 0) {

                // Show message error
                if (this.dom.views.form.error) {

                    if (emailValid == false) {
                        this.dom.views.form.error.classList.add(this._classes.emailInvalid);
                    }

                    this.dom.views.form.error.classList.add(this._classes.show);
                }

                this._isProcess = false;
                document.body.classList.remove(this._classes.loading);
                this.dom.views.form.button.classList.remove(this._classes.loading);

                console.log(errors);
                return;
            }

            // Request
            this._request(data);
        }
    }

    /**
    * Request "Form Contact Send"
    * @param data (FormData)
    */
    _request(data) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/wp/wp-admin/admin-ajax.php?action=' + this._actionSendForm, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this._response((JSON.parse(xhr.response)));
            }
            this._isProcess = false;
            document.body.classList.remove(this._classes.loading);
            this.dom.views.form.button.classList.remove(this._classes.loading);

        }.bind(this);
        xhr.send(data);
    }

    /**
    * Response to the request to send the form
    * @param response Ajax
    */
    _response(response) {

        if (response.success) {
            // Bloc "Message bien envoyé"
            this.dom.views.form.containerButton.classList.add(this._classes.success);

            // Send google event
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ 'event': 'formSubmit', 'form-name': 'contact', 'form-location': '' });
            }
        } else {
            // Bloc "Erreur dans l'envoi du message"
            this.dom.views.form.result.classList.add(this._classes.error);
        }

        this.dom.views.form.result.innerHTML = response.data.message;
        this.dom.views.form.result.classList.add(this._classes.show);
    }

    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
