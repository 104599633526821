import Component from '~/components/component';
import Router from "../../helpers/Router";

// TODO
// Need to improve this code as certain function
// are delayed to match CSS transition timing

export default class Menu extends Component {

    _searchOpened = false;

    constructor(...args) {
        super(...args);
        this.forceHeaderHide = false;
        this.isOpen = false;
        this.isSubmenuOpen = false;
        this.currentSubmenu = -1;
        this.menuHasBeenReseted = true;
        this.memoScroll = 0;

        const limiteDevice = 768;
        this._isDesktop = ( window.innerWidth > limiteDevice );

        this.MENU_SCROLL_TIMING = this.dom.component.classList.contains('isBanner') && !this._isDesktop ? 800 : 300;
        

        this._retrieveDOM();
        this._addListeners();
    }

    _retrieveDOM() {
        this.dom.button = this.dom.component.querySelector('.burger-icon');
        this.dom.circles = this.dom.component.querySelectorAll('.circle');
        this.circlesScaleFactor = 0;
        this.burgerRadius = {
            mobile: 25,
            desktop: 45,
            large: 67.5,
        }
        this.computeCirclesScaleFactor();

        this.dom.header = this.dom.component.querySelector('.header');
        this.dom.menu = this.dom.component.querySelector('.menu-container');
        this.dom.menuButtons = this.dom.component.querySelectorAll('.item-button');
        this.dom.menuItems = this.dom.component.querySelectorAll('.menu-item');
        this.dom.returnButtons = this.dom.component.querySelectorAll('.return-button');
        this.dom.submenuList = this.dom.component.querySelectorAll('.submenu');
        this.dom.submenuContainer = this.dom.component.querySelectorAll('.submenu-container');
        this.dom.menuList = this.dom.component.querySelector('.menu-list');
        this.dom.bottomSubnav = this.dom.component.querySelector('.subnav.only-desktop');
        this.dom.menuInner = this.dom.component.querySelector('.menu-inner');
        this.dom.bannerPush = document.querySelector('.ct--banner-push');

        this.dom.search = {
            container: this.dom.component.querySelector('.js-menu-search-container'),
            content: this.dom.component.querySelector('.js-menu-search-content'),
            buttonOpen: this.dom.component.querySelector('.js-menu-search-button-open'),
            input: this.dom.component.querySelector('.js-menu-search-input'),
            button: this.dom.component.querySelector('.js-menu-search-button')
        };

        this.dom.header.style.transitionDuration = this.MENU_SCROLL_TIMING + "ms";
        this.handleScroll();
    }

    computeCirclesScaleFactor() {
        const rect = this.dom.circles[0].getBoundingClientRect();
        let burgerRadius = this.burgerRadius.mobile;

        if (window.innerWidth > 1920) {
            burgerRadius = this.burgerRadius.large;
        } else if (window.innerWidth > 768) {
            burgerRadius = this.burgerRadius.desktop;
        }

        let x, y;

        if (this.isOpen) {
            x = rect.x + (burgerRadius * this.circlesScaleFactor);
            y = rect.y + (burgerRadius * this.circlesScaleFactor);
        } else {
            x = rect.x + burgerRadius;
            y = rect.y + burgerRadius;
        }

        const heightSide = window.innerHeight - (y + burgerRadius);
        const widthSide = x + burgerRadius;
        const hypothenus = Math.sqrt(Math.pow(heightSide, 2) + Math.pow(widthSide, 2));
        this.circlesScaleFactor = Math.round(hypothenus / burgerRadius * 100) / 100;
    }

    _addListeners() {
        window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true });
        window.addEventListener('mousemove', this.handleMouseMove.bind(this), { passive: true });
    }

    handleOpenPopin() {
        this.forceHeaderHide = true;
        this.dom.header.classList.remove('show');
        document.body.classList.add('locked-scroll');
        document.documentElement.classList.add('locked-scroll');
    }

    handleClosePopin() {
        this.forceHeaderHide = false;
        document.body.classList.remove('locked-scroll');
        document.documentElement.classList.remove('locked-scroll');
    }

    handleMouseMove(e) {
        const mouseY = e.pageY - window.scrollY;

        if (mouseY <= 100 && !this.forceHeaderHide) {
            this.dom.header.classList.add('show');
        }
    }

    handleScroll() {
        const scroll = window.scrollY;
        if (scroll > 50) {
            this.dom.header.classList.add('scrolled');
            if (this.dom.bannerPush && !this._isDesktop) {
                this.dom.component.style.transform = 'translateY(-' + (this.dom.bannerPush.offsetHeight) + "px" + ')';
            }
            setTimeout(() => {
                this.dom.header.classList.add('small-icons');
            }, this.MENU_SCROLL_TIMING);
        } else {
            this.dom.header.classList.remove('scrolled');
            this.dom.header.classList.remove('small-icons');
            if (this.dom.bannerPush && !this._isDesktop) {
                this.dom.component.style.transform = '';
            }
        }
        if (scroll - this.memoScroll < 0) {
            this.dom.header.classList.add('show');
        } else {
            this.dom.header.classList.remove('show');
        }

        this.memoScroll = scroll;
    }

    handleBurgerClick() {

        // Hide search
        if (this._searchOpened) {
            this._closeSearchMenu();
        }

        //Disable scroll
        document.body.classList.toggle('locked-scroll');
        document.documentElement.classList.toggle('locked-scroll');

        this.dom.button.classList.toggle('open');
        this.dom.header.classList.toggle('open');
        this.dom.menu.classList.toggle('open');
        this.dom.component.classList.toggle('open');

        if (this.isOpen) {
            this.dom.circles[1].style.transform = "";
            this.dom.circles[0].style.transform = "";
        } else {
            this.dom.circles[0].style.transform = `scale(${this.circlesScaleFactor})`;
            this.dom.circles[1].style.transform = `scale(${this.circlesScaleFactor})`;
        }

        this.isOpen = !this.isOpen;
        setTimeout(() => {
            this.dom.submenuList.forEach(submenu => {
                submenu.classList.toggle('hidden');
            });
        }, 500)
        if (!this.isOpen) {
            this.resetMenu();
        }
    };

    resetMenu() {
        if (!this.menuHasBeenReseted) {
            const i = this.currentSubmenu;
            this.dom.menuItems.forEach((item) => {
                item.classList.remove('hidden');
            });
            if (this.currentSubmenu > -1) {
                this.dom.menuButtons[i].style.removeProperty('transform');
                this.dom.submenuList[i].classList.remove('open');
                this.dom.bottomSubnav.classList.remove('hidden');
            }
            this.dom.menuList.classList.remove('open');
            this.dom.menuInner.classList.remove('open');
            this.isSubmenuOpen = false;
            this.menuHasBeenReseted = true;
            this.dom.menuItems[i].classList.remove('active');
        }
    }

    resize() {
        if (window.innerWidth > 768) {
            this.resetMenu();
        }
        this.computeCirclesScaleFactor();
    }

    handleMenuClick(i) {
        this.isSubmenuOpen = !this.isSubmenuOpen;
        this.menuHasBeenReseted = false;
        this.currentSubmenu = i;
        if (this.isSubmenuOpen) {
            const from = this.dom.menuItems[i].getBoundingClientRect();
            const to = this.dom.submenuContainer[i].getBoundingClientRect();
            this.dom.menuButtons[i].style.transform = `translateY(-${from.y - to.y}px)`;
        }
        if (!this.isSubmenuOpen) {
            this.dom.menuButtons[i].style.removeProperty('transform');
            this.currentSubmenu = -1;
        }

        this.dom.menuItems.forEach((item, index) => {
            if (index !== i) {
                item.classList.toggle('hidden');
            }
            else {
                item.classList.toggle('active');
            }
        });

        this.dom.bottomSubnav.classList.toggle('hidden');
        this.dom.menuList.classList.toggle('open');
        this.dom.menuInner.classList.toggle('open');
        this.dom.submenuList[i].classList.toggle('open');
    }

    handleMenuClickDelayed(i) {
        this.isSubmenuOpen = !this.isSubmenuOpen;
        if (!this.isSubmenuOpen) {
            this.dom.menuButtons[this.currentSubmenu].style.removeProperty('transform');
        }
        this.dom.menuList.classList.toggle('open');
        this.dom.menuInner.classList.toggle('open');
        this.dom.menuItems[i].classList.toggle('active');
        setTimeout(() => {
            this.dom.submenuList[i].classList.toggle('open');
        }, 400);
    }

    bind() {

        if (this.dom.component.classList.contains('isBanner') && !this._isDesktop) {
            this.dom.component.style.top = this.dom.bannerPush.offsetHeight + "px";
        }

        ['_keyupInputSearch', '_clickButtonSearch', '_mouseEnterContainerSearch', '_mouseLeaveContainerSearch', '_clickButtonOpenSearch']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        if (this.dom.search.container) {
            this.dom.search.container.addEventListener('mouseenter', this._mouseEnterContainerSearch);
            this.dom.search.container.addEventListener('mouseleave', this._mouseLeaveContainerSearch);
        }

        if (this.dom.search.input) {
            this.dom.search.input.addEventListener('keyup', this._keyupInputSearch);
        }

        if (this.dom.search.button) {
            this.dom.search.button.addEventListener('click', this._clickButtonSearch);
        }

        if (this.dom.search.buttonOpen) {
            this.dom.search.buttonOpen.addEventListener('click', this._clickButtonOpenSearch);
        }

        this.dom.menuButtons.forEach((button, i) => {
            button.addEventListener('click', this.handleMenuClick.bind(this, i));
        });

        this.dom.returnButtons.forEach((button, i) => {
            button.addEventListener('click', this.handleMenuClickDelayed.bind(this, i));
        });

        this.dom.button.addEventListener('click', this.handleBurgerClick.bind(this));
    }

    init() {
        super.init();

        this._searchURL = this.dom.search.button.dataset.searchUrl;
        this._initSearchValue = this.dom.search.input.value;
        if (this._searchURL == '') {
            let styleConsole = 'background: #222; color: #ff8c8c'; // green : bada55
            console.warn('%c no "search" found', styleConsole);
        }
    }

    _mouseEnterContainerSearch(event) {
        this.dom.header.classList.add('open-search');
    }

    _mouseLeaveContainerSearch(event) {
        this.dom.header.classList.remove('open-search');
    }

    _clickButtonOpenSearch(event) {
        if (this._searchOpened) {
            this._closeSearchMenu();
        } else {
            this._openSearchMenu();
        }
    }

    _openSearchMenu() {
        this._searchOpened = true;
        this.dom.search.buttonOpen.classList.add('open');
        this.dom.search.content.classList.add('open');
        this.dom.header.classList.add('open-search');
    }

    _closeSearchMenu() {
        this._searchOpened = false;
        this.dom.search.buttonOpen.classList.remove('open');
        this.dom.search.content.classList.remove('open');
        this.dom.header.classList.remove('open-search');
    }

    /**
     * Called when the user clicks on the keyboard keys while in the search input
     * @param event
     */
    _keyupInputSearch(event) {
        let keyCode = event.keyCode; // event.key

        // If the user presses "Enter", it starts the search
        if (keyCode == 13) {
            this._requestSearch();
        }
    }

    /**
     * Called when the user clicks on the "validate" button of the search
     * @param event
     */
    _clickButtonSearch(event) {
        this._requestSearch();
    }

    /**
     * Redirects to the search page with the search value in the URL
     */
    _requestSearch() {

        this._searchQuery = this.dom.search.input.value;

        // If the "search" page exists + the value to search for is not empty and is different from the current search value
        if (this._searchURL && this._searchQuery && this._searchQuery != this._initSearchValue) {
            let redirectURL = this._searchURL + '?q=' + encodeURIComponent(this._searchQuery);
            Router.redirect(redirectURL);
        }
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
