import Page from '~/pages/page';
import Router from "../../helpers/Router";

export default class SearchIndex extends Page {
    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.search = {
            input: this.dom.page.querySelector( '.js-search-input' ),
            
        };
    }

    init() {
        super.init();

        this._searchURL = this.dom.page.dataset.searchUrl;
        this._initSearchValue = this.dom.views.search.input.value;
        if( this._searchURL == '' ) {
            let styleConsole = 'background: #222; color: #ff8c8c'; // green : bada55
            console.warn('%c no "search" found', styleConsole);
        }
    }

    bind() {

        ['_keyupInputSearch']
        .forEach((fn) => this[fn] = this[fn].bind(this));

       
        if( this.dom.views.search.input ) {
            this.dom.views.search.input.addEventListener( 'keyup', this._keyupInputSearch );
        }
    }

     /**
     * Called when the user clicks on the keyboard keys while in the search input
     * @param event
     */
      _keyupInputSearch( event ) {
        let keyCode = event.keyCode; // event.key

        // If the user presses "Enter", it starts the search
        if( keyCode == 13 ) {
            this._requestSearch();
        }
    }

    /**
     * Redirects to the search page with the search value in the URL
     */
     _requestSearch() {

        this._searchQuery = this.dom.views.search.input.value;

        // If the "search" page exists + the value to search for is not empty and is different from the current search value
        if( this._searchURL && this._searchQuery && this._searchQuery != this._initSearchValue ) {
            let redirectURL = this._searchURL + '?q=' + encodeURIComponent( this._searchQuery );
            Router.redirect( redirectURL );
        }
    }


    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
