// import objectFitPolyfill from 'objectFitPolyfill';
// import RafManager from '../helpers/raf-manager';
import { gsap } from "gsap";
// import Scrollbar from 'smooth-scrollbar';
// import SmoothScrollMagic from '../helpers/smooth-scroll-magic';
// import Scroll from '../helpers/scroll';
// import WebGLCanvas from '../webgl/webgl-canvas';
// import testFS from '../webgl/shaders/test.frag';
import Component from '../components/component';
import ScrollListener from '../helpers/scroll-listener';
import States from '../helpers/states';

export const PageEvent = {
    NEXT: '__pe_next',
    PAGE_DESTROYED: '__pe_page_destroyed'
};

export default class Page extends Component {

    _scrollValue = {
        current: null,
        prev: null
    };

    constructor(page, components) {
        super();

        this.dom = {
            component: page,
            page,
            main: this._find('main'),
            scrollable: this._find('.scrollable'),
            container: this._find('.container'),
            scrollSections: this._findAll('.js-scroll-section')
        };

        this.Components = components;

        bindAll(this, [
            '_update',
            '_scrollHandler',
            '_next',
            '_destroy'
        ]);

        this._showTl = gsap.timeline({ paused: true });
        this._hideTl = gsap.timeline({
            paused: true,
            onStart: () => {
                this.dom.page.style.position = 'absolute';
                this.dom.page.style.top = -getWindowPosition() + 'px';

                // Reset scroll
                document.scrollingElement.scrollTop = document.scrollingElement.scrollLeft =
                    document.body.scrollTop = document.body.scrollLeft =
                    document.documentElement.scrollTop = document.documentElement.scrollLeft =
                    window.scrollTop = window.scrollLeft = 0;
            },
            onComplete: this._destroy
        });
    }

    _retrieveDOM() { }

    _initTls() {
        this._showTl
            .from(this.dom.page, { autoAlpha: 0, duration: 0 }, 0)

        this._hideTl
            .to(this.dom.page, { autoAlpha: 0, duration: 0 }, 0)
            .add(() => {
                this._next();
            }, 0)
    }

    init() {
        // if (this.scroll)
        //     this.scroll.init();

        this._ScrollListener = new ScrollListener( this._update );
    }

    bind() {
        // RafManager.on(this._update);
        // if (this.scroll)
        //     this.scroll.on(this._scrollHandler);

        ['_scroll']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        window.addEventListener('scroll', this._scroll, { passive: true });
    }

    unbind() {
        // RafManager.off(this._update);
        // if (this.scroll)
        //     this.scroll.off(this._scrollHandler);

        window.removeEventListener('scroll', this._scroll);
    }

    mediaLoadedHandler() {
        console.log('PAGE.JS : mediaLoadedHandler');
        this.mediaLoaded = true;
    }

    show() {
        this._showTl.play();
    }

    hide() {
        this._showTl.kill();
        this._hideTl.play();
    }

    // _update(delta, time) {
    //     // if (this.webGLCanvas) {
    //     //     this.webGLCanvas.setUniform('uTime', time);
    //     //     this.webGLCanvas.render();
    //     // }
    // }

    _update( top ) {
        for ( var componentName in this.Components ) {
            var component = this.Components[componentName];
            if( component.length > 0 ) {
                for( let i = 0, j = component.length; i < j; i++) {
                    let componentTab = component[i];
                    if( componentTab.hasOwnProperty('animated') && componentTab.hasOwnProperty('animated') == true )
                        componentTab._update( top );
                }
            } else {
                if( component.hasOwnProperty('animated') && component.hasOwnProperty('animated') == true )
                    component._update( top );
            }
        }
    }

    _scrollHandler(currentScroll) {
    }

    _scroll() {
        this._scrollValue.current = window.scrollY;

        // Example
        // if( this.Components.hasOwnProperty( 'Menu' ) ) {
        //     this.Components[ 'Menu' ]._scroll( this._scrollValue.current );
        // }
    }

    resize(width = States.width, height = States.height) {
        // if (this.scroll)
        //     this.scroll.resize(width, height);

        // if (this.webGLCanvas)
        //     this.webGLCanvas.resize(width, height);
    }

    _next() {
        this.emit(PageEvent.NEXT);
    }

    _destroy() {
        // if (this.scroll)
        //     this.scroll.destroy();

        // if (this.webGLCanvas)
        //     this.webGLCanvas.destroy();

        this._next();
        this.emit(PageEvent.PAGE_DESTROYED);
    }

    _getPersitance() {
        return false;
    }
}
