import Component from '~/components/component';
import SimpleSharePopin from '../../helpers/simple-share-popin';

export default class SocialNetworkShare extends Component {


    _classes = {
        button: 'js-social-network-share-button'
    };

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};
        this.dom.btnCopy = this.dom.component.querySelector('.js-copy-url');
        this.dom.copyAlert = this.dom.component.querySelector('.js-copy-alert');
        this.dom.views.buttons = this.dom.component.querySelectorAll('.' + this._classes.button);
    }

    init() {
        super.init();

        new SimpleSharePopin(this.dom.views.buttons);

        this.dom.btnCopy.addEventListener('click', () => {
            this.copyUrl()
        });
    }
    copyUrl() {
        //navigator.clipboard.writeText only work in https
        navigator.clipboard.writeText(window.location.href);
        this.dom.copyAlert.classList.add('show');
        setTimeout(() => {
            this.dom.copyAlert.classList.remove('show');
        }, 700)
    }
    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}