import Component from '~/components/component';

export const SelectEvent = {
    SELECT_CHANGE: '__se_select_change'
};

export const SelectClasses = {
    ITEM_ACTIVE: 'active',
    OPEN: 'open',
};

export default class Select extends Component {
    constructor(...args) {
        super(...args);

        bindAll( this, [
            '_handleListItemClick',
            '_handleSelectStyledClick',
            '_handleBodyClick'
        ]);

        this.placeholder = '';
        this._selectValue = null;
        this._open = false;

        this._retrieveDom();
    }

    /**
     * Set select Node element value
     * @param name - Select option name
     * @param value - Select option value
     */
    set selectValue({name, value}) {
        // get current item
        const targetListItem = [].find.call(this.dom.optionListItems, el => el.dataset.value === value);

        // get current item slug
        const currentSlug = targetListItem ? targetListItem.dataset.slug : undefined;

        // remove already active item
        [].forEach.call(this.dom.optionListItems, el => el.classList.remove(SelectClasses.ITEM_ACTIVE));

        // remove active to list
        this.dom.selectStyled.classList.remove(SelectClasses.OPEN);

        // assign value name to styled select text
        this.dom.selectStyledText.textContent = name;

        // set current list item active
        if(targetListItem) {
            targetListItem.classList.add(SelectClasses.ITEM_ACTIVE);
            // add name to select data selected
            this.dom.select.dataset.selected = name;
        }

        // assign value to select
        this.dom.select.value = value;

        // dispatch dom element change event
        const changeEvent = new CustomEvent("change", {
            detail: {
                element: this.dom.select
            }
        });
        this.dom.select.dispatchEvent(changeEvent);

        // emit component change event
        this.emit(SelectEvent.SELECT_CHANGE, {currentTarget:this.dom.select, slug: currentSlug});

        // assign value
        this._selectValue = {name, value};
    }

    /**
     * get Select Value and name
     * @return {{name: string, value: string}}
     */
    get selectValue() {
        return this._selectValue;
    }

    init() {
        this._initEvents();
        this._getPlaceholder();
    }

    /**
     * Retrieve select dom element
     * @private
     */
    _retrieveDom() {
        this.dom.select = this.dom.component.querySelector('.js-form-select');
        this.dom.selectStyled = this.dom.component.querySelector('.js-form-select-styled');
        this.dom.selectStyledText = this.dom.component.querySelector('.js-form-select-styled-text');
        this.dom.optionList = this.dom.component.querySelector('.js-form-select-options');
        this.dom.optionListItems = this.dom.optionList.querySelectorAll('.js-form-select-item');
        this.dom.optionListPlaceholder = [].find.call(this.dom.optionListItems, el => el.dataset.placeholder !== undefined);
    }

    /**
     * Init component dom elements events
     * @private
     */
    _initEvents() {
        // Listen click on each option list item
        [].forEach.call(this.dom.optionListItems, (el) => {
            el.addEventListener('click', this._handleListItemClick)
        });

        // Listen click on select styled
        this.dom.selectStyled.addEventListener('click', this._handleSelectStyledClick);

        // listen click on body
        document.body.addEventListener('click', this._handleBodyClick);
    }

    /**
     * Handle click event on option list item
     * @param {Event} e
     * @private
     */
    _handleListItemClick(e) {
        e.stopPropagation();
        this._changeSelectValue(e);
    }

    /**
     * Handle click event on select styled node
     * @param {Event} e
     * @private
     */
    _handleSelectStyledClick(e) {
        e.stopPropagation();
        this._closeOthers();
        this._toggleList();
    }

    /**
     * Handle click on body
     * @private
     */
    _handleBodyClick() {
        this.closeSelect();
    }

    /**
     * Get placeholder
     * @private
     */
    _getPlaceholder() {
        this.placeholder = this.dom.optionListPlaceholder.textContent;
    }

    /**
     * Assign select value on list item
     * @private
     */
    _changeSelectValue(e) {
        const targetListItem = e.currentTarget;

        this.selectValue = {
            name: targetListItem.textContent,
            value: targetListItem.getAttribute('data-value')
        }
    }

    /**
     * Close other open select lists on component
     * @private
     */
    _closeOthers() {
        const otherSelects = document.querySelectorAll('.form-select-styled.open');
        [].forEach.call(otherSelects, (el, i, els) => {
            if(el !== this.dom.selectStyled) {
                el.classList.remove(SelectClasses.OPEN);
            }
        });
    }

    /**
     * Toggle list open/close
     * @private
     */
    _toggleList() {
        this.dom.selectStyled.classList.toggle(SelectClasses.OPEN);
    }

    /**
     * Close select list
     */
    closeSelect() {
        this.dom.selectStyled.classList.remove(SelectClasses.OPEN);
    }

    /**
     * Reset select
     */
    reset() {
        this.selectValue = {name: this.placeholder, value: null}
    }

}
