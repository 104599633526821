import Page from '~/pages/page';
import FormValidator from '../../helpers/form-validator';
import UploadManager, { MANAGE_UPLOAD_EVENT } from "../../helpers/upload-manager.js";

export default class SpontaneousApplication extends Page {

    _classes = {
        formContainer: 'js-spontaneous-app-form-container',
        formInput: 'js-spontaneous-app-form-input',
        formSelect: 'js-spontaneous-app-form-select',
        formCheckbox: 'js-spontaneous-app-form-checkbox',
        formUpload: 'js-spontaneous-app-form-upload',
        formUploadLabel: 'js-spontaneous-app-form-upload-label',
        formUploadContainer: 'js-spontaneous-app-form-upload-container',
        formUploadSuccess: 'js-spontaneous-app-form-upload-success',
        formContainerButton: 'js-spontaneous-app-form-container-button',
        formButton: 'js-spontaneous-app-form-button',
        formResult: 'js-spontaneous-app-form-result',
        formError: 'js-spontaneous-app-form-error',

        error: 'error',
        success: 'success',
        show: 'show',
        emailInvalid: 'email-invalid',
        uploadInvalid: 'upload-invalid',
        loading: 'loading',
        uploaded: 'uploaded',
        notRequired: 'not-required',
    };

    _isProcess = false;
    _needUpload = false;

    _actionSendForm = 'mdm_spontaneous_application_send_form';

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {
        this.dom.views = {};

        this.dom.legals_button = this.dom.page.querySelector('.button-legals');
        this.dom.legals_button_close = this.dom.page.querySelector('.button-legals.close')
        this.dom.legals_text = this.dom.page.querySelector('.js-legals-text');

        this.dom.views.form = {
            container: this.dom.page.querySelector('.' + this._classes.formContainer),
            inputs: [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formInput)),
            selects: [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formSelect)),
            checkboxs: {},
            uploadContainers: [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formUploadContainer)),
            uploadSuccesses: [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formUploadSuccess)),
            uploads: [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formUpload)),
            containerButton: this.dom.page.querySelector('.' + this._classes.formContainerButton),
            button: this.dom.page.querySelector('.' + this._classes.formButton),
            result: this.dom.page.querySelector('.' + this._classes.formResult),
            error: this.dom.page.querySelector('.' + this._classes.formError),
        };

        let list_checkbox = [].slice.call(this.dom.page.querySelectorAll('.' + this._classes.formCheckbox));
        if (list_checkbox) {
            for (let i = 0, j = list_checkbox.length; i < j; i++) {
                let checkbox = list_checkbox[i];
                let name = checkbox.getAttribute('name');

                if (this.dom.views.form.checkboxs.hasOwnProperty(name)) {
                    this.dom.views.form.checkboxs[name].push(checkbox);
                } else {
                    this.dom.views.form.checkboxs[name] = [checkbox];
                }
            }
        }
    }

    init() {
        super.init();

        this._initForm();
        this._initUpload();
    }

    /**
     * Init form with FormValidator class
    */
    _initForm() {

        /** Init Form Validator **/
        this._Validator = new FormValidator({
            'inputs': this.dom.views.form.inputs,
            'parent': false
        });
    }

    /**
     * Init upload with UploadManager class
    */
    _initUpload() {

        var uploadsOpts = {
            uploads: {}
        };

        if (this.dom.views.form.uploads) {

            this._needUpload = true;

            for (let i = 0, j = this.dom.views.form.uploads.length; i < j; i++) {

                let upload = this.dom.views.form.uploads[i];
                let label = this.dom.page.querySelector('label[for="' + upload.id + '"]');

                uploadsOpts.uploads[i] = {
                    input: upload,
                    maxSize: 8,
                    type: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
                    label: label,
                    required: true,
                }
            }

            this._UploadManager = new UploadManager(uploadsOpts);
        }
    }

    bind() {
        super.bind();

        ['_checkSelectValue', '_validate', '_uploadSuccess', '_uploadError']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        this.dom.legals_button.addEventListener('click', () => {
            this.dom.legals_text.classList.toggle('open');
            this.dom.legals_button.classList.toggle('hide');
            this.dom.legals_button_close.classList.toggle('hide');
        })
        this.dom.legals_button_close.addEventListener('click', () => {
            this.dom.legals_text.classList.toggle('open');
            this.dom.legals_button.classList.toggle('hide');
            this.dom.legals_button_close.classList.toggle('hide');
        })

        // Upload event
        if (this._needUpload) {
            this._UploadManager.on(MANAGE_UPLOAD_EVENT.SUCCESS_UPLOAD, this._uploadSuccess);
            this._UploadManager.on(MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, this._uploadError);
        }

        // Select input
        if (this.dom.views.form.selects) {
            for (let i = 0, j = this.dom.views.form.selects.length; i < j; i++) {

                this.dom.views.form.selects[i].addEventListener('change', this._checkSelectValue);
            }
        }

        // Validate Form
        this.dom.views.form.button.addEventListener('click', this._validate);
    }

    /**
     * Called when the import of a file went well
     * @param data (object) : { index, firstUpload, nameFile }
    */
    _uploadSuccess(data) {

        let index = data.index;

        // Parent - uploaded
        this.dom.views.form.uploadContainers[index].classList.add(this._classes.uploaded);

        // Writing the file name
        this.dom.views.form.uploadSuccesses[index].innerHTML = data.nameFile;
    }


    /**
     * Called when importing a file did not go well
     * @param data (object) : { index }
    */
    _uploadError(data) {
        let index = data.index;

        // Parent - uploaded
        this.dom.views.form.uploadContainers[index].classList.add(this._classes.uploaded);

        // Remove the file name
        this.dom.views.form.uploadSuccesses[index].innerHTML = '';
    }

    /**
    * The user changes the value of a select
    */
    _checkSelectValue(event) {

        let target = event.currentTarget;

        if (target.value != '') {
            target.parentNode.classList.remove(this._classes.error);
            target.parentNode.classList.add(this._classes.success);
        } else {
            target.parentNode.classList.add(this._classes.error);
            target.parentNode.classList.remove(this._classes.success);
        }
    }

    /**
     * Check if all the fields of type "select" are filled
     * @returns boolean
    */
    _selectIsValid() {

        let isValid = true;

        for (let i = 0, j = this.dom.views.form.selects.length; i < j; i++) {

            let select = this.dom.views.form.selects[i];

            if (select.value == '') {
                select.parentNode.classList.add(this._classes.error);
                isValid = false;
            } else {
                select.parentNode.classList.remove(this._classes.error);
            }
        }

        return isValid;
    }

    /**
     * Retrieving the values ​​of all "select" type fields
     * @returns data : array of select input values
    */
    _selectGetData() {

        let data = {};

        for (let i = 0, j = this.dom.views.form.selects.length; i < j; i++) {
            let select = this.dom.views.form.selects[i];
            data[select.name] = select.value;
        }

        return data;
    }

    /**
     * Check if the user has responded to "choice" type inputs
     * @return boolean
    */
    _checkboxIsValid() {

        let errors = [];

        for (let checkbox in this.dom.views.form.checkboxs) {

            if (!this.dom.views.form.checkboxs.hasOwnProperty(checkbox)) continue;

            let is_checked = false;
            let parent = this.dom.views.form.checkboxs[checkbox][0].parentNode.parentNode;

            // 1 - Loop through each checkox input to see if one is checked
            for (let i = 0, j = this.dom.views.form.checkboxs[checkbox].length; i < j; i++) {
                let element = this.dom.views.form.checkboxs[checkbox][i];

                if (element.checked) {
                    is_checked = true;
                }
            }

            // 2 - Is this field required ?
            if (is_checked == false && parent && parent.classList.contains(this._classes.notRequired)) {
                is_checked = true;
            }

            // 3- If this field is valid
            if (is_checked == true) {
                if (parent) {
                    parent.classList.remove(this._classes.error);
                    parent.classList.add(this._classes.success);
                }
            }

            // 4- If this field is not valid
            if (is_checked == false) {
                if (parent) {
                    parent.classList.remove(this._classes.success);
                    parent.classList.add(this._classes.error);
                }
                errors.push('input ' + checkbox + ' not valid');
            }

        }

        if (errors.length > 0) {
            console.log(errors);
            return false;
        }

        return true;
    }

    /**
     * Retrieving the values ​​of the "checkbox" inputs
     * @return object (the different values ​​of the "checkbox" inputs linked with their name)
    */
    _checkboxGetData() {

        let valuesInput = {};

        for (let checkbox in this.dom.views.form.checkboxs) {

            if (!this.dom.views.form.checkboxs.hasOwnProperty(checkbox)) continue;

            for (let i = 0, j = this.dom.views.form.checkboxs[checkbox].length; i < j; i++) {

                let element = this.dom.views.form.checkboxs[checkbox][i];

                if (element.checked) {
                    if (valuesInput.hasOwnProperty(checkbox)) {
                        valuesInput[checkbox].push(element.value);
                    } else {
                        valuesInput[checkbox] = [element.value];
                    }
                }
            }
        }

        return valuesInput;
    }

    /**
    * The user clicks on the "send" button of the form
    * @param event
    */
    _validate(event) {

        let errors = [];
        let emailValid = true;
        let uploadValid = true;

        // Hide message error
        if (this.dom.views.form.error) {
            this.dom.views.form.error.classList.remove(this._classes.show);
            this.dom.views.form.error.classList.remove(this._classes.emailInvalid);
            this.dom.views.form.error.classList.remove(this._classes.uploadInvalid);
        }

        // Check process
        if (this._isProcess == false) {

            this._isProcess = true;

            document.body.classList.add(this._classes.loading);
            this.dom.views.form.button.classList.add(this._classes.loading);

            let data = new FormData();

            // Check Form Selects
            if (this.dom.views.form.selects) {

                if (this._selectIsValid()) {

                    // Get Select Fields data
                    let selects = this._selectGetData();
                    for (let item in selects) {
                        console.log(item, ' ::: ', selects[item]);
                        data.append(item, selects[item]);
                    }

                } else {
                    errors.push('Select not valid');
                }
            }

            // Check Form Checkboxs
            if (this.dom.views.form.checkboxs) {

                if (this._checkboxIsValid()) {

                    // Get Checkbox Fields data
                    let checkboxs = this._checkboxGetData();
                    for (let item in checkboxs) {
                        console.log(item, ' ::: ', checkboxs[item]);
                        data.append(item, checkboxs[item]);
                    }

                } else {
                    errors.push('Checkbox not valid');
                }
            }

            // Check Uploads
            if (this._needUpload == true) {
                if (this._UploadManager.getFiles()) {

                    let list_upload = this._UploadManager.getFilesValues();
                    for (var upload in list_upload) {
                        data.append(upload, list_upload[upload]);
                    }
                } else {
                    uploadValid = false;
                    errors.push('Form upload not valid');
                }
            }

            // Check Form inputs
            if (this._Validator.isValid()) {

                // Get Form Fields data
                let inputs = this._Validator.getData();

                for (let item in inputs) {
                    console.log(item, ' ::: ', inputs[item]);
                    data.append(item, inputs[item]);
                }

            } else {
                errors.push('Form not valid');
            }

            // Check Form inputs type "email"
            if (this._Validator.isValidEmail() == false) {
                emailValid = false;
                errors.push('Form email not valid');
            }

            // Check errors
            if (errors.length > 0) {

                // Show message error
                if (this.dom.views.form.error) {

                    if (emailValid == false) {
                        this.dom.views.form.error.classList.add(this._classes.emailInvalid);
                    }

                    if (uploadValid == false) {
                        this.dom.views.form.error.classList.add(this._classes.uploadInvalid);
                    }

                    this.dom.views.form.error.classList.add(this._classes.show);
                }

                this._isProcess = false;
                document.body.classList.remove(this._classes.loading);
                this.dom.views.form.button.classList.remove(this._classes.loading);

                console.log(errors);
                return;
            }

            // Request
            this._request(data);
        }
    }

    /**
    * Request "Form Contact Send"
    * @param data (FormData)
    */
    _request(data) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/wp/wp-admin/admin-ajax.php?action=' + this._actionSendForm, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this._response((JSON.parse(xhr.response)));
            }
            this._isProcess = false;
            document.body.classList.remove(this._classes.loading);
            this.dom.views.form.button.classList.remove(this._classes.loading);

        }.bind(this);
        xhr.send(data);
    }

    /**
    * Response to the request to send the form
    * @param response Ajax
    */
    _response(response) {

        if (response.success) {
            // Bloc "Message bien envoyé"
            this.dom.views.form.containerButton.classList.add(this._classes.success);
        } else {
            // Bloc "Erreur dans l'envoi du message"
            this.dom.views.form.result.classList.add(this._classes.error);
        }

        this.dom.views.form.result.innerHTML = response.data.message;
        this.dom.views.form.result.classList.add(this._classes.show);
    }

    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
