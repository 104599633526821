import Component from '~/components/component';

export default class Header5 extends Component {
    constructor(...args) {
        super(...args);

		this._setHeight();
    }

	_setHeight()
    {
        const height = window.innerHeight;

        this.dom.component.style.height = height + 'px';
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
