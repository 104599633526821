import Swiper, { Mousewheel } from 'swiper';
import Component from '~/components/component';

export default class Socials extends Component {
    constructor(...args) {
        super(...args);
        this._retriveDOM();
        this.initSwiper();
    }

    _retriveDOM() {
        this.dom.instaFeed = this.dom.component.querySelector('.instagram-feed');
        if (this.dom.instaFeed) {
            this.dom.container = this.dom.instaFeed.querySelector('.catch-instagram-feed-gallery-widget-image-wrapper');
            if (this.dom.container) {
                this.dom.wrapper = this.dom.container.querySelector('ul');
                this.dom.wrapper.classList.add('swiper-wrapper')
            }
        }
    }

    initSwiper() {
        const swiper = new Swiper(this.dom.container, {
            modules: [Mousewheel],
            slideClass: 'item',
            direction: 'horizontal',
            grabCursor: 'true',
            loop: true,
            slidesPerView: "auto",
            centeredSlides: true,


            mousewheel: {
                forceToAxis: true,
            },

            autoplay: {
                delay: 4000
            }
        });
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
