import Component from '~/components/component';
import { MANAGE_LOAD_PAGE_EVENT } from '../../pages/date/date-list';

export default class PushNextDate extends Component {

    _classes = {
        title: 'js-push-next-date-title',
        updateDate: 'update-date'
    };

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.title = this.dom.component.querySelector( '.' + this._classes.title );
    }

    init() {
        super.init();

        this._url = ( this.dom.views.title && this.dom.views.title.dataset.url ) ? this.dom.views.title.dataset.url : false;
    }

    bind() {

        ['_hoverDate', '_clickDate']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        if ( this.dom.views.title ) {
            this.dom.views.title.addEventListener('mouseenter', this._hoverDate);
            this.dom.views.title.addEventListener('click', this._clickDate);
        }
    }

    unbind() {

        if ( this.dom.views.title ) {
            this.dom.views.title.removeEventListener('mouseenter', this._hoverDate);
            this.dom.views.title.removeEventListener('click', this._clickDate);
        }
    }

    _hoverDate( event ) {

        if( this.dom.views.title.classList.contains( this._classes.updateDate ) ) {
            this.emit( MANAGE_LOAD_PAGE_EVENT.LOAD_URL, { url: this._url } );
        }
    }

    _clickDate( event ) {

        if( this.dom.views.title.classList.contains( this._classes.updateDate ) ) {
            this.emit( MANAGE_LOAD_PAGE_EVENT.CLICK_URL, { url: this._url, targetDOM: this.dom.component } );
        }
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
