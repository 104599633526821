import EventEmitter from 'eventemitter3';
import { CONFIG } from '../config-project';

export const RouterEvent = {
	ROUTE_CHANGE: '__re_router_change'
};

/**
* Manage routes
*/
class Router extends EventEmitter {
	constructor() {
		super();
		
		this._locked = false;
		// this._currentLang = LANG_CURRENT;
		this.history = [];
		let current = window.location.href
		// if (current.endsWith('/')) current = current.slice(0, -1)
		this.urls = {
			origin: window.location.origin || window.location.protocol + '//' + window.location.hostname,
			current
		};
		
		this.title = document?.head?.querySelector('title')?.innerText || '';
		
		bindAll(this, [
			'listen',
			'_linkClickedHandler'
		]);
		
		if (CONFIG.IS_AJAX)
			this._bind();
		
		window.routerDebug = this;
	}
	
	_bind() {
		window.addEventListener('popstate', this.listen);
		delegate(document.body, '[href]', 'click', this._linkClickedHandler, false);
	}
	
	listen() {
		let current = window.location.href;
		// if (current.endsWith('/')) current = current.slice(0, -1)
		
		if (!this._locked && this.urls.current != current) {
			this.redirect(current);
		}
	}
	
	_linkClickedHandler(event) {
		const linkUrl = event.delegateTarget.getAttribute('href');
		
		if (linkUrl.includes(this.urls.origin) && !event.delegateTarget.classList.contains('no-ajax')) {
			console.log('Match Change URL');
			event.preventDefault();
			
			if (!this._locked) {
				this.redirect(linkUrl);
			}
		}
	}

	updateSwitchLangUrl() {
		let _urlSwitchLang = document.body.querySelectorAll('.js-ajax-lang');
		
		_urlSwitchLang.forEach(el => {
			el.setAttribute('href', CURRENT_PAGE_LANGUAGE_TRANSLATION[el.getAttribute('data-lang')]);
		});
	}
	
	execBodyScripts(body_el) {
		function nodeName(elem, name) {
			return elem.nodeName && elem.nodeName.toUpperCase() ===
			name.toUpperCase();
		};
		
		function evalScript(elem) {
			var data = (elem.text || elem.textContent || elem.innerHTML || "" ),
			head = document.getElementsByTagName("head")[0] ||
			document.documentElement,
			script = document.createElement("script");
			
			script.type = "text/javascript";
			try {
				// doesn't work on ie...
				script.appendChild(document.createTextNode(data));
			} catch(e) {
				// IE has funky script nodes
				script.text = data;
			}
			
			head.insertBefore(script, head.firstChild);
			head.removeChild(script);
		};
		
		// main section of function
		var scripts = [],
		script,
		children_nodes = body_el.childNodes,
		child,
		i;
		
		for (i = 0; children_nodes[i]; i++) {
			child = children_nodes[i];
			if (nodeName(child, "script" ) &&
			(!child.type || child.type.toLowerCase() === "text/javascript")) {
				scripts.push(child);
			}
		}
		
		for (i = 0; scripts[i]; i++) {
			script = scripts[i];
			if (script.parentNode) {script.parentNode.removeChild(script);}
			evalScript(scripts[i]);
		}
	}
	
	AJAXresponseHandler() {
		let current = window.location.href
		// if (current.endsWith('/')) current = current.slice(0, -1)
		
		if (this.urls.current != current) {
			history.pushState({}, this.title, this.urls.current);
			if (window.gtag) {
				console.log('GTAG PageViews : ', this.title, window.location.pathname)
				gtag('config', 'UA-29278934-6', {
					'page_title' : this.title,
					'page_path': window.location.pathname,
					'anonymize_ip': true,
					'cookie_expires': 33696000
				});
			}
			if (window.twq) {
				twq('track','PageView');
			}
			if (window.fbq) {
				fbq('track', 'PageView');
			}
			if (window._linkedin_data_partner_ids) {
				_linkedin_partner_id = "77145";
				window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
				window._linkedin_data_partner_ids.push(_linkedin_partner_id);
			}

		}
	}
	
	lock() {
		this._locked = true;
	}
	
	unlock() {
		this._locked = false;
	}
	
	redirect(url) {
		if (url.indexOf('trash') > 0) return;
		if (CONFIG.IS_AJAX) {
			let next = this._parseUrl(url);
			if (next !== this.urls.current) {
				this.history.push(url);
				this.last = this.urls.current;
				this.urls.current = this._parseUrl(url);
				this.emit(RouterEvent.ROUTE_CHANGE, this.urls, this.getUrl());
			}
		} else {
			document.location.href = url;
		}
	}
	
	setTitle(title) {
		document.title = this.title = title || document.title;
	}
	
	_parseUrl(url) {
		if (!url.includes(this.urls.origin)) url = this.urls.origin + url;
		// if (!url.endsWith('/')) url += '/';
		// if (url.endsWith('/')) url = url.slice(0, -1)
		
		return url;
	}
	
	getUrl() {
		let url = this.urls.current.replace(this.urls.origin, '');
		// if (url.endsWith('/')) url = url.slice(0, -1)
		// if (!url.endsWith('/')) url += '/';
		return url;
	}
	
	get route() {
		let route = this.getUrl();
		route = route.replace('en', '');
		route = route.replace('fr', '');
		
		if (route.startsWith('/')) route = route.substring(1)
		// if (route.endsWith('/')) route = route.slice(0, -1)
		return route;
	}
}

export default new Router();
