import Component from '~/components/component';
import Dropdown from '../../helpers/dropdown';

export default class ExpandItem extends Component {
    constructor(...args) {
        super(...args);

    }

    init() {
        const self = this.dom.component;
        const index = self.dataset.index;
        if (index && index == 0) {
            const dropdown = new Dropdown({ dom: self, initOpen: true });
        } else {
            const dropdown = new Dropdown({ dom: self });
        }
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
