import Component from '~/components/component';

export default class Introduction extends Component {

    constructor(...args) {
        super(...args);
        this.mouse = { x: 0, y: 0 };
        this.x = 0;
        this.y = 0;
        this._retrieveDOM();
    }

    _retrieveDOM() {
        this.dom.images = this.dom.component.querySelectorAll('.js-intro-image');
        this.dom.interactives = this.dom.component.querySelectorAll('.js-intro-text');
        this.pause = Array.from(Array(this.dom.images.length), () => true);
    }

    computeImage(e) {
        this.mouse.x = e.offsetX;
        this.mouse.y = e.offsetY;
    }

    loop(i) {
        if (this.mouse.x !== Math.round(this.x * 10) / 10) {
            this.x += (this.mouse.x - this.x) * 0.1;
            this.y += (this.mouse.y - this.y) * 0.1;
        } else {
            this.x = this.mouse.x;
            this.y = this.mouse.y;
        }

        this.dom.images[i].style.transform = `translate(calc(-50% + ${this.x + "px"}), calc(-50% + ${this.y + "px"}))`;
        if (!this.pause[i]) {
            requestAnimationFrame(this.loop.bind(this, i));
        }
    }

    startLoop(i) {
        this.pause[i] = false;
        this.dom.images[i].classList.add('show');
        this.loop(i);
    }

    stopLoop(i) {
        this.pause[i] = true;
        this.dom.images[i].classList.remove('show');
    }

    bind() {
        window.addEventListener("load", () => {
            setTimeout(() => {
                this.dom.interactives.forEach((interactive, i) => {
                    if (!interactive.dataset.hide) {
                        interactive.addEventListener('mouseenter', this.startLoop.bind(this, i));
                        interactive.addEventListener('mouseleave', this.stopLoop.bind(this, i));
                        interactive.addEventListener('mousemove', this.computeImage.bind(this));
                    }
                });
            }, 1900);
        });
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
