
import Component from '~/components/component';
import Dropdown from '../../helpers/dropdown';

export default class Filters extends Component {

    _classes = {
        item: 'js-filters-item-list',
        list: 'js-filters-container-list',
        open: 'open'
    };

    _isFilterOpen = [];
    _DropdownManager = null;

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.items = [].slice.call(this.dom.component.querySelectorAll('.' + this._classes.item));
        this.dom.views.lists = [].slice.call(this.dom.component.querySelectorAll('.' + this._classes.list));

        if (this.dom.views.items) {
            for (let i = 0, j = this.dom.views.items.length; i < j; i++) {
                this._isFilterOpen[i] = false;
            }
        }
    }

    bind() {

        ['_clickFilter']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        if (window.innerWidth < 1024) {
            this._DropdownManager = new Dropdown({ initOpen: window.location.search !== "" });
        }

        if (this.dom.views.items) {
            for (let i = 0, j = this.dom.views.items.length; i < j; i++) {
                this.dom.views.items[i].addEventListener('click', this._clickFilter);
            }
        }
    }

    _clickFilter(event) {
        let target = event.currentTarget;
        let indexTarget = this.dom.views.items.indexOf(target);

        if (this._isFilterOpen[indexTarget]) {
            this._closeFilter(indexTarget);
        } else {
            this._openFilter(indexTarget);
        }
    }

    _closeFilter(index) {

        this.dom.views.items[index].classList.remove(this._classes.open);
        this._isFilterOpen[index] = false;
    }

    _openFilter(index) {

        if (this.dom.views.items) {
            for (let i = 0, j = this.dom.views.items.length; i < j; i++) {
                this.dom.views.items[i].classList.remove(this._classes.open);
            }

        }
        this.dom.views.items[index].classList.add(this._classes.open);
        this._isFilterOpen[index] = true;
    }

    resize(width, height) {
        super.resize(width, height);

        if (window.innerWidth < 1024 && this._DropdownManager == null ) {
            this._DropdownManager = new Dropdown({ initOpen: window.location.search !== "" });
        }
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
