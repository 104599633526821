import { gsap } from 'gsap';
import Component from '~/components/component';

export default class CustomCursor extends Component {
    constructor(...args) {
        super(...args);

        this._retrieveDOM();

        this.x = {prev: 0, next: 0};
        this.y = {prev: 0, next: 0};
        this.rafId = undefined;
        this.smoothFactor = 0.2;
    }

    _retrieveDOM()
    {
        this.dom.elsHover = document.body.querySelectorAll('.hover-custom-cursor');
        this.dom.cursor = this.dom.component;
    }
    bind()
    {
        if (window.matchMedia("(pointer: fine)").matches) {
            for (let i = 0; i < this.dom.elsHover.length; i++) {
                this.dom.elsHover[i].addEventListener("mouseover",(e)=> {
                    this.setPositionCursor(e)
                });
                this.dom.elsHover[i].addEventListener("mouseleave", this.unsetPositionCursor.bind(this));
            }
        }
    }
    setPositionCursor = (e) =>
    {
        gsap.set(this.dom.cursor, {
            x: e.clientX,
            y: e.clientY,
        });
        gsap.to(this.dom.cursor, {
            scale: 1,
            duration: 0.2
        });
        document.addEventListener("pointermove", this.handleMouseMove);

    }
    unsetPositionCursor()
    {
        document.removeEventListener("pointermove", this.handleMouseMove);

        gsap.to(this.dom.cursor, {
            scale: 0,
            duration: 0.2
        });
    }
    handleMouseMove = (e) =>
    {
        this.x.next = e.clientX;
        this.y.next = e.clientY;


        if (this.rafId === undefined) {
            this.rafId = requestAnimationFrame(this.raf);
        }
    }
    raf = () =>
    {
        this.x.prev = lerp(this.x.prev, this.x.next, this.smoothFactor);
        this.y.prev = lerp(this.y.prev, this.y.next, this.smoothFactor);

        gsap.set(this.dom.cursor, {
            x: this.x.prev,
            y: this.y.prev,
        });

        if (Math.abs(this.y.prev - this.y.next) < 0.1) {
            this.stopLoop();
        } else {
            this.runLoop(this.dom.cursor);
        }
    }
    runLoop()
    {
        this.rafId = requestAnimationFrame(this.raf);
    }
    stopLoop()
    {
        cancelAnimationFrame(this.rafId);
        this.rafId = undefined;
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
