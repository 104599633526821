import Swiper, { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';
import Component from '~/components/component';

export default class LogosSlider extends Component {
    constructor(...args) {
        super(...args);
        this.retrieveDOM();
        this.initSwiper();
    }

    retrieveDOM() {
        this.dom.prevButton = this.dom.component.parentNode.querySelector('.button.prev');
        this.dom.nextButton = this.dom.component.parentNode.querySelector('.button.next');
    }

    initSwiper() {
        const swiper = new Swiper(this.dom.component, {
            modules: [Autoplay, Pagination, Navigation, Mousewheel],
            direction: 'horizontal',
            grabCursor: 'true',
            slidesPerView: 1.5,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            mousewheel: {
                forceToAxis: true,
            },

            navigation: {
                prevEl: this.dom.prevButton,
                nextEl: this.dom.nextButton,
            },
            
            pagination: {
                el: this.dom.component.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },

            breakpoints: {
                768: {
                    slidesPerView: 4.1,
                    spaceBetween: 30
                }
            },

        });
    }
}
