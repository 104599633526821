import Component from '~/components/component';

export default class ReadingBanner extends Component {

  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.remove = this.dom.component.querySelector('.js-remove');
    this.dom.listen = this.dom.component.querySelector('.js-listen');
    this.dom.progressLine = this.dom.component.querySelector('.js-progress-line');
    this.dom.page = document.body.querySelector('.page');
    this.dom.title = document.body.querySelector('.header-1 .title');
    this.isSpeaking = false;
  }

  bind() {
    this.dom.remove.addEventListener('click', () => {
      this.dom.component.classList.add('hidden');
      this.pauseSpeech();
    })
    this.dom.listen.addEventListener('click', () => {

      let label = this.dom.title.textContent;

      if (!this.isSpeaking) {
        const content = this.dom.page.textContent;
        const msg = new SpeechSynthesisUtterance();
        msg.text = content;
        window.speechSynthesis.speak(msg);

        // Send google event
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({ 'event': 'ecouter', 'action-ecoute': 'activation', 'article-ecoute': label.replace("'","’") });              
        }
      } else {
        this.pauseSpeech();

        // Send google event
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({ 'event': 'ecouter', 'action-ecoute': 'désactivation', 'article-ecoute': label.replace("'","’") });              
        }
      }
      this.dom.listen.classList.toggle('is-listen');

      this.isSpeaking = !this.isSpeaking;
    })

    window.addEventListener('beforeunload', () => {
      this.pauseSpeech();
    });

    window.addEventListener('scroll', () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      this.dom.progressLine.style.width = scrolled + "%";
    })

  }
  pauseSpeech() {
    window.speechSynthesis.cancel();
  }
  /*
  init() {
  }
  bind() {
  }
  unbind() {
  }
  resize(width, height) {
  }
  */
}
