import Flickity from "flickity";
import { gsap } from 'gsap';
import Component from '~/components/component';
import States from "../../helpers/states";

export default class Slider2 extends Component {

    _classes = {
        sliderContainer: 'js-slider-2-slider-container',
        sliderSlide: 'js-slider-2-slider-slide',
        slideLabel: 'js-slider-2-slide-label',
        slideContent: 'js-slider-2-slide-content',
        open: 'open'
    };

    _switchDesktop = true;

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.slider = {
            container: this.dom.component.querySelector('.' + this._classes.sliderContainer),
            slides: null,
            labels: null,
            contents: null
        }

        if (this.dom.views.slider.container) {
            this.dom.views.slider.slides = [].slice.call(this.dom.views.slider.container.querySelectorAll('.' + this._classes.sliderSlide));
            this.dom.views.slider.labels = [].slice.call(this.dom.views.slider.container.querySelectorAll('.' + this._classes.slideLabel));
            this.dom.views.slider.contents = [].slice.call(this.dom.views.slider.container.querySelectorAll('.' + this._classes.slideContent));
        }

    }

    init() {
        super.init();

        if (this.dom.views.slider.container) {
            this._closeAllSlider();
            this._initSlider();
        }
    }


    /**
     * Init Flickity Slider
     */
    _initSlider() {

        if (this.dom.views.slider.slides.length > 1) {
            this._sliderFlickity = new Flickity(this.dom.views.slider.container, {
                wrapAround: false,
                cellAlign: 'center',
                prevNextButtons: true,
                pageDots: false,
                adaptiveHeight: true,
                groupCells: false,
                watchCSS: true,
                arrowShape: 'M39.7,49.7H62 M39,49.7l5.2,5.3 M39,49.7l5.2-5.2'
            });
        }
    }

    bind() {

        ['_clickSlideLabel']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        if (this.dom.views.slider.labels) {
            for (let i = 0, j = this.dom.views.slider.labels.length; i < j; i++) {
                this.dom.views.slider.labels[i].addEventListener('click', this._clickSlideLabel);
            }
        }
    }

    _closeAllSlider(width) {

        if (width <= 768) {

            if (this._switchDesktop == true) {
                this._switchDesktop = false;

                if (this.dom.views.slider.slides) {
                    for (let i = 0, j = this.dom.views.slider.slides.length; i < j; i++) {
                        let initHeight = this.dom.views.slider.labels[i].getBoundingClientRect().height;
                        this.dom.views.slider.slides[i].style.height = initHeight + 'px';
                        this.dom.views.slider.slides[i].classList.remove(this._classes.open);
                    }
                }
            }

        } else {

            if (this._switchDesktop == false) {
                this._switchDesktop = true;

                if (this.dom.views.slider.slides) {
                    for (let i = 0, j = this.dom.views.slider.slides.length; i < j; i++) {
                        this.dom.views.slider.slides[i].style.height = 'auto';
                        this.dom.views.slider.slides[i].classList.remove(this._classes.open);
                    }
                }
            }
        }
    }

    _clickSlideLabel(event) {
        let target = event.currentTarget;
        let index = this.dom.views.slider.labels.indexOf(target);
        let slide = this.dom.views.slider.slides[index];
        let content = this.dom.views.slider.contents[index];
        let heightContent = target.getBoundingClientRect().height;

        if (slide) {

            if (slide.classList.contains(this._classes.open)) {
                slide.classList.remove(this._classes.open);

            } else {
                heightContent = target.getBoundingClientRect().height + content.getBoundingClientRect().height;
                slide.classList.add(this._classes.open);
            }

            gsap
                .to(slide, { height: heightContent, duration: 0.8, ease: "power1.inOut" })
        }
    }

    resize(width = States.width, height = States.height) {
        super.resize(width, height);
        this._closeAllSlider(width);
    }
}
