import Component from '~/components/component';

export default class Footer extends Component {
    constructor(...args) {
        super(...args);

        this.animationX = -1;
        this.animationY = -1;

        this._init();
    }

    _init() {
        this._retrieveDOM();
        this._bind();
    }

    _retrieveDOM() {
        this.dom.contentLinks = this.dom.component.querySelectorAll('.ct--footer__content-text a');
        this.dom.animationSection = this.dom.component.querySelector('.ct--footer__top');
        this.dom.birdElement = this.dom.component.querySelector('.bird-container');
    }

    handleMouseMove(e) {
        const posX = e.x;
        const posY = e.pageY - this.dom.animationSection.offsetTop;
        const dist = Math.sqrt(Math.pow(posX - this.animationX, 2) + Math.pow(posY - this.animationY, 2));
        if ((this.animationX === -1 && this.animationY === -1) || dist > window.innerWidth / 10) {
            this.animationX = posX;
            this.animationY = posY;
            const bird = this.dom.birdElement.cloneNode(true);
            bird.style.left = this.animationX + "px";
            bird.style.top = this.animationY + "px";
            if (Math.random() > 0.5) {
                bird.style.transform = `rotate(${(Math.random() - 0.5) * 30}deg) translate(-50%, -50%)`;
            } else {
                bird.style.transform = `rotate(${(Math.random() - 0.5) * 30}deg) translate(-50%, -50%) scaleX(-1)`;
            }
            const colorRandom = Math.random();
            if (colorRandom > 0.66) {
                bird.style.fill = "white";
            } else if (colorRandom > 0.33) {
                bird.style.fill = "#0f143d";
            } else {
                bird.style.fill = "#bde0ec";
            }
            bird.classList.remove('base');
            this.dom.animationSection.appendChild(bird);
            setTimeout(() => {
                bird.remove();
            }, 1000)
        }
    }

    handleMouseEnter() {
        this.dom.animationSection.addEventListener('mousemove', this.handleMouseMove.bind(this));
    }

    handleMouseLeave() {
        this.dom.animationSection.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    }

    _bind() {
        //Events click on dots
        for (let i = 0; i < this.dom.contentLinks.length; i++) {
            let link = this.dom.contentLinks[i];
            link.addEventListener('click', () => {
                // Send google event
                if (typeof dataLayer !== 'undefined') {
                    let label = link.innerText;
                    dataLayer.push({ 'event': 'clicFooter', 'footerElement': label.replace("'", "’") });
                }
            })
        }

        if (this.dom.animationSection) {
            this.dom.animationSection.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
            this.dom.animationSection.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
        }
    }

    /*
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
