import GlobeLib from "globe.gl";
import 'leaflet';
import 'leaflet.markercluster';
import Component from '~/components/component';

const L = window['L'];

const typeface = require('./Anton_Regular.json');

const GLOBE_TEXTURE = '/app/themes/mdm/library/medias/images/textures/globe.jpg';
const MAP_TEXTURE = '/app/themes/mdm/library/medias/images/textures/map.png';
const ROTATE_SPEED = -0.6;
const SMALL_POINT_SIZE = 2;
const BIG_POINT_SIZE = 5;
const ACTION_POINT_SIZE = 1.2;

const colorData = {
    blueLight: "#bde0ec",
    blueDark: "#0f143d",
    blueMedium: "#659fc4",
    orange: "#e9511c",
    green: "#0fb09d",
}

export default class Globe extends Component {
    constructor(...args) {
        super(...args);

        this._retrieveDOM();
        this.isAction = this.dom.component.dataset.type === "action";
        this.isPopinOpen = false;
        this.data = [];
        this.getData();

        this.mouseWatcher = window.matchMedia("(pointer: fine)");
        this.isWithMouse = this.mouseWatcher.matches;
        if (this.isWithMouse) {
            this.initGlobe();
        } else {
            this.initMap();
        }

        this._eventListener();
    }

    _retrieveDOM() {
        this.dom.globe = this.dom.component.querySelector('.globe-container');
        this.dom.infos = this.dom.component.querySelector('.infos');
        this.dom.closeButton = this.dom.component.querySelector('.close-button');
        this.dom.contentCountries = this.dom.component.querySelectorAll('.js-content-country');
    }

    handleCursorChange() {
        this.isWithMouse = this.mouseWatcher.matches;
        if (this.isWithMouse) {
            this.initGlobe();
        } else {
            this.initMap();
        }
    }

    _eventListener() {
        this.dom.closeButton.addEventListener('click', this.closePopin.bind(this));
        this.mouseWatcher.addEventListener('change', this.handleCursorChange.bind(this));
    }

    resize() {
        if (this.isWithMouse) {
            this.initGlobe();
            this.world.width(this.dom.globe.clientWidth);
            this.world.height(this.dom.globe.clientWidth);
        }
        this.closePopin(true);
    }

    closePopin(forced) {
        if ((window.innerWidth <= 768 || forced) && this.isPopinOpen) {
            this.emit('close-popin')
        }
        this.isPopinOpen = false;
        this.dom.infos.classList.remove('open');
        if (this.isWithMouse) {
            this.startRotation();
        }
    }

    openPopin(index) {
        this.isPopinOpen = true;
        this.dom.infos.dataset.index = index;
        this.dom.infos.classList.add('open');

        if (this.isWithMouse) {
            this.stopRotation();
        }

        if (window.innerWidth <= 768) {
            this.emit('open-popin')
        }
    }

    stopRotation() {
        this.world.controls().autoRotate = false;
    }

    startRotation() {
        if (!this.isPopinOpen) {
            this.world.controls().autoRotate = true;
        }
    }

    handlePointClick(point) {
        this.openPopin(point.index);
        this.world.pointOfView({ lat: point.lat, lng: point.lng }, 1000);
    }

    globeSetup() {
        this.world.scene().children[2].intensity = 0;
        this.world.scene().children[1].color = { r: 1, g: 1, b: 1 };

        this.world.camera().setFocalLength(this.isAction ? 58 : 58);
    }

    getData() {
        for (let i = 0; i < this.dom.contentCountries.length; i++) {
            let data = [];
            data['name'] = this.dom.contentCountries[i].dataset.title;
            data['lat'] = this.dom.contentCountries[i].dataset.lat;
            data['lng'] = this.dom.contentCountries[i].dataset.long;
            data['color'] = this.isAction ?
                colorData.green :
                this.getColor(this.dom.contentCountries[i].dataset.color);
            data['size'] = this.isAction ? ACTION_POINT_SIZE : SMALL_POINT_SIZE;
            data['index'] = i + 1;
            this.data.push(data);
        }
    }

    getColor(colorName) {
        const color = this.toCamelCase(colorName);
        return colorData[color];
    }

    toCamelCase(text) {
        text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
        return text.substr(0, 1).toLowerCase() + text.substr(1);
    }

    handlePointHover(point, prevPoint) {
        if (this.activeGlobePoint) {
            this.activeGlobePoint.__threeObj.scale.set(2, 2, 0.1);
        }
        this.activeGlobePoint = point;
        if (point) {
            point.__threeObj.scale.set(3, 3, 0.1);
        } else {
            this.activeGlobePoint = null;
            prevPoint.__threeObj.scale.set(2, 2, 0.1);
        }
    }

    initGlobe() {
        if (this.isAction) {
            this.world = GlobeLib()
                .globeImageUrl(GLOBE_TEXTURE)
                .pointsData(this.data)
                .pointAltitude(0)
                .pointRadius('size')
                .pointColor('color')
                .pointResolution(32)
                .onPointClick(this.handlePointClick.bind(this))
                .onLabelClick(this.handlePointClick.bind(this))
                .onPointHover(this.handlePointHover.bind(this))
                .backgroundColor('rgba(0,0,0,0)')
                .showAtmosphere(false)
                .onGlobeReady(this.globeSetup.bind(this))
                .width(this.dom.globe.clientWidth)
                .height(this.dom.globe.clientWidth)
                (this.dom.globe)
        } else {
            this.world = GlobeLib()
                .globeImageUrl(GLOBE_TEXTURE)
                .pointsData(this.data)
                .pointLabel("")
                .pointAltitude(0)
                .pointRadius('size')
                .pointColor('color')
                .pointResolution(32)
                .labelsData(this.data)
                .labelText("name")
                .labelSize(2)
                .labelDotRadius(current => current.size - 0.5)
                .labelColor("color")
                .labelResolution(32)
                .labelDotOrientation("top")
                .labelTypeFace(typeface)
                .onPointClick(this.handlePointClick.bind(this))
                .onLabelClick(this.handlePointClick.bind(this))
                .backgroundColor('rgba(0,0,0,0)')
                .showAtmosphere(false)
                .onGlobeReady(this.globeSetup.bind(this))
                .width(this.dom.globe.clientWidth)
                .height(this.dom.globe.clientWidth)
                (this.dom.globe)
        }

        this.world.controls().enableZoom = false;
        this.world.pointOfView({ lat: 47.029413, lng: 2.574153 }, 0);
        this.world.controls().autoRotateSpeed = ROTATE_SPEED;

        // bugs if no timeout
        setTimeout(() => {
            this.world.controls().autoRotate = false;
        }, 50);

        this.dom.globe.addEventListener('mouseenter', this.stopRotation.bind(this));
        this.dom.globe.addEventListener('mouseleave', this.startRotation.bind(this));
    }
    initMap() {
        this.map = L.map('map-container', {
            center: [0, 0],
            zoom: 1,
            minZoom: 1,
            maxZoom: this.isAction ? 7 : 3,
            maxBounds: [[90, 180], [-90, -180]],
            maxBoundsViscosity: 1,
            attributionControl: false
        });

        L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
        }).addTo(this.map);

        const markerSize = SMALL_POINT_SIZE * 15;

        if (this.isAction) {
            const markersCluster = new L.MarkerClusterGroup();
            this.data.forEach((marker, i) => {
                const myIcon = L.divIcon({
                    className: 'my-div-icon',
                    html: `<div style="--color:${colorData.green};--size:${markerSize}px"></div>`
                });
                const pin = L.marker([marker.lat, marker.lng], { icon: myIcon }).on('click', () => this.openPopin(i + 1));
                markersCluster.addLayer(pin);
            });
            this.map.addLayer(markersCluster);
        } else {
            this.data.forEach((marker, i) => {
                const myIcon = L.divIcon({
                    className: 'my-div-icon',
                    html: `<div style="--color:${marker.color};--size:${markerSize}px"><span>${marker.name}</span></div>`
                });
                L.marker([marker.lat, marker.lng], { icon: myIcon }).addTo(this.map).on('click', () => this.openPopin(i + 1));
            });
        }

        // const imageBounds = [[-90, -180], [90, 180]];
        // L.imageOverlay(MAP_TEXTURE, imageBounds).addTo(this.map);
    }
}