import Component from '~/components/component';

export default class Intervention extends Component {
    constructor(...args) {
        super(...args);

        this.activeIndex = -1;

        this._retrieveDOM();
        this._bind();
        this.handleDotClick(0); // SET FIRST INDEX OPEN
    }

    _retrieveDOM() {
        this.dom.infos = this.dom.component.querySelectorAll('.js-info');
    }

    handleDotClick(i) {
        if (this.activeIndex >= 0) {
            this.dom.infos[this.activeIndex].classList.remove("open");
        }
        if (this.activeIndex === i) {
            this.activeIndex = -1
            this.dom.infos[i].classList.remove("open");
        } else {
            this.activeIndex = i;
            this.dom.infos[i].classList.add("open");
        }
    }

    _bind() {
        this.dom.infos.forEach((info, i) => {
            info.addEventListener('click', this.handleDotClick.bind(this, i));
        });
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
