import { gsap } from 'gsap';
import Component from '~/components/component';

export default class PageTransition extends Component {

    _classes = {
        blockOrange: 'js-page-transition-block-orange',
        blockBlue: 'js-page-transition-block-blue',
        blockdarkBlue: 'js-page-transition-block-dark-blue',
        noAjax: 'no-ajax'
    };

    _urlOrigin = window.location.origin || window.location.protocol + '//' + window.location.hostname;
    _nextLocationHref = null;

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.blocks = {
            orange: this.dom.component.querySelector('.' + this._classes.blockOrange),
            blue: this.dom.component.querySelector('.' + this._classes.blockBlue),
            darkBlue: this.dom.component.querySelector('.' + this._classes.blockdarkBlue),
        }
    }

    init() {
        super.init();

        window.addEventListener("load", this._initTimelines.bind(this));
    }

    _initTimelines() {

        this._showRevealPageTl = gsap.timeline({ paused: true, onComplete: this.showingPage.bind(this) });
        this._showRevealPageTl
            .to([this.dom.views.blocks.darkBlue, this.dom.views.blocks.orange, this.dom.views.blocks.blue], { scaleY: 0, transformOrigin: '0 0', duration: 0.5, ease: "power3.easeIn", stagger: 0.2 })

        this._showRevealPageTl.play();

        // LEAVING PAGE
        this._showLeavingPageTl = gsap.timeline({ paused: true, onComplete: this.leavingPage.bind(this) });
        this._showLeavingPageTl
            .to([this.dom.views.blocks.blue, this.dom.views.blocks.orange, this.dom.views.blocks.darkBlue], { scaleY: 1, transformOrigin: '100% 100%', duration: 0.5, ease: "power3.easeIn", stagger: 0.2 })
    }

    showingPage() {
        this.dom.component.style.display = 'none';
    }

    leavingPage() { }

    bind() {
        super.bind();

        delegate(document.body, '[href]', 'click', this._linkClickedHandler.bind(this), false);

        // Trick history cache btn and page-transitions issue
        window.addEventListener("pageshow", this.onWindowPageShow.bind(this));
    }

    onWindowPageShow(event) {
        if (event.persisted) {
            document.querySelector('html').classList.add('reloading');
            window.location.reload();
        }
    }

    _linkClickedHandler(event) {
        let href = event.delegateTarget.getAttribute('href');
        let target = event.delegateTarget.getAttribute('target');

        // Prevent self clicked url
        if (href == document.location.href) {
            event.preventDefault();
            return null;
        }

        if (href.includes(this._urlOrigin) && !event.delegateTarget.classList.contains(this._classes.noAjax) && href.indexOf('#') == -1 && href.indexOf('mailto:') == -1 && href.indexOf('tel:') == -1 && target != '_blank') {

            event.preventDefault();
            event.stopImmediatePropagation();

            this._nextLocationHref = href;

            this.startLeavingPage();

            return null; // prevent user navigation away until animation's finished
        }
    }

    startLeavingPage(href = null) {

        this.dom.component.style.display = 'block';
        this._showLeavingPageTl.play();

        setTimeout(() => {
            window.location = this._nextLocationHref;
            // document.location.href = url;
        }, this._showLeavingPageTl.duration() * 1000);

        // prevent user navigation away until animation's finished
        // this.app.emit( 'leaving_page_transition_start', { href: ( href ) ? href : this.nextLocationHref,  duration: this.showLeavingPage.duration() } );
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
