import Component from '~/components/component';
import FormValidator from '../../helpers/form-validator';
import NoScroll from '../../helpers/no-scroll';

export default class NewsletterPopin extends Component {
    _classes = {
        formContent: 'js-newsletter-popin-form-content',
        formInput: 'js-newsletter-popin-input',
        formMsgError: 'js-newsletter-popin-msg-error',
        formButton: 'js-newsletter-popin-button',
        formResult: 'js-newsletter-popin-form-result',
        formResultText: 'js-newsletter-popin-form-result-text',
        buttonClose: 'js-newsletter-close-popin',
        loading: 'loading',
        error: 'error',
        show: 'show',
        emailInvalid: 'email-invalid',
        openPopin: 'js-newsletter-open-popin',
    };

    _actionRegisterForm = 'mdm_newsletter_register_ajax';
    _is_process = false;

    constructor(...args) {
        super(...args);

        this._retrieveDOM();

        this.noScroll = new NoScroll();

        this.inputsData
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.buttonClose = this.dom.component.querySelector('.' + this._classes.buttonClose);

        this.dom.views.form = {
            content: this.dom.component.querySelector('.' + this._classes.formContent),
            inputs: [].slice.call(this.dom.component.querySelectorAll('.' + this._classes.formInput)),
            button: this.dom.component.querySelector('.' + this._classes.formButton),
            result: this.dom.component.querySelector('.' + this._classes.formResult),
            resultText: this.dom.component.querySelector('.' + this._classes.formResultText),
            msgError: this.dom.component.querySelector('.' + this._classes.formMsgError)
        };
    }

    init() {
        super.init();

        this._initForm();
    }

    /**
     * Init form with FormValidator class
     */
    _initForm() {

        /** Init Form Validator **/
        this._Validator = new FormValidator({
            'inputs': this.dom.views.form.inputs,
            'parent': false
        });
    }

    bind() {
        super.bind();

        ['_validate', '_openPopin', '_closePopin']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        // Validate Form
        this.dom.views.form.button.addEventListener('click', this._validate);

        // Close popin
        this.dom.views.buttonClose.addEventListener('click', this._closePopin);

        // Open popin
        delegate(document.body, '.' + this._classes.openPopin, 'click', this._openPopin, false);
    }

    /**
     * The user clicks on the popin open button
     * @param event
     */
    _openPopin() {
        this.dom.component.classList.add(this._classes.show);
        this.noScroll.disableScroll();        
    }

    /**
     * The user clicks on the popin close button
     * @param event
     */
    _closePopin() {
        this.dom.component.classList.remove(this._classes.show);
        this.noScroll.enableScroll();
    }

    /**
     * The user clicks on the "send" button of the form
     * @param event
     */
    _validate(event) {

        let errors = [];
        let emailValid = true;

        // Hide message error
        if (this.dom.views.form.msgError) {
            this.dom.views.form.msgError.classList.remove(this._classes.show);
            this.dom.views.form.msgError.classList.remove(this._classes.emailInvalid);
        }

        // Check process
        if (this._is_process == false) {

            this._is_process = true;

            document.body.classList.add(this._classes.loading);
            this.dom.views.form.button.classList.add(this._classes.loading);

            let data = new FormData();

            // Check Form inputs
            if (this._Validator.isValid()) {

                // Get Form Fields data
                this.inputsData = this._Validator.getData();

                for (let item in this.inputsData) {
                    data.append(item, this.inputsData[item]);
                }

            } else {
                errors.push('Form not valid');
            }

            // Check Form inputs type "email"
            if (this._Validator.isValidEmail() == false) {
                emailValid = false;
                errors.push('Form email not valid');
            }

            // Check errors
            if (errors.length > 0) {

                // Show message error
                if (this.dom.views.form.msgError) {

                    if (emailValid == false) {
                        this.dom.views.form.msgError.classList.add(this._classes.emailInvalid);
                    }

                    this.dom.views.form.msgError.classList.add(this._classes.show);
                }

                this._is_process = false;
                document.body.classList.remove(this._classes.loading);
                this.dom.views.form.button.classList.remove(this._classes.loading);

                return;
            }

            // Request
            this._request(data);
        }
    }

    /**
     * Request "Form Contact Send"
     * @param data (FormData)
     */
    _request(data) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/wp/wp-admin/admin-ajax.php?action=' + this._actionRegisterForm, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this._response((JSON.parse(xhr.response)));
            }
            this._is_process = false;
            document.body.classList.remove(this._classes.loading);
            this.dom.views.form.button.classList.remove(this._classes.loading);

        }.bind(this);
        xhr.send(data);
    }

    /**
     * Response to the request to send the form
     * @param response Ajax
     */
    _response(response) {

        this.dom.views.form.resultText.innerHTML = response.data.message;

        if (response.success) {

            // Bloc "Message bien envoyé"
            // Send google event
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ 'event': 'formSubmit', 'form-name': 'newsletter', 'form-location': '' });
            }

        } else {
            // Bloc "Erreur dans l'envoi du message"
            this.dom.views.form.result.classList.add(this._classes.error);
        }
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
