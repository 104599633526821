import axios from 'axios';
import { gsap } from 'gsap';
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
// import Tests from 'helpers/Tests'
import Detect from 'helpers/Detect';
import 'lib/polyfill';
import debounce from 'lodash.debounce';
import { mapp } from 'utils/math';
import { CONFIG } from '../config-project';
import Google from '../helpers/google';
import Loader, { LoaderEvent } from '../helpers/Loader';
import Router, { RouterEvent } from '../helpers/Router';
import States from '../helpers/states';
import Store from '../helpers/Store';
import LocalSvgSprite from '../helpers/svg-sprite';
import { map } from '../map/map.generic';
import { PageEvent } from '../pages/page';


gsap.registerPlugin(ScrambleTextPlugin);


Detect.init(navigator.userAgent);

if (window.ENV.browser === 'ie' || window.ENV.browser === 'edge') {
    var oldGetBoundingClientRect = HTMLElement.prototype.getBoundingClientRect;
    HTMLElement.prototype.getBoundingClientRect = function () {
        var res = oldGetBoundingClientRect.apply(this, arguments);
        res.x = res.left;
        res.y = res.top;
        return res;
    }
}

export default class Main {
    constructor() {

        var localSvgSprite = new LocalSvgSprite();

        this._map = map;
        Store.componentsMap = map;

        this.Components = {};
        this.Page = null;

        this._cache = {};
        this._DOMparser = new DOMParser();
        this._isFirstInit = true;
        this._device = "generic";

        bindAll(this, [
            '_resize',
            '_routeChangeHandler',
            '_loadingHandler',
            '_onLoadingProgress',
            '_mediaLoadedHandler',
            '_pageLoadedHandler',
            '_destroyClasses',
            '_request',
            '_response'
        ]);

        this._retrieveDom();
        this._bind();
        this._initContext();

        this._dom.body.classList.add('dom-content-loaded');
        this._initTlLoading();

        // if (CONFIG.DEBUG) {
        //     window.Main = this;
        //     window.States = States;
        // }

        // document.body.classList.add(Tests.useWebGL ? 'is-webgl' : 'no-webgl' );

        window.axiosDebug = (url) => {
            axios.get(url)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }

    /**
     * Retrieve DOM Elements
     */
    _retrieveDom() {
        this._dom = {
            body: document.body,
            main: document.querySelector('body main'),
            screenwrapper: document.querySelector('body main .screen-wrapper'),
            page: document.querySelector('body main .page'),
            transition: document.querySelector('body .screen-transition'),
            appWrapper: document.body.querySelector('.app-wrapper'),
            textLoading: document.body.querySelector('.js-screen-transition-txt'),
            transitionBar: document.body.querySelector('.js-screen-transition-bar'),
            transitionProgress: document.body.querySelector('.js-screen-transition-progress')
        };

        States.dom = this._dom;
    }

    /**
     * Bind events
     */
    _bind() {
        window.addEventListener('resize', debounce(this._resize, 200));

        if (CONFIG.IS_AJAX) {
            console.log('IS_AJAX');
            Router.on(RouterEvent.ROUTE_CHANGE, this._routeChangeHandler);
        }

        if (CONFIG.IS_LOADER) {
            Loader.on(LoaderEvent.LOADING, this._loadingHandler);
            Loader.on(LoaderEvent.SET_PERCENT, this._onLoadingProgress);
        }

        if (CONFIG.IS_LAZY_LOADING) {
            Loader.on(LoaderEvent.MEDIA_LOADED, this._mediaLoadedHandler);
        } else {
            if (CONFIG.IS_LOADER)
                Loader.on(LoaderEvent.MEDIA_LOADED, this._pageLoadedHandler);
        }
    }

    /**
     * Initialise the current context
     */
    _initContext() {
        this._retrieveDomProperties();
        this._initComponents();
        this._initPage();

        gsap.killTweensOf(this._dom.transitionProgress);
        gsap.set(this._dom.transitionProgress, { scaleX: 0 });
        if (CONFIG.IS_LOADER)
            Loader.preloadMedia(this._isFirstInit ? this._dom.body : this._dom.page);

        if (!CONFIG.IS_LAZY_LOADING)
            this._pageLoadedHandler();

        this._resize();

        setTimeout(() => {
            this._resize();
        }, 2000)

        Google.bind();
    }

    /**
     * Retrieve page properties: entity & page name
     */
    _retrieveDomProperties() {
        this._properties = {
            entity: this._dom.page.getAttribute('data-entity').toLowerCase(),
            page: this._dom.page.getAttribute('data-page').toLowerCase()
        };
    }

    /**
     * Detect available components in Page and instanciate them
     */
    _initComponents() {
        const container = (this._isFirstInit) ? this._dom.body : this._dom.page;
        const components = container.querySelectorAll('[data-component]');
        for (let i = 0, j = components.length; i < j; i++) {
            const component = components[i];
            const name = component.getAttribute('data-component');
            if (this._map.components.hasOwnProperty(name)) {
                if (this.Components.hasOwnProperty(name.capitalize())) {
                    if (isArray(this.Components[name.capitalize()])) {
                        this.Components[name.capitalize()].push(new this._map.components[name].default(component));
                    } else {
                        this.Components[name.capitalize()] = [
                            this.Components[name.capitalize()],
                            new this._map.components[name].default(component)
                        ];
                    }
                } else {
                    this.Components[name.capitalize()] = new this._map.components[name].default(component);
                }
            } else {
                throw new Error(`Main${this._device.capitalize()} :: you are calling non-existing '${name}' component in your map file. Please check your map file.`);
            }
        }

        States.Components = this.Components;
    }

    /**
     * Detect current page, find class and instanciate it
     */
    _initPage() {
        if (this._map.pages.hasOwnProperty(this._properties.entity)) {
            if (this._map.pages[this._properties.entity].hasOwnProperty(this._properties.page)) {
                this.Page = new this._map.pages[this._properties.entity][this._properties.page].default(this._dom.page, this.Components);
            } else {
                throw new Error(`Main${this._device.capitalize()} :: you are calling non-existing '${this._properties.page}' page for entity '${this._properties.page}' in your map file. Please check your map file.`);
            }
        } else {
            throw new Error(`Main${this._device.capitalize()} :: you are calling non-existing '${this._properties.entity}' page entity in your map file. Please check your map file.`);
        }

        // Send google event
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({ 'event': 'generic', 'env_work': 'production', 'pageType': this._properties.entity, 'device': (Store.isMobile ? 'mobile' : 'desktop') });                                                 
        }

        States.Page = this.Page;
    }

    /**
     * Triggered when the window is resize
     */
    _resize() {
        const width = window.innerWidth || document.documentElement.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight;


        this._browseComponents('_resize', [width, height]);
        this._browseComponents('resize', [width, height]);

        if (this.Page) this.Page.resize(width, height);


        if (Store.isMobile) {
            clearTimeout(this._resizeTo);
            this._resizeTo = setTimeout(() => {
                const width = window.innerWidth || document.documentElement.clientWidth;
                const height = window.innerHeight || document.documentElement.clientHeight;


                this._browseComponents('_resize', [width, height]);
                this._browseComponents('resize', [width, height]);

                if (this.Page) this.Page.resize(width, height);
            }, 100)
        }

        States.width = width;
        States.height = height;
    }

    /**
     * Triggered when the route changes
     */
    _routeChangeHandler({ origin, current }, url) {
        this.PreviousPage = this.Page;

        clearTimeout(this._resizeTo);

        Router.lock();
        Google.unbind();

        gsap.killTweensOf(this._dom.transitionProgress);
        gsap.set(this._dom.transitionProgress, { scaleX: 0 });

        this._browseComponents('_unbind', null);

        this._browseComponents('unbind', null, (component, key) => {
            if (!component.persist) {
                component = null;
                delete this.Components[key];
            }
        });

        // this.tlLoading.play();
        gsap.fromTo(this._dom.transition, {
            y: '100vh',
        }, {
            duration: 0.7,
            y: 0,
            ease: "power2.inOut",
            onComplete: () => {
                this._dom.transition.classList.add('anim');
                this.PreviousPage.unbind();
                this.PreviousPage.hide();
            }
        });

        // this.PreviousPage.unbind();
        // this.PreviousPage.hide();

        this.PreviousPage.once(PageEvent.NEXT, this._request);
        this.PreviousPage.once(PageEvent.PAGE_DESTROYED, this._destroyClasses);
        window.scrollTo(0, 0);
    }

    _onLoadingProgress(percent) {
        // first 10 percent is for xhr request of the page
        let progress = mapp(percent, 0, 100, 0.1, 1, true);
        gsap.killTweensOf(this._dom.transitionProgress);

        if (progress < 1) {
            gsap.to(this._dom.transitionProgress, { scaleX: progress, duration: .6, ease: 'linear' });
        } else {
            gsap.to(this._dom.transitionProgress, { scaleX: progress, duration: .7, ease: 'cubic.out' });
        }
    }

    /**
     * Triggered when the media are loading
     */
    _loadingHandler(percent) {
        // console.log('MAIN.js : _loadingHandler', percent);
        // document.body.querySelector('.loader .js-count').innerHTML = Math.round(percent) + ' %';
    }

    /**
     * Triggered when the media are loaded
     */
    _mediaLoadedHandler() {
        this._browseComponents('mediaLoadedHandler');

        this.Page.mediaLoadedHandler();
    }

    /**
     * Triggered when the page is loaded
     */
    _pageLoadedHandler() {
        if (!CONFIG.IS_LAZY_LOADING)
            this._browseComponents('mediaLoadedHandler');
        this._browseComponents('init');
        this._browseComponents('bind');

        this.Page.init();
        this.Page.bind();
        this.Page.show();

        // CLEAN
        gsap.to(this._dom.transition, {
            duration: 0.7,
            y: '-100vh',
            ease: "power2.inOut",
            onComplete: () => {
                // this.tlLoading.pause();
            }
        });

        Router.execBodyScripts(this._dom.page);
        Router.updateSwitchLangUrl();
        Router.unlock();
        Router.listen();
    }

    /**
     * Destroy instances of the previous Page / Components
     */
    _destroyClasses() {
        this._dom.screenwrapper.removeChild(this.PreviousPage.dom.page);

        this.PreviousPage.off(PageEvent.PAGE_DESTROYED, this._destroyClasses);
        this.PreviousPage = null;
    }

    /**
     * AJAX request
     */
    _request() {
        gsap.killTweensOf(this._dom.transitionProgress);
        gsap.set(this._dom.transitionProgress, { scaleX: 0 });
        gsap.to(this._dom.transitionProgress, { scaleX: .15, duration: 1.3, ease: 'linear' });

        if (CONFIG.IS_LOADER)
            Loader.show();
        let url = Router.getUrl();

        const cacheXhr = this._cache[url];
        this.Page.off(PageEvent.NEXT, this._request);


        this._startRequest = Date.now();
        if (cacheXhr) {
            this._response(cacheXhr);
        } else {
            if (url === '') url = '/'

            axios.get(url)
                .then(response => {
                    this._response(response.data);
                })
                .catch(error => {
                    console.error(error);

                    axios.get(url)
                        .then(response => {
                            this._response('/');
                        })
                        .catch(error => {
                            console.error(error);
                        });
                });
        }
    }

    /**
     * AJAX response
     */
    _response(xhr) {

        if (this._startRequest) {
            let time = Date.now() - this._startRequest;
            console.log('server response time', time / 1000 + 's')
        }

        this._cache[Router.getUrl()] = xhr;

        // Update DOM
        const dom = this._DOMparser.parseFromString(xhr, 'text/html');
        this._dom.page = dom.querySelector('div.page');
        this._dom.screenwrapper.appendChild(this._dom.page);
        States.dom = this._dom;

        // Update title
        const title = dom.querySelector('title');
        Router.setTitle(title.innerText);

        Router.AJAXresponseHandler();
        if (CONFIG.IS_LOADER)
            Loader.AJAXresponseHandler();

        this._initContext();
        window.scrollTo(0, 0);
    }

    _browseComponents(methodName, argsArray = [], callback = null) {
        for (let key in this.Components) {
            if (this.Components.hasOwnProperty(key)) {
                // Browse all components
                let component = null;
                if (isArray(this.Components[key])) {
                    for (let i = 0, j = this.Components[key].length; i < j; i++) {
                        const componentsType = this.Components[key];
                        component = componentsType[i];
                        if (component.persist === false || ((methodName !== 'init' || this._isFirstInit === true) && methodName !== 'unbind'))
                            component[methodName].apply(component, argsArray);
                    }
                } else {
                    component = this.Components[key];
                    if (component.persist === false || ((methodName !== 'init' || this._isFirstInit === true) && methodName !== 'unbind'))
                        component[methodName].apply(component, argsArray);
                }

                // Callback to call
                if (typeof callback === 'function')
                    callback.call(callback, component, key);
            }
        }

        if (methodName === 'init')
            this._isFirstInit = false;
    }

    _initTlLoading() {
        // this.tlLoading = gsap.timeline({ paused: true, repeat: -1 });
        // this.tlLoading
        // 	.to(this._dom.textLoading, {duration: 5, textTransform: 'uppercase', scrambleText: {chars: "upperCase", speed: 0.1, tweenLength: false} });

        // this.tlLoading.play();
    }
}

const __dcl = () => {
    new Main();
};

document.addEventListener('DOMContentLoaded', __dcl);
