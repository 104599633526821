import Swiper, { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';
import Component from '~/components/component';

const breakpoint = window.matchMedia('(max-width:1023px)');

export default class KeyFiguresSmall extends Component {
    constructor(...args) {
        super(...args);
        this.initSlider = false;
        this.shouldInit();
        this.bind();
    }


    
    bind() {
        window.removeEventListener('resize', this.shouldInit)
        window.addEventListener('resize', this.shouldInit)
    }

    shouldInit = () => {
        if (!breakpoint.matches) {
            if (this.swiper !== undefined) {
                this.swiper.destroy(); 
                this.initSlider = false;
                return;
            } 
        } else if (breakpoint.matches && !this.initSlider) this.initSwiper();
    }

    initSwiper() {
        this.initSlider = true;
        this.swiper = new Swiper(this.dom.component, {
            modules: [Autoplay, Pagination, Navigation, Mousewheel],
            direction: 'horizontal',
            grabCursor: 'true',
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            
            mousewheel: {
                forceToAxis: true,
            },

            pagination: {
                el: this.dom.component.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
            
            autoplay: {
                delay: 4000
            }, 
        });
    }
}
