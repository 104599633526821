import { gsap } from "gsap";
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText';
import Page from '~/pages/page';
import States from '../../helpers/states';
gsap.registerPlugin(ScrollTrigger, SplitText, DrawSVGPlugin, MotionPathPlugin);

export default class DateEntry extends Page {

    _classes = {
        elementBody: 'js-date-entry-element-in-page',
        pushNextDateComp: 'js-date-entry-push-next-date-comp',
        datesFillWhite: 'js-push-next-date-fill-white',
        datesFillblue: 'js-push-next-date-fill-blue',
        datesBackground: 'js-push-next-date-background',
        blockDateUpdate: 'js-date-entry-block-date-update',
        timeline: 'js-date-entry-timeline',
        date: 'js-push-next-date-title',
        header: 'js-header-6',
        fixed: 'fixed',
        updateDate: 'update-date',
        fillAnimate: 'fill-animate',
        hide: 'hide',
        contentAjax: 'content-ajax',
        headerBanner: 'header-6__banner',
        quoteContainer: 'js-quote',
        quoteText: 'js-quote-text',
        quoteAuthor: 'js-quote-author',
        quoteLegend: 'js-quote-legend',
        videoContainer: 'js-video-container',
        videoMedia: 'js-video-media',
        blockTextImgContainer: 'js-text-img',
        blockTextImgContentText: 'js-text-img-content-text',
        blockTextImgContent: 'js-text-img-content',
        blockTextImgImg: 'js-text-img-img',
        blockDateUpdateDeco: 'js-block-update-date-deco',
        birdPath: 'js-bird-path',
        birdItem: 'js-bird-item',
        onlyMobile: 'only-mobile'
    };

    _updateScrollTarget = false;
    _parentScroll = window;
    _parrentScrollContainer = document.body;
    _scrollbarWidth = 0;
    _animIntro = true;

    _limiteDevice = 768;
    _isDesktop = ( States.width > this._limiteDevice );

    _tabTimelineBlockTextImg = [];
    _splitTextQuote = [];

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
        this._initTls();
    }

    _retrieveDOM() {

        this.dom.views = {};

        // Timeline date
        this.dom.views.timeline = this.dom.page.querySelector( '.' + this._classes.timeline );
        
        // Header
        this.dom.views.header = {
            container: this.dom.page.querySelector( '.' + this._classes.header ),
            banner: this.dom.page.querySelector( '.' + this._classes.headerBanner ),
        }

        // Date fixed
        this.dom.views.blockDate = {
            listElementBody: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.elementBody ) ), // Elements : header + breadcrumbs + flexibleContent
            date: this.dom.page.querySelector( '.' + this._classes.date ),
            dateContainerUpdate: this.dom.page.querySelector( '.' + this._classes.blockDateUpdate ),
            datesFillWhite: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.datesFillWhite ) ),
            datesFillBlue: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.datesFillblue ) ),
            datesBackground: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.datesBackground ) ),
            nextDateComp: this.dom.page.querySelector( '.' + this._classes.pushNextDateComp )
        }

        // Quote
        this.dom.views.quote = [];
        let quoteContainers = [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.quoteContainer ) );
        if( quoteContainers ) {
            for( let i = 0, j = quoteContainers.length; i < j; i++ ) {
                let element = quoteContainers[ i ];
                this.dom.views.quote.push( {
                    container: element,
                    text: element.querySelector( '.' + this._classes.quoteText ),
                    author: element.querySelector( '.' + this._classes.quoteAuthor ),
                    legend: element.querySelector( '.' + this._classes.quoteLegend ),
                } )
            }
        }

        // Video
        this.dom.views.video = [];
        let videoContainers = [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.videoContainer ) );
        if( videoContainers ) {
            for( let i = 0, j = videoContainers.length; i < j; i++ ) {
                let element = videoContainers[ i ];
                this.dom.views.video.push( {
                    container: element,
                    media: element.querySelector( '.' + this._classes.videoMedia ),
                } )
            }
        }

        // Block text + image
        this.dom.views.blockTextImg = [];
        let blockTextImg = [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.blockTextImgContainer ) );
        if( blockTextImg ) {
            for( let i = 0, j = blockTextImg.length; i < j; i++ ) {
                let element = blockTextImg[ i ];
                this.dom.views.blockTextImg.push( {
                    container: element,
                    contentText: element.querySelector( '.' + this._classes.blockTextImgContentText ),
                    content: [].slice.call( element.querySelectorAll( '.' + this._classes.blockTextImgContent ) ),
                    img: [].slice.call( element.querySelectorAll( '.' + this._classes.blockTextImgImg ) ),
                } )
            }
        }
        
        // Block Update date
        // this.dom.views.blockDateUpdate = {
        //     container: this.dom.page.querySelector( '.' + this._classes.blockDateUpdate ),
        //     birds: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.blockDateUpdateDeco ) )
        // }

        // Bird
        this.dom.views.bird = {
            path: this.dom.page.querySelector( '.' + this._classes.birdPath ),
            item: this.dom.page.querySelector( '.' + this._classes.birdItem )
        }
    }

    init() {
        super.init();

        // if( this.dom.page.classList.contains( this._classes.contentAjax ) ) {
        //     this._updateScrollTarget = true;
        //     this._parentScroll = this.dom.page.parentNode;

        //     // let parentClassname = this._parentScroll.className.match(/\bjs-[^\s]*/);
        //     // if( !parentClassname ) {
        //     //     parentClassname = this._parentScroll.className.split( ' ' )[ 0 ];
        //     // }
        //     this._parrentScrollContainer = this._parentScroll;
            
        //     this._ScrollListener.updateScrollElement( this._parentScroll );
        //     this._scrollbarWidth = this._parentScroll.offsetWidth - this._parentScroll.clientWidth;
        // }

        setTimeout( () => {
            this._managerBlockDate(window.innerWidth, window.innerHeight);
        }, 1000 )

        if( this.dom.views.quote ) {
            this._initAnimQuote();
        }

        if( this.dom.views.video ) {
            this._initAnimVideo();
        }

        if( this.dom.views.blockTextImg ) {
            this._initAnimBlockTextImg();
        }

        // if( this.dom.views.blockDateUpdate.container ) {
        //     this._initAnimBlockDateUpdate();
        // }

        if( this.dom.views.bird.path && this.dom.views.bird.item ) {
            this._initAnimBird();
        }
    }

    _initTls() {
        super._initTls();

        this._showTl
            .from(this.dom.page, { autoAlpha: 0, duration: 0.01, onComplete: () => {
                // this.dom.views.timeline.classList.remove( this._classes.hide ); // gsap.to( this.dom.views.timeline, {autoAlpha: 1, duration: 0.8, ease: 'power2.out'})

                if( this._animIntro && this.dom.views.header.banner ) {
                    gsap.from( this.dom.views.header.banner, {autoAlpha: 0, duration: 0.8, delay: 0.8, ease: 'power2.out'})
                }
            } }, 0)
    }

    setAnimIntro( value ) {
        this._animIntro = value;
    }
    
    _initAnimQuote() {

        if( this._splitTextQuote.length > 0 ) {
            for( let i = 0, j = this._splitTextQuote.length; i < j; i++ ) {
                this._splitTextQuote[ i ].revert();
            }
        }

        this.tabTimelineQuote = [];
        this._splitTextQuote = [];

        for( let i = 0, j = this.dom.views.quote.length; i < j; i++ ) {

            let element = this.dom.views.quote[ i ];

            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: element.container,
                    scroller: this._parrentScrollContainer,
                    start: "top 90%",
                    end: "+=" + element.container.getBoundingClientRect().height + " 70%", //"bottom 65%", // "+=" + this.dom.views.section.items[0].getBoundingClientRect().height * this._totalSection + " 80%",
                    toggleActions: 'play pause reverse pause',
                    scrub: true,
                    // markers: true
                },
            });

            if( element.text ) {

                this._splitTextQuote.push( 
                    new SplitText( element.text, { type: 'lines', linesClass: 'line' } )
                );

                // tl.to( this._splitTitle.lines, {
                //     y: 0,
                //     ease: 'power2.out',
                //     stagger: 0.1
                // });
                
                // tl.to( this._splitTitle.lines, {
                //     autoAlpha: 1,
                //     ease: 'power2.out',
                //     stagger: 0.1
                // }, '-=0.3');

                let lines = this._splitTextQuote[ ( this._splitTextQuote.length - 1 ) ].lines;
                tl
                    .from( lines, { y: 80, duration: 0.9, ease: "power2.inOut", stagger: (0.3/lines.length) }, '-=0.1' )
                    .from( lines, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut", stagger: (0.3/lines.length) }, '-=0.7' )
            }

            if( element.author ) {
                tl 
                    .from( element.author, { y: 80, duration: 0.9, ease: "power2.inOut" }, '-=0.1' )
                    .from( element.author, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut" }, '-=0.7' )
            }

            if( element.legend ) {
                tl 
                    .from( element.legend, { y: 80, duration: 0.9, ease: "power2.inOut" }, '-=0.1' )
                    .from( element.legend, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut" }, '-=0.7' )
            }

            this.tabTimelineQuote.push( tl );
        }
    }

    _initAnimVideo() {

        this.tabTimelineVideo = [];

        for( let i = 0, j = this.dom.views.video.length; i < j; i++ ) {

            let element = this.dom.views.video[ i ];
            if( element.media ) {
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: element.container,
                        scroller: this._parrentScrollContainer,
                        start: "top 95%",
                        end: "bottom 90%",
                        toggleActions: 'play pause reverse pause',
                        scrub: true,
                        // markers: true
                    },
                });
                tl
                    .from( element.media, { y: 80, duration: 0.9, ease: "power2.inOut" }, '-=0.1' )
                    .from( element.media, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut" }, '-=0.7' )
        
                this.tabTimelineVideo.push( tl );
            }   
        }
    }

    _initAnimBlockTextImg() {

        if( this._tabTimelineBlockTextImg.length > 0 ) {
            
            for( let i = 0, j = this._tabTimelineBlockTextImg.length; i < j; i++ ) {
                this._tabTimelineBlockTextImg[ i ].kill();
            }

            for( let i = 0, j = this.dom.views.blockTextImg.length; i < j; i++ ) {
                let element = this.dom.views.blockTextImg[ i ];
                if( element.img ) {
                    gsap.set( element.img, {clearProps: 'all'});
                }

                if( element.content ) {
                    gsap.set( element.content, {clearProps: 'all'});
                }
            }
        }

        
        this._tabTimelineBlockTextImg = [];

        for( let i = 0, j = this.dom.views.blockTextImg.length; i < j; i++ ) {

            let element = this.dom.views.blockTextImg[ i ];
           
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: element.container,
                    scroller: this._parrentScrollContainer,
                    // start: "top 95%",
                    // end: "bottom 90%",
                    start: "top 90%",
                    end: "+=500px",
                    // endTrigger: element.contentText,
                    // end: "bottom 50%",
                    toggleActions: 'play pause reverse pause',
                    scrub: true,
                    // markers: true
                },
            });

            let first_element = ( this._isDesktop ) ? element.img : element.content;
            let second_element = ( this._isDesktop ) ? element.content : element.img;
      
            if( first_element ) {
                tl
                    .from( first_element, { y: 100, duration: 0.9, ease: "power2.inOut", stagger: (0.3/first_element.length) }, '-=0.1' )
                    .from( first_element, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut", stagger: (0.3/first_element.length) }, '-=0.7' )
            }

            if( second_element ) {
                tl
                    .from( second_element, { y: 80, duration: 0.9, ease: "power2.inOut", stagger: (0.3/second_element.length) }, '-=0.9' )
                    .from( second_element, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut", stagger: (0.3/second_element.length) }, '-=0.7' )
            }
    
            this._tabTimelineBlockTextImg.push( tl );  
        }
    }

    _initAnimBlockDateUpdate() {
        this.tabTimelineBlockDateUpdate = [];

           
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: this.dom.views.blockDateUpdate.container,
                scroller: this._parrentScrollContainer,
                start: "top 100%",
                end: "bottom 95%",
                toggleActions: 'play pause reverse pause',
                scrub: true,
                // markers: true
            },
        });

        if( this.dom.views.blockDateUpdate.birds ) {
            tl
                .from( this.dom.views.blockDateUpdate.birds, { y: 200, duration: 0.9, ease: "power2.inOut", stagger: (0.3/this.dom.views.blockDateUpdate.birds.length) }, '-=0.1' )
                .from( this.dom.views.blockDateUpdate.birds, { autoAlpha: 0, duration: 0.5, ease: "power2.inOut", stagger: (0.3/this.dom.views.blockDateUpdate.birds.length) }, '-=0.7' )
        }
    
        this.tabTimelineBlockDateUpdate.push( tl );  
    }

    _initAnimBird() {
        
        gsap.set( this.dom.views.bird.item, { xPercent:-50, yPercent:-50, transformOrigin: "50% 50%" } );

        let height_path_svg = 10000;
        let end_anim = this.dom.page.getBoundingClientRect().height / height_path_svg;
        let limit_end_anim = this.dom.page.getBoundingClientRect().height * 1.4;

        this.timelineBird = gsap.timeline({ 
            // defaults: {duration: 1 },
            scrollTrigger: {
                scrub: true,
                scroller: this._parrentScrollContainer,
                trigger: this.dom.views.header.container,
                start: "top 0%",
                // endTrigger: ".ct--footer",
                // end: "top center",
                // end: "+="+(this.dom.page.getBoundingClientRect().height),
                end: "+=" + limit_end_anim,
                // markers: true,
                onUpdate: self => {
                    gsap.to( this.dom.views.bird.item, {rotation: () => self.direction === 1 ? 0 : -90, overwrite: 'auto'} );
                }
            }})
        // .from( this.dom.views.bird.path, {drawSVG: 0}, 0)
        .to( this.dom.views.bird.item, {
            motionPath: {
                path: this.dom.views.bird.path, 
                align: this.dom.views.bird.path,
                alignOrigin: [0.5, 0.5],
                autoRotate: 90,
                start: 0,
                end : end_anim
            }
        }, 0)
  
    }

    resize(width, height) {

        this._managerBlockDate(width, height);
        // this._managerTimeline(width, height);

        if (States.width > this._limiteDevice) {
            if( this._isDesktop == false ) {
                this._isDesktop = true;

                if( this.dom.views.quote ) {
                    this._initAnimQuote();
                }
                
                this._initAnimBlockTextImg();
            }
        } else {
            if( this._isDesktop ) {
                this._isDesktop = false;

               

                if( this.dom.views.quote ) {
                    this._initAnimQuote();
                }

                this._initAnimBlockTextImg();
            }
        }
    }

    _update( top ) {
        super._update(top);
        this._animateBlockDate( top );
        // this._animateTimeline( top );
    }

    _managerBlockDate(width, height) {
        this._setSizeBlockDate(width, height);
        this._animateBlockDate( ( this._updateScrollTarget ) ? this._parentScroll.scrollTop : window.scrollY );
    }

    _managerTimeline(width, height) {
        // this._setSizeTimeline(width, height);
        this._animateTimeline( ( this._updateScrollTarget ) ? this._parentScroll.scrollTop : window.scrollY );
    }

    _setSizeBlockDate(width, height) {

        // Content height to consider for date filling 
        this._limitBeforeChangeDate = 0;
        this._heightBeforeChangeDate = 0;
        for( let i = 0, j = this.dom.views.blockDate.listElementBody.length; i < j; i++ ) {
            let element = this.dom.views.blockDate.listElementBody[ i ];
            if( element ) {
                this._limitBeforeChangeDate += element.getBoundingClientRect().height;

                if( element.classList.contains( this._classes.header ) ) {
                    this._heightBeforeChangeDate += element.getBoundingClientRect().height / 2;
                } else {
                    this._heightBeforeChangeDate += element.getBoundingClientRect().height;
                }
            }
        }
        if( this._heightBeforeChangeDate > 0 ) {
            this._heightBeforeChangeDate -= window.innerHeight / 2;
        }
        
        // "top" of the date in the middle of the browser window
        // this._targetTopDate = ( window.innerHeight / 2 ) - ( this.dom.views.blockDate.date.getBoundingClientRect().height * 0.45 );
        // let dateStyles = window.getComputedStyle( this.dom.views.blockDate.date );
        // let dateMatrix = new WebKitCSSMatrix(dateStyles.transform); // dateMatrix.m42 => transform translate Y
        let dateTransformProperty = this._getTranslateValues(this.dom.views.blockDate.date);
        this._targetTopDate = ( window.innerHeight / 2 ) + parseFloat( dateTransformProperty.y );
        
        // Spacing between the top of the window and the "top" of the date in its container
        // this._limitBeforeUnfixedTitle = this._limitBeforeChangeDate + this.dom.views.blockDate.dateContainerUpdate.getBoundingClientRect().height + ( this.dom.views.blockDate.nextDateComp.getBoundingClientRect().height / 2 ) - ( this.dom.views.blockDate.date.getBoundingClientRect().height * 0.45 ) ;
        this._limitBeforeUnfixedTitle = this._limitBeforeChangeDate + ( this.dom.views.blockDate.nextDateComp.getBoundingClientRect().height / 2 ) + parseFloat( dateTransformProperty.y ) ;
    }

    _animateBlockDate( top ) {

        ///////////////////////////
        // 1- Update date fill
        ///////////////////////////
        // let cursorFillDate = top + ( window.innerHeight / 2 );
        if( ( top <= window.innerHeight / 2 ) && this.dom.views.blockDate.nextDateComp.classList.contains( this._classes.fillAnimate ) ) {
            this.dom.views.blockDate.nextDateComp.classList.remove( this._classes.fillAnimate );

            this.dom.views.blockDate.datesFillWhite.map(function(e) {
                // e.style.height = '100%';
                e.style['clip-path'] = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)';
            });

            this.dom.views.blockDate.datesFillBlue.map(function(e) {
                e.style['clip-path'] = 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)';
            });
        }
        if( top > window.innerHeight / 2 ) {
            if( !this.dom.views.blockDate.nextDateComp.classList.contains( this._classes.fillAnimate ) ) {
                this.dom.views.blockDate.nextDateComp.classList.add( this._classes.fillAnimate )
            }

            // let pourcent = Math.min( 100, Math.max( 0, 100-((top * 100 ) / this._topElement)));
            let pourcent = Math.min( 100, Math.max( 0, 100 - ( ( ( top - ( window.innerHeight / 2 ) ) * 100 ) / this._heightBeforeChangeDate ) ) );
            this.dom.views.blockDate.datesFillWhite.map(function(e) {
                // e.style.height = pourcent + '%';
                e.style['clip-path'] = 'polygon(0% 0%, 100% 0%, 100% ' + pourcent + '%, 0% ' + pourcent + '%)';
            });

            this.dom.views.blockDate.datesFillBlue.map(function(e) {
                e.style['clip-path'] = 'polygon(0% ' + pourcent + '%, 100% ' + pourcent + '%, 100% 100%, 0% 100%)';
            });

            // gsap.to( this.dom.views.blockDate.datesFillWhite, {height: pourcent + '%', duration: 0.5, ease: 'power2.out'})
            
        }
       
        ///////////////////////////
        // 2- Change date value
        ///////////////////////////
        let cursorChangeDate = top + ( window.innerHeight / 2.5 );
        if( cursorChangeDate > this._limitBeforeChangeDate && !this.dom.views.blockDate.date.classList.contains( this._classes.updateDate ) ) {
            this.dom.views.blockDate.date.classList.add( this._classes.updateDate )

            // gsap.to( this.dom.views.blockDate.datesFillBlue, {"clip-path": 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)', duration: 0.5, ease: 'power2.out'})
            // gsap.to( this.dom.views.blockDate.datesBackground, {"clip-path": 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', duration: 0.5, ease: 'power2.out'})
        }
        if( cursorChangeDate <= this._limitBeforeChangeDate && this.dom.views.blockDate.date.classList.contains( this._classes.updateDate ) ) {
            // gsap.to( this.dom.views.blockDate.datesFillBlue, {"clip-path": 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', duration: 0.5, ease: 'power2.out'})
            // gsap.to( this.dom.views.blockDate.datesBackground, {"clip-path": 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)', duration: 0.5, ease: 'power2.out'})
            this.dom.views.blockDate.date.classList.remove( this._classes.updateDate )
        }

        ////////////////////////////////////////////////////////////////
        // 3- Put the date in fixed or in the natural flow of the site
        ////////////////////////////////////////////////////////////////
        let cursorFixedDate = top + this._targetTopDate;
        if( cursorFixedDate > this._limitBeforeUnfixedTitle && this.dom.views.blockDate.nextDateComp.classList.contains( this._classes.fixed ) ) {
            this.dom.views.blockDate.nextDateComp.classList.remove( this._classes.fixed );

            if( this._updateScrollTarget ) {
                this.dom.views.blockDate.date.style.left = 'calc(50% + ' + this._scrollbarWidth / 2 + 'px)';

                 // -- fix diff mobile -- 
                this.dom.views.blockDate.date.querySelector( '.' + this._classes.onlyMobile ).style.transform = 'translateX( -' + this._scrollbarWidth / 2 + 'px)';
            }
            
        }
        if( cursorFixedDate <= this._limitBeforeUnfixedTitle && !this.dom.views.blockDate.nextDateComp.classList.contains( this._classes.fixed ) ) {
            this.dom.views.blockDate.nextDateComp.classList.add( this._classes.fixed );
            
            if( this._updateScrollTarget ) {
                this.dom.views.blockDate.date.style.left = '50%';

                // -- fix diff mobile -- 
                this.dom.views.blockDate.date.querySelector( '.' + this._classes.onlyMobile ).style.transform = 'translateX(0)';
            }
        }
    }

    _setSizeTimeline( width, height ) {}

    _animateTimeline( top ) {

        let cursorChangeTimeline = top + ( window.innerHeight / 2.5 );
        if( cursorChangeTimeline > this._limitBeforeChangeDate && !this.dom.views.timeline.classList.contains( this._classes.hide ) ) {
            this.dom.views.timeline.classList.add( this._classes.hide )
        }
        if( cursorChangeTimeline <= this._limitBeforeChangeDate && this.dom.views.timeline.classList.contains( this._classes.hide ) ) {
            this.dom.views.timeline.classList.remove( this._classes.hide )
        }
    }

    _getTranslateValues (element) {
        const style = window.getComputedStyle(element)
        const matrix = style['transform'] || style.webkitTransform || style.mozTransform
      
        // No transform property. Simply return 0 values.
        if (matrix === 'none' || typeof matrix === 'undefined') {
          return {
            x: 0,
            y: 0,
            z: 0
          }
        }
      
        // Can either be 2d or 3d transform
        const matrixType = matrix.includes('3d') ? '3d' : '2d'
        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')
      
        // 2d matrices have 6 values
        // Last 2 values are X and Y.
        // 2d matrices does not have Z value.
        if (matrixType === '2d') {
          return {
            x: matrixValues[4],
            y: matrixValues[5],
            z: 0
          }
        }
      
        // 3d matrices have 16 values
        // The 13th, 14th, and 15th values are X, Y, and Z
        if (matrixType === '3d') {
          return {
            x: matrixValues[12],
            y: matrixValues[13],
            z: matrixValues[14]
          }
        }
    }

    // show() {
    //     super.show();
    //     console.log('tre')
    // }
    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
