import Component from '~/components/component';

const expandSelectors = {
    CONTAINER: 'expand-container',
    HANDLER: 'expand-handler',
    SHUTTER: 'expand-handler',
    INNER: 'expand-inner'
};

export default class ExpandContent extends Component {
    constructor(...args) {
        super(...args);
        this.init();
    }
  
    init() {
        this._retrieveDOM();
        this.bind();
    }

    open(target) {
        target.classList.add('open');
        target.classList.remove('close');
        this.setStyle(target, true);
    }
 
    close(target) {
        target.classList.add('close');
        target.classList.remove('open');
        this.setStyle(target, false);
    }

    toggle = (e) => {
        const target = e.currentTarget;
        if(target.classList.contains("close")) {this.open(target);}
        else {this.close(target);}
    }

    setStyle(target, type) {
        const container = target.parentNode.parentNode;
        const inner = container.querySelector('.expand');
        if(type) inner.classList.add("expandOpen");
        else inner.classList.remove("expandOpen");
    }

    _retrieveDOM() {
        this.dom = {};
        this.dom.handlers = document.body.querySelectorAll(".expand-handler");
    }

    bind() {
        this.dom.handlers.forEach(handler => {
            if(!handler.hasAttribute("listener")) {
                handler.removeEventListener("click", this.toggle);
                handler.addEventListener("click", this.toggle);
                handler.setAttribute("listener", true);
            }
        });
    }
}
