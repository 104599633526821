import 'leaflet';
import Component from '~/components/component';

const L = window['L'];

export default class Map extends Component {

    _dataCities = DATA_CITIES;

    constructor(...args) {
        super(...args);
        this.initMap();
    }

    initMap() {
        this.map = L.map('map-container', {
            center: [46.689855, 2.403062],
            zoom: 5,
            minZoom: 5,
            maxZoom: 10,
            maxBounds: [[50.698545, -6.616991], [40.939934, 10.954500]],
            maxBoundsViscosity: 1,
            attributionControl: false
        });

        L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
        }).addTo(this.map);


        if( this._dataCities ) {
            this._dataCities.forEach(city => {
                const myIcon = L.divIcon({
                    className: 'my-div-icon',
                    html: `<a href="${city.url}"></a>`
                });
                L.marker([city.lat, city.lng], { icon: myIcon }).addTo(this.map);
            });
        }
       

        // const markerSize = SMALL_POINT_SIZE * 15;

        // if (this.isAction) {
        //     const markersCluster = new L.MarkerClusterGroup();
        //     this.data.forEach((marker, i) => {
        //         const myIcon = L.divIcon({
        //             className: 'my-div-icon',
        //             html: `<div style="--color:${colorData.green};--size:${markerSize}px"></div>`
        //         });
        //         const pin = L.marker([marker.lat, marker.lng], { icon: myIcon }).on('click', () => this.openPopin(i + 1));
        //         markersCluster.addLayer(pin);
        //     });
        //     this.map.addLayer(markersCluster);
        // } else {
        //     this.data.forEach((marker, i) => {
        //         const myIcon = L.divIcon({
        //             className: 'my-div-icon',
        //             html: `<div style="--color:${marker.color};--size:${markerSize}px"><span>${marker.name}</span></div>`
        //         });
        //         L.marker([marker.lat, marker.lng], { icon: myIcon }).addTo(this.map).on('click', () => this.openPopin(i + 1));
        //     });
        // }

        // const imageBounds = [[-90, -180], [90, 180]];
        // L.imageOverlay(MAP_TEXTURE, imageBounds).addTo(this.map);
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
