import EventEmitter from "eventemitter3";

export const MANAGE_UPLOAD_EVENT = {
    'SUCCESS_UPLOAD' : '__mu_success_upload',
    'ERROR_UPLOAD' : '__mu_error_upload',
}
/**
 * Manage upload multiple files from upload inputs
 */
export default class UploadManager extends EventEmitter {

    _listeners = {
        _successUpload: this._successUpload.bind( this ),
        _errorUpload: this._errorUpload.bind( this ),
    };

    constructor(opts) {
        super();

        this._uploads = opts.uploads;
        this._uploadsArray = [];

        this._initialize();
        this._bind();
    }

    /**
     * Initialize Upload Input class.
     */
    _initialize()
    {
        for (var item in this._uploads) {
            const input = this._uploads[item].input;
            const maxSize = this._uploads[item].maxSize;
            const type = this._uploads[item].type;
            const label = this._uploads[item].label;
            const required = this._uploads[item].required;
            this._uploadsArray.push(new UploadInput({ input, maxSize: maxSize, type: type, label: label, required: required }));
        }
    }

    _bind() {

        if( this._uploadsArray.length > 0 ) {

            for( let i = 0, j = this._uploadsArray.length; i < j; i++ ) {
                this._addEmitEvent( i )
            }
        }
    }

    _addEmitEvent( index ) {

        // Emit "success"
        this._uploadsArray[ index ].on( MANAGE_UPLOAD_EVENT.SUCCESS_UPLOAD, this._listeners._successUpload );

        // Emit "error"
        this._uploadsArray[ index ].on( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, this._listeners._errorUpload );
    }

    _successUpload( data ) {
        let index = this._uploadsArray.indexOf( data.instance );
        this.emit( MANAGE_UPLOAD_EVENT.SUCCESS_UPLOAD, { index : index, firstUpload: data.firstUpload, nameFile: data.nameFile } );
    }

    _errorUpload( data ) {
        let index = this._uploadsArray.indexOf( data.instance );
        this.emit( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, { index : index } );
    }

    addUpload( upload ) {
        let input = upload.input;
        let maxSize = upload.maxSize;
        let type = upload.type;
        let label = upload.label;
        let required = upload.required;
        this._uploadsArray.push(new UploadInput({ input, maxSize: maxSize, type: type, label: label, required: required }));
 
        let index = this._uploadsArray.length - 1;

        this._addEmitEvent( index );
    }

    removeUpload( index ) {

        // Remove event
        this._uploadsArray[ index ].off( MANAGE_UPLOAD_EVENT.SUCCESS_UPLOAD, this._listeners._successUpload );
        this._uploadsArray[ index ].off( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, this._listeners._errorUpload );

        // Destroy instance "UploadInput"
        this._uploadsArray[ index ].destroy();

        // Remove instance "UploadInput"
        this._uploadsArray.splice( index, 1 );
    }

    getFiles()
    {
        this._process();

        this.values = [];
        for(var i = 0, j = this._uploadsArray.length; i < j; i++)
        {
            if(this._uploadsArray[i].isValid())
            {
                if(this._uploadsArray[i].getValue())
                    this.values[this._uploadsArray[i]._input.getAttribute("name")] = this._uploadsArray[i].getValue();
            } else
                return false;
        }

        return true;
    }

    getFilesValues()
    {
        return this.values;
    }

    _process() {
        for(var i = 0, j = this._uploadsArray.length; i < j; i++)
        {
            if(this._uploadsArray[i]._file)
                this._uploadsArray[i]._process();
        }
    }

    destroy() {
        for(var i = 0, j = this._uploadsArray.length; i < j; i++)
        {
            this._uploadsArray[ i ].off( MANAGE_UPLOAD_EVENT.SUCCESS_UPLOAD, this._listeners._successUpload );
            this._uploadsArray[ i ].off( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, this._listeners._errorUpload );
            this._uploadsArray[i].destroy();
        }
    }

}

class UploadInput extends EventEmitter {

    constructor(opts) {
        super();

        this._input = opts.input;
        this._maxSize = opts.maxSize;
        this._type = opts.type;
        this._label = opts.label;
        this._required = opts.required;
        this._firstUpload = true;

        this._listeners = {};

        this._initialize();
    }

    _initialize() {

        this._listeners.change = function(e) {
            if (this._input.files[0])
                this._file = this._input.files[0];
            this._process();
        }.bind(this);
        this._input.addEventListener('change', this._listeners.change);

    }

    _process() {
        // Check if a file is uploaded
        this._clearClasses();

        if(this._file) {

            this._size = this._file.size/(1024*1024);
            // Check if file follow upload restrictions (maxSize + type)
            if(this._size <= this._maxSize)
            {
                this._label.classList.remove('error-size');
                this._valid = false;
                for(var el in this._type)
                {
                    if(this._type[el] == this._file.type)
                        this._valid = true;
                }

                if(this._valid == false) {
                    this._label.classList.add('error-type');
                    this.emit( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, { instance: this } );
                }
                else {
                    this._label.classList.remove('error-type');
                    this._label.classList.add('validate');

                    this.emit( MANAGE_UPLOAD_EVENT.SUCCESS_UPLOAD, { instance: this, firstUpload: this._firstUpload, nameFile: this._file.name } );
                    this._firstUpload = false;           
                }

            } else {
                this._label.classList.add('error-size');
                this.emit( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, { instance: this } );
            }

        } else {
            console.log('FILE EMPTY');
            this.emit( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, { instance: this } );
        }

    }

    _clearClasses() {
        this._label.classList.remove('validate');
        this._label.classList.remove('error-size');
        this._label.classList.remove('error-type');
    }

    isValid() {
        if(this._required == true || typeof this._file != 'undefined')
            return this._valid;
        else
            return true;
    }

    getValue() {
        if(this._file)
            return this._file;
    }

    destroy() {
        this._input.removeEventListener('change', this._listeners.change);
        this._listeners = {};
    }
}