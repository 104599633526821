import Page from '~/pages/page';
import FormValidator from '../../helpers/form-validator';
import UploadManager, { MANAGE_UPLOAD_EVENT } from "../../helpers/upload-manager.js";

export default class NewsEntry extends Page {

    _classes = {
        formContainer: 'js-job-form-container',
        formInput: 'js-job-form-input',
        formSelect: 'js-job-form-select',
        formCheckbox: 'js-job-form-checkbox',
        formRadio: 'js-job-form-radio',
        formUpload: 'js-job-form-upload',
        formUploadLabel: 'js-job-form-upload-label',
        formUploadContainer: 'js-job-form-upload-container',
        formUploadSuccess: 'js-job-form-upload-success',
        formContainerButton: 'js-job-form-container-button',
        formButton: 'js-job-form-button',
        formResult: 'js-job-form-result',
        formError: 'js-job-form-error',

        error: 'error',
        success: 'success',
        show: 'show',
        emailInvalid: 'email-invalid',
        uploadInvalid: 'upload-invalid',
        loading: 'loading',
        uploaded: 'uploaded',
        notRequired: 'not-required'
    };

    _isProcess = false;
    _needUpload = false;

    _JOB_TITLE = JOB_TITLE;
    _JOB_ID = JOB_ID;

    _actionSendForm = 'mdm_job_send_form';

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {
        this.dom.views = {};

        this.dom.views.form = {
            container: this.dom.page.querySelector( '.' + this._classes.formContainer ),
            inputs: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.formInput ) ),
            selects: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.formSelect ) ),
            checkboxs: {},
            radios: {},
            uploadContainers: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.formUploadContainer ) ),
            uploadSuccesses: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.formUploadSuccess ) ),
            uploads: [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.formUpload ) ),
            containerButton: this.dom.page.querySelector( '.' + this._classes.formContainerButton ),
            button: this.dom.page.querySelector( '.' + this._classes.formButton ),
            result: this.dom.page.querySelector( '.' + this._classes.formResult ),
            error: this.dom.page.querySelector( '.' + this._classes.formError ),
        };

        let list_checkbox = [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.formCheckbox ) );
        if( list_checkbox ) {
            for( let i = 0, j = list_checkbox.length; i < j; i++ ) {
                let checkbox = list_checkbox[ i ]; 
                let name = checkbox.getAttribute( 'name' );

                if( this.dom.views.form.checkboxs.hasOwnProperty( name ) ) {
                    this.dom.views.form.checkboxs[ name ].push( checkbox );
                } else {
                    this.dom.views.form.checkboxs[ name ] = [ checkbox ];
                }
            }
        }

        let list_radio = [].slice.call( this.dom.page.querySelectorAll( '.' + this._classes.formRadio ) );
        if( list_radio ) {
            for( let i = 0, j = list_radio.length; i < j; i++ ) {
                let radio = list_radio[ i ]; 
                let name = radio.getAttribute( 'name' );

                if( this.dom.views.form.radios.hasOwnProperty( name ) ) {
                    this.dom.views.form.radios[ name ].push( radio );
                } else {
                    this.dom.views.form.radios[ name ] = [ radio ];
                }
            }
        }
    }

    init() {
        super.init();

        this._initForm();
        this._initUpload();
    }

    /**
     * Init form with FormValidator class
    */
    _initForm() {

        /** Init Form Validator **/
        this._Validator = new FormValidator({
            'inputs' : this.dom.views.form.inputs,
            'parent' : false
        });
    }

    /**
     * Init upload with UploadManager class
    */
    _initUpload() {

        var uploadsOpts = {
            uploads: {}
        };

        if( this.dom.views.form.uploads ) {

            this._needUpload = true;

            for( let i = 0, j = this.dom.views.form.uploads.length; i < j; i++ ) {

                let upload = this.dom.views.form.uploads[ i ];
                let label = this.dom.page.querySelector('label[for="' + upload.id + '"]');

                uploadsOpts.uploads[ i ] = {
                    input: upload,
                    maxSize: 8,
                    type: ['application/pdf', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
                    label: label,
                    required: true,
                }
            }

            this._UploadManager = new UploadManager( uploadsOpts );
        }
    }

    bind() {
        super.bind();

        [ '_checkSelectValue', '_validate', '_uploadSuccess', '_uploadError', '_clickInputRadio' ]
            .forEach((fn) => this[fn] = this[fn].bind(this));

        // Upload event
        if( this._needUpload ) {
            this._UploadManager.on( MANAGE_UPLOAD_EVENT.SUCCESS_UPLOAD, this._uploadSuccess );
            this._UploadManager.on( MANAGE_UPLOAD_EVENT.ERROR_UPLOAD, this._uploadError );
        }

        // Select input
        if( this.dom.views.form.selects ) {
            for( let i = 0, j = this.dom.views.form.selects.length; i < j; i++ ) {

                this.dom.views.form.selects[ i ].addEventListener( 'change', this._checkSelectValue );
            }
        }

        // Input type radio
        if( this.dom.views.form.radios ) {

            for( let groupInput in this.dom.views.form.radios ) {

                if (!this.dom.views.form.radios.hasOwnProperty(groupInput)) continue;

                for( let i = 0, j = this.dom.views.form.radios[ groupInput ].length; i < j; i++ ) {
                    this.dom.views.form.radios[ groupInput ][ i ].addEventListener( 'click', this._clickInputRadio );
                }
            }
        }

        // Validate Form
        if (this.dom.views.form.button) {
            this.dom.views.form.button.addEventListener( 'click', this._validate );
        }
    }

    /**
     * Called when the user clicks on an input of type "radio"
     * Deselect the other inputs of the same group if you click on an input
     * @param event
     */
     _clickInputRadio( event ) {
        let target = event.currentTarget;
        let name = target.getAttribute( 'name' );

        for( let i = 0, j = this.dom.views.form.radios[ name ].length; i < j; i++ ) {
            let input = this.dom.views.form.radios[ name ][ i ];
            if( (target != input) && ( input.checked == true ) ) {
                input.checked = false;
            }
        }
    }


    /**
     * Called when the import of a file went well
     * @param data (object) : { index, firstUpload, nameFile }
    */
     _uploadSuccess( data ) {

        let index = data.index;

        // Parent - uploaded
        this.dom.views.form.uploadContainers[ index ].classList.add( this._classes.uploaded );

        // Writing the file name
        this.dom.views.form.uploadSuccesses[ index ].innerHTML = data.nameFile;
    }

   
    /**
     * Called when importing a file did not go well
     * @param data (object) : { index }
    */
    _uploadError( data ) {
        let index = data.index;

        // Parent - uploaded
        this.dom.views.form.uploadContainers[ index ].classList.add( this._classes.uploaded );

        // Remove the file name
        this.dom.views.form.uploadSuccesses[ index ].innerHTML = '';
    }

    /**
    * The user changes the value of a select
    */
     _checkSelectValue( event ) {

        let target = event.currentTarget;

        if( target.value != '' || target.classList.contains( this._classes.notRequired ) ) {
            target.parentNode.classList.remove( this._classes.error );
            target.parentNode.classList.add( this._classes.success );
        } else {
            target.parentNode.classList.add( this._classes.error );
            target.parentNode.classList.remove( this._classes.success );
        }
    }

    /**
     * Check if all the fields of type "select" are filled
     * @returns boolean
    */
    _selectIsValid() {

        let isValid = true;

        for( let i = 0, j = this.dom.views.form.selects.length; i < j; i++ ) {

            let select = this.dom.views.form.selects[ i ];

            if( select.value == '' && select.classList.contains( this._classes.notRequired ) == false ) {
                select.parentNode.classList.add( this._classes.error );
                isValid = false;
            } else {
                select.parentNode.classList.remove( this._classes.error );
            }
        }

        return isValid;
    }

    /**
     * Retrieving the values ​​of all "select" type fields
     * @returns data : array of select input values
    */
    _selectGetData() {

        let data = {};

        for( let i = 0, j = this.dom.views.form.selects.length; i < j; i++ ) {
            let select = this.dom.views.form.selects[ i ];
            data[ select.name ] = select.value;
        }

        return data;
    }

    /**
     * Check if the user has responded to "choice" type inputs
     * @return boolean
    */
    _checkboxIsValid() {

        let errors = [];

        for( let checkbox in this.dom.views.form.checkboxs ) {

            if ( !this.dom.views.form.checkboxs.hasOwnProperty( checkbox ) ) continue;

            let is_checked = false;
            let parent = this.dom.views.form.checkboxs[ checkbox ][ 0 ].parentNode.parentNode;

            // 1 - Loop through each checkox input to see if one is checked
            for( let i = 0, j = this.dom.views.form.checkboxs[ checkbox ].length; i < j; i++ ) {
                let element = this.dom.views.form.checkboxs[ checkbox ][ i ];

                if( element.checked ) {
                    is_checked = true;
                }
            }

            // 2 - Is this field required ?
            if( is_checked == false && parent && parent.classList.contains( this._classes.notRequired ) ) {
                is_checked = true;
            }

            // 3- If this field is valid
            if( is_checked == true ) {
                if( parent ) {
                    parent.classList.remove( this._classes.error );
                    parent.classList.add( this._classes.success );
                }
            }

            // 4- If this field is not valid
            if( is_checked == false ) {
                if( parent ) {
                    parent.classList.remove( this._classes.success );
                    parent.classList.add( this._classes.error );
                }
                errors.push( 'input ' + checkbox + ' not valid' );
            }

        }

        if( errors.length > 0 ) {
            return false;
        }

        return true;
    }

     /**
     * Check if the user has responded to "radio" type inputs
     * @return boolean
    */
      _radioIsValid() {

        let errors = [];

        for( let radio in this.dom.views.form.radios ) {

            if ( !this.dom.views.form.radios.hasOwnProperty( radio ) ) continue;

            let is_checked = false;
            let parent = this.dom.views.form.radios[ radio ][ 0 ].parentNode.parentNode;

            // 1 - Loop through each checkox input to see if one is checked
            for( let i = 0, j = this.dom.views.form.radios[ radio ].length; i < j; i++ ) {
                let element = this.dom.views.form.radios[ radio ][ i ];

                if( element.checked ) {
                    is_checked = true;
                }
            }

            // 2 - Is this field required ?
            if( is_checked == false && parent && parent.classList.contains( this._classes.notRequired ) ) {
                is_checked = true;
            }

            // 3- If this field is valid
            if( is_checked == true ) {
                if( parent ) {
                    parent.classList.remove( this._classes.error );
                    parent.classList.add( this._classes.success );
                }
            }

            // 4- If this field is not valid
            if( is_checked == false ) {
                if( parent ) {
                    parent.classList.remove( this._classes.success );
                    parent.classList.add( this._classes.error );
                }
                errors.push( 'input ' + radio + ' not valid' );
            }

        }

        if( errors.length > 0 ) {
            return false;
        }

        return true;
    }

    /**
     * Retrieving the values ​​of the "checkbox" inputs
     * @return object (the different values ​​of the "checkbox" inputs linked with their name)
    */
    _checkboxGetData() {

        let valuesInput = {};

        for( let checkbox in this.dom.views.form.checkboxs ) {

            if ( !this.dom.views.form.checkboxs.hasOwnProperty( checkbox ) ) continue;

            for( let i = 0, j = this.dom.views.form.checkboxs[ checkbox ].length; i < j; i++ ) {

                let element = this.dom.views.form.checkboxs[ checkbox ][ i ];

                if( element.checked ) {
                    if( valuesInput.hasOwnProperty( checkbox ) ) {
                        valuesInput[ checkbox ].push( element.value );
                    } else {
                        valuesInput[ checkbox ] = [ element.value ];
                    }
                }
            }
        }

        return valuesInput;
    }

    /**
     * Retrieving the values ​​of the "radio" inputs
     * @return object (the different values ​​of the "radio" inputs linked with their name)
    */
    _radioGetData() {

        let valuesInput = {};

        for( let radio in this.dom.views.form.radios ) {

            if ( !this.dom.views.form.radios.hasOwnProperty( radio ) ) continue;

            for( let i = 0, j = this.dom.views.form.radios[ radio ].length; i < j; i++ ) {

                let element = this.dom.views.form.radios[ radio ][ i ];

                if( element.checked ) {
                    valuesInput[ radio ] = element.value;
                }
            }
        }

        return valuesInput;
    }

    /**
    * The user clicks on the "send" button of the form
    * @param event
    */
    _validate( event ) {

        let errors = [];
        let emailValid = true;
        let uploadValid = true;

        // Hide message error
        if( this.dom.views.form.error ) {
            this.dom.views.form.error.classList.remove( this._classes.show );
            this.dom.views.form.error.classList.remove( this._classes.emailInvalid );
            this.dom.views.form.error.classList.remove( this._classes.uploadInvalid );
        }

        // Check process
        if( this._isProcess == false ) {

            this._isProcess = true;

            document.body.classList.add( this._classes.loading );
            this.dom.views.form.button.classList.add( this._classes.loading );

            let data = new FormData();

            // Check Form Selects
            if( this.dom.views.form.selects ) {
                
                if( this._selectIsValid() ) {

                    // Get Select Fields data
                    let selects = this._selectGetData();
                    for(let item in selects) {
                        data.append(item, selects[item]);
                    }

                } else {
                    errors.push( 'Select not valid' );
                }
            }

            // Check Form Checkboxs
            if( this.dom.views.form.checkboxs ) {
                
                if( this._checkboxIsValid() ) {

                    // Get Checkbox Fields data
                    let checkboxs = this._checkboxGetData();
                    for(let item in checkboxs) {
                        data.append(item, checkboxs[item]);
                    }

                } else {
                    errors.push( 'Checkbox not valid' );
                }
            }

            // Check Form Radios
            if( this.dom.views.form.radios ) {
                
                if( this._radioIsValid() ) {

                    // Get Radio Fields data
                    let radios = this._radioGetData();
                    for(let item in radios) {
                        data.append(item, radios[item]);
                    }

                } else {
                    errors.push( 'Checkbox not valid' );
                }
            }

            // Check Uploads
            if( this._needUpload == true ) {
                if( this._UploadManager.getFiles() ) {

                    let list_upload = this._UploadManager.getFilesValues();
                    for (var upload in list_upload) {
                        data.append( upload, list_upload[upload] );
                    }
                } else {
                    uploadValid = false;
                    errors.push( 'Form upload not valid' );
                }
            }

            // Check Form inputs
            if( this._Validator.isValid() ) {

                // Get Form Fields data
                let inputs = this._Validator.getData();

                for(let item in inputs) {
                    data.append(item, inputs[item]);
                }

            } else {
                errors.push( 'Form not valid' );
            }

            // Check Form inputs type "email"
            if( this._Validator.isValidEmail() == false ) {
                emailValid = false;
                errors.push( 'Form email not valid' );
            }

            // Check errors
            if( errors.length > 0 ) {

                // Show message error
                if( this.dom.views.form.error ) {

                    if( emailValid == false ) {
                        this.dom.views.form.error.classList.add( this._classes.emailInvalid );
                    }

                    if( uploadValid == false ) {
                        this.dom.views.form.error.classList.add( this._classes.uploadInvalid );
                    }

                    this.dom.views.form.error.classList.add( this._classes.show );
                }

                this._isProcess = false;
                document.body.classList.remove( this._classes.loading );
                this.dom.views.form.button.classList.remove( this._classes.loading );

                return;
            }

            // Job id
            data.append('job_id', this._JOB_ID);

            // Job title
            data.append('job_title', this._JOB_TITLE);

            // Request
            this._request( data );
        }
    }

    /**
    * Request "Form Contact Send"
    * @param data (FormData)
    */
    _request( data ) {

        const xhr = new XMLHttpRequest();
        xhr.open( 'POST', '/wp/wp-admin/admin-ajax.php?action=' + this._actionSendForm, true );
        xhr.onreadystatechange = function () {
            if( xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200 ) {
                this._response(( JSON.parse( xhr.response )));
            }
            this._isProcess = false;
            document.body.classList.remove( this._classes.loading );
            this.dom.views.form.button.classList.remove( this._classes.loading );

        }.bind( this );
        xhr.send( data );
    }

    /**
    * Response to the request to send the form
    * @param response Ajax
    */
    _response( response ) {

        if( response.success ) {
            // Bloc "Message bien envoyé"
            this.dom.views.form.containerButton.classList.add( this._classes.success );
        } else {
            // Bloc "Erreur dans l'envoi du message"
            this.dom.views.form.result.classList.add( this._classes.error );
        }

        this.dom.views.form.result.innerHTML = response.data.message;
        this.dom.views.form.result.classList.add( this._classes.show );
    }
}
