import EventEmitter from "eventemitter3";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Swiper, { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';
import Page from '~/pages/page';
import States from '../../helpers/states';
import { map } from '../../map/map.generic';


gsap.registerPlugin(ScrollToPlugin);

export default class DateList extends Page {
    constructor(...args) {
        super(...args);

        this.mouse = { x: 0, y: 0 };
        this.x = 0;
        this.y = 0;

        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;

        this.circleScale = this.screenWidth * 0.2;
        this.dragStrength = 0.1;
        this.maxCircleSize = this.screenWidth > this.screenHeight ? this.screenWidth : this.screenHeight;
        this.animationDuration = 400;
        this.bigAnimationDuration = 800;
        this.endingAnimationStart = 0;
        this.startingAnimationStart = 0;
        this.clickingAnimationStart = 0;
        this.closingAnimationStart = 0;
        this.dynamicCircleScale = 0;
        this.circleAnimation = [];
        this.timeout = [];
        this.pause = [];

        this.isScrolling = false;

        this._limiteDevice = 768;

        this._retrieveDOM();
        this.initSwiper();

        this.currentSlide = -1;

        this.swiperSlideHeight = this.swiper.slidesSizesGrid;
    }

    _retrieveDOM() {
        this.dom.images = this.dom.component.querySelectorAll('.js-hero-container');
        this.dom.swiperContainer = this.dom.component.querySelector('.slider-date');
        this.dom.swiperSlides = this.dom.component.querySelectorAll('.swiper-slide-date');
        this.dom.centuries = this.dom.component.querySelectorAll('.century > .text');
        this.dom.singleContainer = this.dom.component.querySelector('.singles');
        this.dom.singles = this.dom.component.querySelectorAll('.single-date');
        this.dom.closeButtons = this.dom.component.querySelectorAll('.js-cross');
        this.dom.intersectionObserver = this.dom.component.querySelector('.intersection-observer');
        this.dom.moreContent = this.dom.component.querySelector('.more-content');
        this.beforeSlide2000count = parseInt(this.dom.swiperContainer.dataset.lastCentury);
        this.afterSlide2000count = parseInt(this.dom.swiperContainer.dataset.currentCentury);
    }

    onSliderMove() {
        let translateValue = this.swiper.translate + this.swiper.slidesGrid[0];
        let slideHeight = this.swiper.slidesSizesGrid[0];
        if (
            -translateValue >= slideHeight * (this.beforeSlide2000count - 1) &&
            -translateValue <= slideHeight * (this.beforeSlide2000count)
        ) {
            this.dom.centuries[0]
                ? this.dom.centuries[0].style.transform = `translateY(${translateValue + (slideHeight * (this.beforeSlide2000count - 1))}px)`
                : null;
            this.dom.centuries[1]
                ? this.dom.centuries[1].style.transform = `translateY(${translateValue}px)`
                : null;
        } else if (-translateValue <= slideHeight * (this.beforeSlide2000count - 1)) {
            this.dom.centuries[1]
                ? this.dom.centuries[1].style.transform = `translateY(${translateValue}px)`
                : null;
        } else {
            this.dom.centuries[0]
                ? this.dom.centuries[0].style.transform = `translateY(${translateValue + (slideHeight * (this.beforeSlide2000count - 1))}px)`
                : null;
        }
    }

    handleSlideChange() {
        if (this.swiper.activeIndex >= this.beforeSlide2000count) {
            this.dom.centuries[0]?.classList.remove('active');
            this.dom.centuries[1]?.classList.add('active');
        } else {
            this.dom.centuries[0]?.classList.add('active');
            this.dom.centuries[1]?.classList.remove('active');
        }

        setTimeout(() => {
            this.swiper.params.mousewheel.releaseOnEdges = false;
        }, 500);
    }

    handleReachEnd() {
        setTimeout(() => {
            this.swiper.params.mousewheel.releaseOnEdges = true;
        }, 750);
    }

    initSwiper() {
        this.swiper = new Swiper(this.dom.swiperContainer, {
            modules: [Autoplay, Pagination, Navigation, Mousewheel],
            direction: 'vertical',
            grabCursor: 'true',
            slideActiveClass: "active",
            mousewheel: {
                releaseOnEdges: true,
            },
            slidesPerView: "auto",
            centeredSlides: true,
            slideClass: 'swiper-slide-date',
            touchEventsTarget: 'container',
            touchReleaseOnEdges: true,
        });

        this.swiper.on('reachEnd', this.handleReachEnd.bind(this));
        this.swiper.on('slideChange', this.handleSlideChange.bind(this));
        this.swiper.on('setTranslate', this.onSliderMove.bind(this));
        this.dom.centuries.forEach(century => {
            this.swiper.on('slideChangeTransitionStart', () => century.classList.add('animate'));
            this.swiper.on('slideChangeTransitionEnd', () => century.classList.remove('animate'));
            this.swiper.on('slideResetTransitionStart', () => century.classList.add('animate'));
            this.swiper.on('slideResetTransitionEnd', () => century.classList.remove('animate'));
        });
    }

    computeMousePos(e) {
        this.mouse.x = e.pageX;
        this.mouse.y = e.pageY;
    }

    easeInOutSine(t, b, c, d) {
        return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
    }

    loop(i) {
        if (this.mouse.x !== Math.round(this.x * 10) / 10) {
            this.x += (this.mouse.x - this.x) * this.dragStrength;
            this.y += (this.mouse.y - this.y) * this.dragStrength;
        } else {
            this.x = this.mouse.x;
            this.y = this.mouse.y;
        }

        if (this.circleAnimation[i]) {
            this.dynamicCircleScale = Math.round(this.easeInOutSine(
                Date.now() - this.circleAnimation[i].start,
                this.circleAnimation[i].initValue,
                this.circleAnimation[i].valueChange,
                this.circleAnimation[i].duration
            ));
        }

        this.dom.singles[i].style.clipPath = `circle(${this.dynamicCircleScale}px at ${this.x}px ${this.y}px)`;

        if (!this.pause[i]) {
            requestAnimationFrame(this.loop.bind(this, i));
        }
    }

    startCircleAnimation(i, animationDuration, initValue, valueChange) {
        return new Promise((res) => {

            this.circleAnimation[i] = {
                start: Date.now(),
                duration: animationDuration,
                initValue: initValue,
                valueChange: valueChange,
            }

            clearTimeout(this.timeout[i]);

            this.timeout[i] = setTimeout(() => {
                this.circleAnimation[i] = null;
                res();
            }, animationDuration);
        })
    }

    startLoop(i, e) {

        // if (States.width > this._limiteDevice) {
        this.x = e.pageX;
        this.y = e.pageY;

        // console.log(i)
        this.pause[i] = false;
        this.dom.singles[i].classList.remove('hide');

        this.startCircleAnimation(i, this.animationDuration, 0, this.circleScale);
        this.loop(i);
        // }

        if (this.dom.swiperSlides[i] && this.dom.swiperSlides[i].dataset.url) {
            this._ManagerLoadPage.load(this.dom.swiperSlides[i].dataset.url);
        }

    }

    async stopLoop(i) {
        // console.log(i)
        if (!this.singleIsOpen) {
            await this.startCircleAnimation(
                i,
                this.animationDuration,
                this.dynamicCircleScale,
                -this.dynamicCircleScale
            );

            this.pause[i] = true;
            this.dom.singles[i].classList.add('hide');
        }
    }

    async handleSlideClick(i) {
        // if( this.preventDateClick ) return;

        // On mobile there is no hover
        // the loop should be started on click
        if (this.pause[i]) {
            this.pause = false;
            this.loop(i);
        }
        this.dom.moreContent.classList.add('hide');
        this.singleIsOpen = true;
        this.dom.closeButtons[i].classList.add('open');
        await this.startCircleAnimation(i, this.animationDuration, this.circleScale, this.maxCircleSize);
        this.pause[i] = true;
        this.dom.singles[i].classList.add('open');

        if (this.dom.swiperSlides[i] && this.dom.swiperSlides[i].dataset.url) {
            this._ManagerLoadPage.changeDOM(this.dom.swiperSlides[i].dataset.url, i);
        }
    }

    async handleCloseButton(i, e) {

        // if( this.preventDateClick ) return

        // this.preventDateClick = true;

        this.x = e.pageX;
        this.y = e.pageY;

        this.pause[i] = false;
        this.loop(i);

        window.scrollTo(0, 0);

        this.dom.singles[i].classList.remove('open');
        this.singleIsOpen = false;
        await this.startCircleAnimation(i, this.animationDuration, this.maxCircleSize, -this.maxCircleSize);
        // this.preventDateClick = false;
        this.dom.singles[i].classList.add('hide');

        this.dom.moreContent.classList.remove('hide');
        this.dom.closeButtons[i].classList.remove('open');

        if (this._ManagerLoadPage) {
            this._ManagerLoadPage._hideContainerDate();
        }
    }

    handleResize() {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
        this.circleScale = this.screenWidth * 0.2;
    }

    handleObserver(e) {
        const isEntering = e[0].intersectionRatio === 1 ? true : false;
        this.swiper.enabled = !isEntering;
    }

    bind() {

        this.dom.swiperSlides.forEach((slide, i) => {
            slide.addEventListener('mouseenter', this.startLoop.bind(this, i));
            slide.addEventListener('mouseleave', this.stopLoop.bind(this, i));
            slide.addEventListener('mousemove', this.computeMousePos.bind(this));
            slide.addEventListener('click', this.handleSlideClick.bind(this, i));
            this.dom.closeButtons[i].addEventListener('click', this.handleCloseButton.bind(this, i));
        });

        const observer = new IntersectionObserver(this.handleObserver.bind(this), { threshold: 1 });
        observer.observe(this.dom.intersectionObserver);

        if (this._ManagerLoadPage) {
            // this._ManagerLoadPage.on( MANAGE_LOAD_PAGE_EVENT.CLOSE_SLIDER, (data) => {
            //     this.handleCloseButton(data.index, data.e);
            // } );

            this._ManagerLoadPage.on(MANAGE_LOAD_PAGE_EVENT.NEXT_DATE, (data) => {
                this.swiper.slideNext();
            });
        }

    }

    init() {

        super.init();

        this._ManagerLoadPage = new ManagerLoadPage({
            mainPage: this.dom.page,
        });


        // if( this.dom.page.classList.contains( 'date-exist' ) ) {
        let indexDate = parseInt(this.dom.page.dataset.indexDate);
        this.swiper.slideTo(indexDate);
        // }

    }

    resize(width, height) {
        super.resize(width, height)

        this.handleResize();

        this._ManagerLoadPage.resize(width, height);
    }

    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}

export const MANAGE_LOAD_PAGE_EVENT = {
    'LOAD_URL': '__mlp_load_url',
    'CLICK_URL': '__mlp_click_url',
    'CLOSE_SLIDER': '__mlp_close_slider',
    'NEXT_DATE': '__mlp_next_date',
}


class ManagerLoadPage extends EventEmitter {

    _cache = {};
    _urls = {
        origin: window.location.origin || window.location.protocol + '//' + window.location.hostname,
        default: null,
        current: window.location.href,
        new: null
    };
    _DOMparser = new DOMParser();
    _Components = {};
    _Page = {
        dom: null,
        class: null,
    };
    _locked = false;
    _pages = {
        previous: null,
        current: null
    };
    _PreviousPage = {
        dom: null,
        class: null,
    };
    _actionSendRequest = 'mdm_date_list_request_date';
    _titles = {
        origin: '',
        current: ''
    }
    _classes = {
        singleDate: 'ct--history-single',
        singleDateContent: 'js-date-single-content',
        hide: 'hide',
        show: 'show',
        open: 'open',
        dateLoaded: 'date-loaded',
        loading: 'loading',
        containerLoading: 'js-container-loading',
        dateExist: 'date-exist',
        transition: 'transition'
    }
    _indexCurrent = 0;
    _indexCurrentSlide = 0;
    _indexPrevious = -1;

    constructor({ mainPage = null }) {

        super();

        this.mainPage = mainPage;

        if (this._checkRequiredParams() == false)
            return;

        this._retrieveDOM();
        this._init();
        this.bind();
    }

    /**
     * Check if the mandatory parameters are well informed
     * @return {Boolean}
     */
    _checkRequiredParams() {

        let styleConsole = 'background: #222; color: #ff8c8c'; // green : bada55

        if (this.mainPage == null || this.mainPage == false) {
            console.warn('%c [ ManagerLoadPage ] -- The main page is not defined ', styleConsole);
            return false;
        }
    }

    _retrieveDOM() {

        this._views = {};

        this._views.singleDate = [].slice.call(document.body.querySelectorAll('.' + this._classes.singleDate));
        this._views.singleDateContent = [].slice.call(document.body.querySelectorAll('.' + this._classes.singleDateContent));
        this._views.containerLoading = document.body.querySelector('.' + this._classes.containerLoading);
    }

    _init() {
        this._map = map;

        // Init url
        this._urls.default = this.mainPage.dataset.defaultUrl;

        // Init title
        this._titles.origin = this.mainPage.dataset.titleOrigin;
        this._titles.current = this._titles.origin;


        if (this.mainPage.classList.contains(this._classes.dateExist)) {
            this._initDateInitial();
        }
    }

    bind() {
    }

    _initDateInitial() {

        this._indexCurrent = parseInt(this.mainPage.dataset.indexDate);

        this._pathUrl = this.getPathUrl(this._urls.current);
        this._urls.new = this._urls.current;

        this._Page.dom = this._views.singleDateContent[this._indexCurrent].querySelector('div.page');

        this._views.singleDate[this._indexCurrent].classList.add(this._classes.open);
        this._views.singleDate[this._indexCurrent].classList.add(this._classes.dateLoaded);

        window.scrollTo(0, 0); // gsap.set( window, { scrollTo: { y: 0 } });

        let xhr = {
            success: true,
            data: {
                data_html: this._Page.dom.outerHTML,
                date: this._Page.dom.dataset.currentDate
            },
        };

        this._cache[this._pathUrl] = xhr;

        // Update title
        this._titles.current = this._Page.dom.dataset.titleDate;

        this._initContext();
        this._initFirstEventPage();
    }

    _clickCloseButton(event) {

        this.emit(MANAGE_LOAD_PAGE_EVENT.CLOSE_SLIDER, { index: this._indexCurrentSlide, e: event });
        this._hideContainerDate();
    }

    _hideContainerDate() {

        this._indexPrevious = this._indexCurrent;

        this._views.singleDate[this._indexPrevious].classList.remove(this._classes.dateLoaded);

        this._resetContainer();

        this._urls.new = this._urls.default;
        this._updateURL();
    }

    _resetContainer() {
        this._removeContextOldPage();
        this._hideOldPage();
        this._destroyOldPage();

        this._Page = {
            dom: null,
            class: null
        }
    }

    _lock() {
        this._locked = true;
    }

    _unlock() {
        this._locked = false;
    }

    load(url) {

        if (this._locked) {
            return;
        }

        this._request(url)
    }

    _request(url, callback = null) {

        this._lock();

        this._pathUrl = this.getPathUrl(url);
        this._urls.new = url;

        if (this._cache[this._pathUrl]) {
            if (callback && typeof callback == 'function') {
                callback(this._cache[this._pathUrl]);
            } else {
                this._unlock();
            }
        } else {

            if (url === '') url = '/'

            let data = new FormData();
            data.append('url', url);
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/wp/wp-admin/admin-ajax.php?action=' + this._actionSendRequest, true);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {

                    this._cache[this._pathUrl] = JSON.parse(xhr.response);
                    if (callback && typeof callback == 'function') {
                        callback(this._cache[this._pathUrl]);
                    } else {
                        this._unlock();
                    }
                } else {
                    this._unlock();
                }

            }.bind(this);
            xhr.send(data);

            // axios.get(url)
            //     .then(response => {
            //         this._cache[this._pathUrl] = response.data;
            //         if( callback && typeof callback == 'function') {
            //             callback(this._cache[this._pathUrl]);
            //         } else {
            //             this._unlock();
            //         }
            //     })
            //     .catch(error => {
            //         console.error( error );
            //         this._unlock();
            //     });
        }
    }

    changeDOM(url, current_date) {
        this._indexCurrent = current_date;
        this._indexCurrentSlide = current_date;

        this._toggleContainerLoading(true);
        this._request(url, this._updateDOM.bind(this));
    }

    _toggleContainerLoading(to_show) {
        if (to_show) {
            this._views.containerLoading.classList.add(this._classes.show);

        } else {
            this._views.containerLoading.classList.remove(this._classes.show);
        }

    }

    _updateDOM(xhr) {

        // Remove previous page
        if (this._Page.dom) {
            this._removeContextOldPage();
            this._hideOldPage();
            this._destroyOldPage();
        }

        // Update DOM
        if (xhr.success) {
            this._addNewPage(xhr);
        }

        this._unlock();
    }

    _removeContextOldPage() {
        this._PreviousPage.class = this._Page.class;
        this._PreviousPage.dom = this._Page.dom;

        this._removeEventLoad();

        this._browseComponents('_unbind', null);

        this._browseComponents('unbind', null, (component, key) => {
            if (!component.persist) {
                component = null;
                delete this._Components[key];
            }
        });

        this._PreviousPage.class.unbind();
    }

    _hideOldPage() {
        this._PreviousPage.class.hide();
        this._views.singleDate[this._indexPrevious].classList.remove(this._classes.dateLoaded);
        this._views.singleDate[this._indexPrevious].classList.remove(this._classes.open);
        this._views.singleDate[this._indexPrevious].classList.add(this._classes.hide);
    }

    _destroyOldPage() {
        this._destroyClasses();
    }

    /**
     * Destroy instances of the previous Page / Components
    */
    _destroyClasses() {
        this._views.singleDateContent[this._indexPrevious].removeChild(this._PreviousPage.dom);

        this._PreviousPage = {
            dom: null,
            class: null
        };
    }

    _addNewPage(xhr) {

        this._initContent(xhr);
        this._initContext();
        this._clearPropsElement();
        this._initFirstEventPage();
    }

    _initContent(xhr) {

        // Update DOM
        const dom = this._DOMparser.parseFromString(xhr.data.data_html, 'text/html');

        this._Page.dom = dom.querySelector('div.page');
        this._views.singleDateContent[this._indexCurrent].appendChild(this._Page.dom);

        window.scrollTo(0, 0);

        // Update title
        this._titles.current = this._Page.dom.dataset.titleDate;
        this._setTitle(this._titles.current);
    }

    _setTitle(title) {
        document.title = title || document.title;
    }

    _initContext() {
        this._retrieveDomProperties();
        this._initComponents();
        this._initPage();
        this._updateURL();
    }

    _clearPropsElement() {

        this._toggleContainerLoading(false);

        this._views.singleDate[this._indexCurrent].classList.add(this._classes.dateLoaded);
    }

    getPathUrl(url) {
        url = url.replace(this._urls.origin, '');
        // if (url.endsWith('/')) url = url.slice(0, -1)
        // if (!url.endsWith('/')) url += '/';
        return url;
    }

    resize(width = States.width, height = States.height) {

        this._browseComponents('_resize', [width, height]);
        this._browseComponents('resize', [width, height]);

        if (this._Page.class) this._Page.class.resize(width, height);
    }

    /**
     * Retrieve page properties: entity & page name
     */
    _retrieveDomProperties() {
        this._properties = {
            entity: this._Page.dom.getAttribute('data-entity').toLowerCase(),
            page: this._Page.dom.getAttribute('data-page').toLowerCase()
        };
    }

    /**
     * Detect available components in Page and instanciate them
     */
    _initComponents() {
        const container = this._Page.dom;
        const components = container.querySelectorAll('[data-component]');
        for (let i = 0, j = components.length; i < j; i++) {
            const component = components[i];
            const name = component.getAttribute('data-component');
            if (this._map.components.hasOwnProperty(name)) {
                if (this._Components.hasOwnProperty(name.capitalize())) {
                    if (isArray(this._Components[name.capitalize()])) {
                        this._Components[name.capitalize()].push(new this._map.components[name].default(component));
                    } else {
                        this._Components[name.capitalize()] = [
                            this._Components[name.capitalize()],
                            new this._map.components[name].default(component)
                        ];
                    }
                } else {
                    this._Components[name.capitalize()] = new this._map.components[name].default(component);
                }
            } else {
                throw new Error(`Main${this._device.capitalize()} :: you are calling non-existing '${name}' component in your map file. Please check your map file.`);
            }
        }
    }

    /**
    * Detect current page, find class and instanciate it
     */
    _initPage() {
        if (this._map.pages.hasOwnProperty(this._properties.entity)) {
            if (this._map.pages[this._properties.entity].hasOwnProperty(this._properties.page)) {
                this._Page.class = new this._map.pages[this._properties.entity][this._properties.page].default(this._Page.dom, this._Components);
            } else {
                throw new Error(`Main :: you are calling non-existing '${this._properties.page}' page for entity '${this._properties.page}' in your map file. Please check your map file.`);
            }
        } else {
            throw new Error(`Main :: you are calling non-existing '${this._properties.entity}' page entity in your map file. Please check your map file.`);
        }
    }

    _initFirstEventPage() {
        this._browseComponents('mediaLoadedHandler');
        this._browseComponents('init');
        this._browseComponents('bind');
        this._initEventLoad();

        this._Page.class.init();
        this._Page.class.bind();
        this._Page.class.show();

        this.resize();

        setTimeout(() => {
            this.resize();
        }, 2000)

        // Router.execBodyScripts(this._dom.page);
        // Router.updateSwitchLangUrl();
    }


    _updateURL() {
        let current = window.location.href
        // if (current.endsWith('/')) current = current.slice(0, -1)

        if (this._urls.new != current) {
            history.pushState({}, this._titles.current, this._urls.new);
            this._urls.current = this._urls.new;
        }
    }

    _browseComponents(methodName, argsArray = [], callback = null) {
        for (let key in this._Components) {
            if (this._Components.hasOwnProperty(key)) {
                // Browse all components
                let component = null;
                if (isArray(this._Components[key])) {
                    for (let i = 0, j = this._Components[key].length; i < j; i++) {
                        const componentsType = this._Components[key];
                        component = componentsType[i];
                        if (component.persist === false || (methodName !== 'init' && methodName !== 'unbind'))
                            component[methodName].apply(component, argsArray);
                    }
                } else {
                    component = this._Components[key];
                    if (component.persist === false || (methodName !== 'init' && methodName !== 'unbind'))
                        component[methodName].apply(component, argsArray);
                }

                // Callback to call
                if (typeof callback === 'function')
                    callback.call(callback, component, key);
            }
        }
    }

    _initEventLoad() {
        for (let key in this._Components) {
            if (this._Components.hasOwnProperty(key)) {
                // Browse all components
                let component = null;
                if (isArray(this._Components[key])) {
                    for (let i = 0, j = this._Components[key].length; i < j; i++) {
                        const componentsType = this._Components[key];
                        component = componentsType[i];

                        component.on(MANAGE_LOAD_PAGE_EVENT.LOAD_URL, this._triggerEventLoadOnComponent.bind(this));
                        component.on(MANAGE_LOAD_PAGE_EVENT.CLICK_URL, this._triggerEventClickOnComponent.bind(this));

                    }
                } else {
                    component = this._Components[key];
                    component.on(MANAGE_LOAD_PAGE_EVENT.LOAD_URL, this._triggerEventLoadOnComponent.bind(this));
                    component.on(MANAGE_LOAD_PAGE_EVENT.CLICK_URL, this._triggerEventClickOnComponent.bind(this));
                }

            }
        }
    }

    _removeEventLoad() {
        for (let key in this._Components) {
            if (this._Components.hasOwnProperty(key)) {
                // Browse all components
                let component = null;
                if (isArray(this._Components[key])) {
                    for (let i = 0, j = this._Components[key].length; i < j; i++) {
                        const componentsType = this._Components[key];
                        component = componentsType[i];

                        component.off(MANAGE_LOAD_PAGE_EVENT.LOAD_URL, this._triggerEventLoadOnComponent.bind(this));
                        component.off(MANAGE_LOAD_PAGE_EVENT.CLICK_URL, this._triggerEventClickOnComponent.bind(this));
                    }
                } else {
                    component = this._Components[key];

                    component.off(MANAGE_LOAD_PAGE_EVENT.LOAD_URL, this._triggerEventLoadOnComponent.bind(this));
                    component.off(MANAGE_LOAD_PAGE_EVENT.CLICK_URL, this._triggerEventClickOnComponent.bind(this));
                }
            }
        }
    }

    _triggerEventLoadOnComponent(data) {
        this.load(data.url);
    }

    _triggerEventClickOnComponent(data) {

        let element = data.targetDOM;

        gsap.to(window, {
            scrollTo: { y: element }, onComplete: () => {
                element.classList.add(this._classes.transition)
                this._views.containerLoading.classList.add(this._classes.show);
                this._request(data.url, this._updateToNextDate.bind(this));
            }
        });
    }

    _updateToNextDate(xhr) {

        // Update DOM
        if (xhr.success) {

            this._indexPrevious = this._indexCurrent;
            this._indexCurrent += 1;

            this.emit(MANAGE_LOAD_PAGE_EVENT.NEXT_DATE, { index: this._indexCurrent });
            this._removeContextOldPage();

            this._initContent(xhr);
            this._initContext();

            this._Page.class.setAnimIntro(false);

            this._views.singleDate[this._indexCurrent].classList.add(this._classes.dateLoaded);
            this._views.singleDate[this._indexCurrent].classList.add(this._classes.open);
            this._views.singleDate[this._indexCurrent].classList.remove(this._classes.hide);

            this._initFirstEventPage();

            // Hide + Destroy previous page
            this._hideOldPage();
            this._destroyOldPage();
            this._views.containerLoading.classList.remove(this._classes.show);

        } else {
            this._hideContainerDate();
        }

        this._unlock();
    }
}