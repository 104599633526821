import Component from '~/components/component';

export default class BlockHistory extends Component {

    _classes = {
        content : 'js-block-history-content',
        buttonReadMore: 'js-block-history-read-more',
        text: 'js-block-history-text',
        open: 'open'
    };

    constructor(...args) {
        super(...args);

        this._retrieveDOM();
    }

    _retrieveDOM() {

        this.dom.views = {};

        this.dom.views.content = this.dom.component.querySelector( '.' + this._classes.content );
        this.dom.views.buttonReadMore = this.dom.component.querySelector( '.' + this._classes.buttonReadMore );
        this.dom.views.text = this.dom.component.querySelector( '.' + this._classes.text );
    }

    init() {
        super.init();

        this._contentText = ( this.dom.views.text ) ? this.dom.views.text.dataset.fullText : '';
    }

    bind() {

        ['_openContent']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        if (this.dom.views.buttonReadMore) {
            this.dom.views.buttonReadMore.addEventListener('click', this._openContent);
        }
    }

    _openContent( event ) {
        this.dom.views.content.classList.add( this._classes.open );

        if( this.dom.views.text ) {
            this.dom.views.text.innerHTML = this._contentText;
        }
        
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
