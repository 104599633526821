import Swiper, { Mousewheel, Navigation, Pagination } from 'swiper';
import Component from '~/components/component';

export default class Slider extends Component {

    constructor(...args) {
        super(...args);
        this.retrieveDOM();
        this.initSwiper();
    }

    retrieveDOM() {
        this.dom.prevButton = this.dom.component.querySelector('.button.prev');
        this.dom.nextButton = this.dom.component.querySelector('.button.next');
    }

    initSwiper() {
        const swiper = new Swiper(this.dom.component, {
            modules: [Pagination, Navigation, Mousewheel],
            direction: 'horizontal',
            grabCursor: 'true',
            

            mousewheel: {
                forceToAxis: true,
            },

            navigation: {
                prevEl: this.dom.prevButton,
                nextEl: this.dom.nextButton,
            },
            
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },

            autoplay: {
                delay: 4000
            }
        });
    }

    /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}